import Bill from '@/_services/Http/bill';

const actions = {
  async all({}, data) {
    return await Bill.all(data)
  },
  async show({}, uuid) {
    return await Bill.show(uuid)
  },
  async create({}, data) {
    return await Bill.store(data)
  },
  async search({}, term) {
    return await Bill.search(term);
  },
  async update({}, uuid, data) {
    return await Bill.update(uuid, data)
  },
  async delete({}, uuid) {
    await Bill.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

export default {
  namespaced: true,
  actions,
};
