import ApiService from '@/_services/Http/axios';

const Dashboard = {
  index: {},
};

Dashboard.index = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get('/v1/admin/dashboard/index')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Dashboard;
