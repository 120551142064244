import ApiService from "@/_services/Http/axios";

const Company = {
  all: {},
  store: {},
  delete: {},
  edit: {},
  search: {},
  update: {},
};

Company.all = async function ({
  perPage,
  currentPage,
  document,
  company_name,
  state,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/companies/list?page=${currentPage}&limit=${perPage}&document=${document}&state=${state}&company_name=${company_name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Company.edit = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/companies/${uuid}/edit`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Company.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/companies/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Company.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/companies/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Company.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/companies/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Company.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/companies/for-select?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Company.forSelectCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/companies/categories/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Company;
