// Config
export default [
  {
    path: '/sort',
    name: 'sort-items',
    component: () => import('@/views/media/sort/Index.vue'),
    meta: {
      resource: 'Mídia',
      action: 'Organizar',
      pageTitle: 'Organizar',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Organizar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/medias',
    name: 'media-list',
    component: () => import('@/views/media/media/List.vue'),
    meta: {
      resource: 'Mídia',
      action: 'Arquivo',
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-media",
      },
      pageTitle: 'Arquivos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Arquivos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banners',
    name: 'banner-list',
    component: () => import('@/views/media/banner/List.vue'),
    meta: {
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-banner",
      },
      resource: "Mídia",
      action: "Banner",     
      pageTitle: 'Banners',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Mídias',
        },
        {
          text: 'Banners',
          active: true,
        },
      ],
    },
  },
  {
    path: '/warnings',
    name: 'warning-list',
    component: () => import('@/views/media/warning/List.vue'),
    meta: {
      resource: 'Mídia',
      action: 'Arquivo',
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-warning",
      },
      pageTitle: 'Avisos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Mídias',
        },
        {
          text: 'Avisos',
          active: true,
        },
      ],
    },
  },
];
