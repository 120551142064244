import User from "@/_services/Http/user";

const state = () => ({
  loading: true,
  message: "Aguarde",
  error: "",
  users: [],
  user: {},
  deleted: false,
});

const getters = {
};

const actions = {
  async all({}, data) {
    return await User.all(data);
  },
  async create({}, data) {
    return await User.store(data);
  },
  async update({}, { uuid: uuid, data: data }) {
    return await User.update(uuid, data);
  },
  async show({}, uuid) {
    return await User.show(uuid)
  },
  async fetchUsersCRM({}) {
    return await User.fetchUsersCRM();
  },
  async showDiscounts({}) {
    return await User.showDiscounts()
  },
  async delete({}, data) {
    return await User.delete(data.uuid);
  },
  async search({}, term) {
    return await User.search(term);
  },
  async fetchTeams({}, data) {
    return await User.fetchTeams(data);
  },
  async managersDiscounts({}, { classroom, modality }) {
    return await User.managersDiscounts(classroom, modality);
  },
  async managersDiscountSend({}, {}) {
    return await User.managersDiscountSend()
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
