import Api from '@/_services/Http/privacy-policy';

const actions = {
  async all({}) {
    return await Api.all();
  },
  async update({}, {items}) {
    return await Api.update(items)
  },
};

export default {
  namespaced: true,
  actions,
};
