// Config
export default [
  {
    path: "/blog/home",
    name: "blog-home",
    component: () => import("@/views/blog/home/Index.vue"),
    meta: {
      resource: "Blog",
      action: "Home",
      btnCreate: false,
      btnExport: false,
      pageTitle: "Início",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Início",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/posts",
    name: "blog-posts-list",
    component: () => import("@/views/blog/post/List.vue"),
    meta: {
      resource: "Blog",
      action: "Post",
      btnCreate: false,
      btnCreateRouteName: "blog-posts-create",
      btnExport: false,
      pageTitle: "Posts",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Posts",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/posts/create",
    name: "blog-posts-create",
    component: () => import("@/views/blog/post/Create.vue"),
    meta: {
      resource: "Blog",
      action: "Post",
      btnCreate: false,
      btnBack: "blog-posts-list",
      btnExport: false,
      pageTitle: "Cadastrar Post",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Posts",
          route: "blog-posts-list",
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/posts/:uuid/edit",
    name: "blog-posts-edit",
    component: () => import("@/views/blog/post/Edit.vue"),
    meta: {
      resource: "Blog",
      action: "Post",
      btnBack: "blog-posts-list",
      pageTitle: "Editar Post",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Posts",
          to: { name: "blog-posts-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/areas",
    name: "blog-areas-list",
    component: () => import("@/views/blog/area/Index.vue"),
    meta: {
      resource: "Blog",
      action: "Área",
      pageTitle: "Áreas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Áreas",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/areas/:uuid/edit",
    name: "blog-areas-edit",
    component: () => import("@/views/blog/area/Edit.vue"),
    meta: {
      resource: "Blog",
      action: "Área",
      btnBack: "blog-areas-list",
      pageTitle: "Editar Área",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Áreas",
          to: { name: "blog-areas-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/authors",
    name: "blog-authors-list",
    component: () => import("@/views/blog/author/List.vue"),
    meta: {
      resource: "Blog",
      action: "Jornalista",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-author",
      },
      pageTitle: "Jornalistas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Blog",
        },
        {
          text: "Jornalistas",
          active: true,
        },
      ],
    },
  },
];
