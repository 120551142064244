import ApiService from '@/_services/Http/axios';

const Contest = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: {},
  update: {},
};

Contest.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/contests/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contest.list = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get('/v1/admin/contests')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contest.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/contests/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contest.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/contests/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contest.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/contests/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contest.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/contests/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contest.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/contests/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Contest;
