import Unit from '@/_services/Http/unit';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: false,
  units: [],
  unit: {},
  unit_uuid: '',
  deleted: false,
});

const getters = {
  error: (state) => state.error,
  StateUnit: (state) => state.unit,
};

const actions = {
  async all({}, data) {
    return await Unit.all(data)
  },
  async search({}, name) {
    return await Unit.search(name)
  },
  async create({
    commit,
  }, data) {
    await Unit.store(data)
      .then((res) => {
        commit('SET_UNIT', res.data.data);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Unit.show(uuid)
      .then((res) => {
        if (res.data) {
          commit('SET_UNIT', res.data);
        } else {
          commit('SET_ERROR', true);
        }

        commit('SET_LOADING', false);
      })
      .catch(() => {
        commit('SET_ERROR', true);
      });
  },
  async delete({}, data) {
    await Unit.delete(data.uuid);
  },
  async forSelect({}, status) {
    return await Unit.forSelect(status);
  },
  async update({
    commit,
  }, data) {
    await Unit.update(data)
      .then((res) => {
        commit('SET_UNIT', res);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_UNITS(state, units) {
    state.units = units;
  },
  SET_UNIT(state, unit) {
    state.unit = unit;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
  SET_UUID(state, uuid) {
    state.unit_uuid = uuid;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
