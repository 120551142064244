import Author from '@/_services/Http/author';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  authors: [],
  author: {},
  deleted: false,
});

const getters = {
  error: (state) => state.error,
  StateAuthor: (state) => state.author,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Author.all(data)
      .then((res) => {
        commit('SET_AUTHORS', res);
      });
  },
  async create({
    commit,
  }, data) {
    await Author.store(data)
      .then((res) => {
        commit('SET_AUTHOR', res.data);
      });
  },
  async update({}, { uuid: uuid, data: data }) {
    return await Author.update(uuid, data);
  },
  async show({
    commit,
  }, uuid) {
    await Author.show(uuid)
      .then((res) => {
        commit('SET_AUTHOR', res.data);
      });
  },
  async search({}, term) {
    return await Author.search(term);
  },
  async delete({}, data) {
    await Author.delete(data.uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, data) {
    state.error = data;
  },
  SET_AUTHORS(state, data) {
    state.authors = data;
  },
  SET_AUTHOR(state, data) {
    state.author = data;
  },
  DELETED(state, data) {
    state.deleted = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
