import ApiService from "@/_services/Http/axios";

const Datasheet = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: {},

  ApiAllContractModels: {},
  ApiShowContractModels: {},
  ApiUpdateContractModels: {},
  ApiDeleteContractModels: {},
  ApiStoreContractModels: {},
  ApiForSelectContractModels: {},
};

Datasheet.all = async function ({ perPage: limit, currentPage: page, title }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/datasheets/list?page=${page}&limit=${limit}&title=${title}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Datasheet.list = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/datasheets/list")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Datasheet.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/datasheets/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Datasheet.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/datasheets/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Datasheet.update = async function ({ uuid, data }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/datasheets/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Datasheet.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/datasheets/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Datasheet.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/datasheets/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Datasheet;
