import Faq from '@/_services/Http/faq';

const actions = {
  async all({}) {
    return await Faq.all();
  },
  async create({}, data) {
    return await Faq.store(data)
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Faq.update({uuid, data})
      .then((res) => {
        commit('SET_ORGAN', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async delete({}, uuid) {
    await Organ.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

export default {
  namespaced: true,
  actions,
};
