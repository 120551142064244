import ApiService from "@/_services/Http/axios";

const Client = {
  all: {},
  allCategories: {},
  store: {},
  storeCategory: {},
  delete: {},
  deleteCategory: {},
  show: {},
  search: {},
  forSelectCategories: {},
  forSelectTypes: {},
  update: {},
  updateCategory: {},
};

Client.all = async function ({
  perPage,
  currentPage,
  document,
  company_name,
  state,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/clients/list?page=${currentPage}&limit=${perPage}&document=${document}&state=${state}&company_name=${company_name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.allCategories = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/clients/categories/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/clients/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/clients/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.storeCategory = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/clients/categories/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/clients/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.updateCategory = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/clients/categories/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.deleteMedia = async function (uuid, media_uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/clients/${uuid}/delete/${media_uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/clients/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.deleteCategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/clients/categories/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/clients/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Client.forSelectCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/clients/categories/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default Client;
