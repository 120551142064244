import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import dashboard from "./routes/dashboard";
import customers from "./routes/customers";
import medias from "./routes/medias";
import others from "./routes/others";
import auth from "./routes/auth";
import products from "./routes/products";
import problems from "./routes/problems";
import pedagogical from "./routes/pedagogical";
import pedagogicalUnits from "./routes/pedagogical-units";
import marketing from "./routes/marketing";
import config from "./routes/config";
import orders from "./routes/orders";
import contests from "./routes/contests";
import blog from "./routes/blog";
import chartsMaps from "./routes/charts-maps";
import purchase from "./routes/purchase";
import { can } from "@/@core/libs/acl/utils";

Vue.use(VueRouter);

const DEFAULT_TITLE = process.env.VUE_APP_NAME;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard-ecommerce" } },
    ...auth,
    ...customers,
    ...medias,
    ...contests,
    ...config,
    ...blog,
    ...problems,
    ...products,
    ...marketing,
    ...pedagogicalUnits,
    ...pedagogical,
    ...dashboard,
    ...others,
    ...orders,
    ...purchase,
    ...chartsMaps,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  const excludedRoutes = ["auth-user-login", "misc-not-authorized"];

  if (excludedRoutes.includes(to.name)) {
    return next();
  }

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: "auth-user-login" });

    return next({ name: "misc-not-authorized" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

router.afterEach((to) => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }

  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
