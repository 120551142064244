import Approved from '@/_services/Http/approved';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  approveds: [],
  approved: {},
  approved_uuid: '',
  deleted: false,
  searchApproveds: [],
});

const getters = {
  error: (state) => state.error,
  StateApproved: (state) => state.approved,
};

const actions = {
  async all({}, data) {
    return await Approved.all(data)
  },
  async update({
    commit,
  }, {uuid, data}) {
 
    await Approved.update(uuid, data)
      .then((res) => {
        commit('SET_APPROVED', res.data);
      });
  },
  async show({
    commit,
  }, uuid) {
    await Approved.show(uuid)
      .then((res) => {
        commit('SET_APPROVED', res);
      });
  },
  async search({
    commit,
  }, name) {
    commit('SET_SEARCHAPPROVEDS', []);
    await Approved.search(name)
      .then((res) => {
        commit('SET_SEARCHAPPROVEDS', res);
      });
  },
  async create({
    commit,
  }, data) {
    await Approved.store(data)
      .then((res) => {
        commit('SET_APPROVED', res.data);
      });
  },
  async delete({}, uuid) {
    await Approved.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_APPROVEDS(state, approveds) {
    state.approveds = approveds;
  },
  SET_SEARCHAPPROVEDS(state, approveds) {
    state.searchApproveds = approveds;
  },
  SET_APPROVED(state, approved) {
    state.approved = approved;
  },
  SET_UUID(state, approved) {
    state.approved_uuid = approved.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
