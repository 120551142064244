import ApiService from '@/_services/Http/axios';

const Api = {
  all: {},
  allCategories: {},
  allTypes: {},

  store: {},
  delete: {},
  show: {},

  forSelect: {},

  storeCategory: {},
  deleteCategory: {},
  showCategory: {},

  storeType: {},
  deleteType: {},
  showType: {},

  search: {},
  forSelectCategories: {},
  forSelectTypes: {},
};

Api.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.allCategories = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/categories/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.allTypes = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/types/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.showType = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/types/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.showCategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/categories/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.forSelect = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/for-select?term=${filters.term ?? ''}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/types/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.forSelectCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/categories/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/bank-accounts/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.storeCategory = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/bank-accounts/categories/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.storeType = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/bank-accounts/types/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/bank-accounts/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.updateType = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/bank-accounts/types/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.updateCategory = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/bank-accounts/categories/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/bank-accounts/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.deleteCategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/bank-accounts/categories/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.deleteType = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/bank-accounts/types/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bank-accounts/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Api;
