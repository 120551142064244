// Products
export default [
  {
    path: "/products/subcategories/:uuid",
    name: "products-subcategories-list",
    component: () => import("@/views/product/Subcategories.vue"),
    meta: {
      resource: "Produtos",
      action: "Subcategoria - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-subcategory",
      },
      pageTitle: "Subcategorias",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Subcategorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/books",
    name: "products-books-list",
    component: () => import("@/views/product/book/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Apostila - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-books-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_BOOK_UUID,
      },
      pageTitle: "Apostilas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Apostilas",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/books/create",
    name: "products-books-create",
    component: () => import("@/views/product/book/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Apostila - Cadastrar",
      btnBack: "products-books-list",
      pageTitle: "Produto Apostilas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Apostilas",
          to: { name: "products-books-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/books/:uuid/edit",
    name: "products-books-edit",
    component: () => import("@/views/product/book/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Apostila - Editar",
      btnBack: "products-books-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Apostilas",
          to: { name: "products-books-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/passports",
    name: "products-passport-list",
    component: () => import("@/views/product/passport/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Passaporte - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-passport-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_PASSPORT_UUID,
      },
      pageTitle: "Passaporte",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Passaporte",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/passports/create",
    name: "products-passport-create",
    component: () => import("@/views/product/passport/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Passaporte - Cadastrar",
      btnBack: "products-passport-list",
      pageTitle: "Passaporte",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Passaporte",
          to: { name: "products-passport-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/passports/:uuid/edit",
    name: "products-passport-edit",
    component: () => import("@/views/product/passport/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Passaporte - Editar",
      btnBack: "products-passport-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Passaporte",
          to: { name: "products-passport-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/online",
    name: "products-online-list",
    component: () => import("@/views/product/online/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Online - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-online-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_ONLINE_UUID,
      },
      pageTitle: "Cursos Online",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Online",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/online/create",
    name: "products-online-create",
    component: () => import("@/views/product/online/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Online - Cadastrar",
      btnBack: "products-online-list",
      pageTitle: "Produto Online",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Online",
          to: { name: "products-online-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/online/:uuid/edit",
    name: "products-online-edit",
    component: () => import("@/views/product/online/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Online - Editar",
      btnBack: "products-online-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Online",
          to: { name: "products-online-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/presencials",
    name: "products-presencials-list",
    component: () => import("@/views/product/presencial/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Presencial - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-presencials-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_PRESENCIAL_UUID,
      },
      pageTitle: "Cursos Presenciais",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Presenciais",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/presencial/create",
    name: "products-presencials-create",
    component: () => import("@/views/product/presencial/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Presencial - Cadastrar",
      btnBack: "products-presencials-list",
      pageTitle: "Curso Presencial",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Presenciais",
          to: { name: "products-presencials-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/presencial/:uuid/edit",
    name: "products-presencials-edit",
    component: () => import("@/views/product/presencial/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Presencial - Editar",
      btnBack: "products-presencials-list",
      pageTitle: "Curso Presencial",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Presenciais",
          to: { name: "products-presencials-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/lives",
    name: "products-lives-list",
    component: () => import("@/views/product/live/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Live - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-lives-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_LIVE_UUID,
      },
      pageTitle: "Lives",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Lives",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/live/create",
    name: "products-lives-create",
    component: () => import("@/views/product/live/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Live - Cadastrar",
      btnBack: "products-lives-list",
      pageTitle: "Curso Presencial",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Lives",
          to: { name: "products-lives-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/live/:uuid/edit",
    name: "products-lives-edit",
    component: () => import("@/views/product/live/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Live - Editar",
      btnBack: "products-lives-list",
      pageTitle: "Curso Presencial",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Lives",
          to: { name: "products-lives-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/free",
    name: "products-free-list",
    component: () => import("@/views/product/free/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Grátis - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-free-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_FREE_UUID,
      },
      pageTitle: "Cursos Grátis",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Grátis",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/free/create",
    name: "products-free-create",
    component: () => import("@/views/product/free/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Grátis - Cadastrar",
      pageTitle: "Curso Grátis",
      btnBack: "products-free-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Grátis",
          to: { name: "products-free-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/free/:uuid/edit",
    name: "products-free-edit",
    component: () => import("@/views/product/free/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Grátis - Editar",
      btnBack: "products-free-list",
      pageTitle: "Curso Grátis",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Grátis",
          to: { name: "products-free-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/fast",
    name: "products-fast-list",
    component: () => import("@/views/product/fast/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Rápido - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-fast-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_FAST_UUID,
      },
      pageTitle: "Cursos Rápidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Rápidos",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/fast/create",
    name: "products-fast-create",
    component: () => import("@/views/product/fast/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Rápido - Cadastrar",
      btnBack: "products-fast-list",
      pageTitle: "Curso Rápido",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Rápidos",
          to: { name: "products-fast-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/fast/:uuid/edit",
    name: "products-fast-edit",
    component: () => import("@/views/product/fast/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Rápido - Editar",
      btnBack: "products-fast-list",
      pageTitle: "Curso Rápido",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Cursos Rápidos",
          to: { name: "products-fast-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  {
    path: "/products/questions",
    name: "products-questions-list",
    component: () => import("@/views/product/question/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Questões - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-questions-create",
      btnExport: false,
      btnSubcategoryProduct: {
        route: "products-subcategories-list",
        text: "Subcategorias",
        variant: "light",
        uuid: process.env.VUE_APP_PRODUCT_CATEGORY_QUESTION_UUID,
      },
      pageTitle: "Questões",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Questões",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/questions/create",
    name: "products-questions-create",
    component: () => import("@/views/product/question/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Questões - Cadastrar",
      btnBack: "products-questions-list",
      pageTitle: "Produto Online",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Questões",
          to: { name: "products-questions-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/questions/:uuid/edit",
    name: "products-questions-edit",
    component: () => import("@/views/product/question/Edit.vue"),
    meta: {
      resource: "Produtos",
      action: "Questões - Editar",
      btnBack: "products-questions-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Questões",
          to: { name: "products-questions-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/combo",
    name: "products-combo-list",
    component: () => import("@/views/product/combo-questoes/List.vue"),
    meta: {
      resource: "Produtos",
      action: "Combo Questões - Listar",
      btnCreate: false,
      btnCreateRouteName: "products-combo-create",
      btnExport: false,
      pageTitle: "Combo Questões",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Combo Questões",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/combo/create",
    name: "products-combo-create",
    component: () => import("@/views/product/combo-questoes/Create.vue"),
    meta: {
      resource: "Produtos",
      // btnBack: "combo-questoes-list",
      action: "Combo Questões - Cadastrar",
      pageTitle: "Combo Questões",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Combo Questões",
          to: { name: "combo-questoes-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/combo/:uuid/edit",
    name: "products-combo-edit",
    component: () => import("@/views/product/combo-questoes/Create.vue"),
    meta: {
      resource: "Produtos",
      action: "Combo Questões - Editar",
      btnBack: "products-combo-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Combo Questões",
          to: { name: "products-combo-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
];
