import ApiService from '@/_services/Http/axios';

const Sort = {
  allProducts: {},
  allContests: {},
  allContestsHome: {},
  updateProducts: {},
  allNewsHome: {},
  updateNewsHome: {},
  updateContests: {},
  updateContestsHome: {},
  updateAreasBlog: {},
};


Sort.allContestsHome = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/sorts/contests/home`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.allNewsHome = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/sorts/news/home`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.allProducts = async function (id) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/sorts/products/${id}/all`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.allContests = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/sorts/contests/all`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.updateProducts = async function (id, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/sorts/products/${id}/update`, {ids: data})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.updateContests = async function (id, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/sorts/contests/update`, {ids: data})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.updateAreasBlog = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/sorts/areas/blog`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.updateContestsHome = async function (id, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/sorts/contests/update`, {ids: data, home: true})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Sort.updateNewsHome = async function (id, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/sorts/news/update`, {ids: data, home: true})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Sort;
