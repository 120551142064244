import ApiService from "@/_services/Http/axios";

const Customer = {
  all: {},
  store: {},
  delete: {},
  search: {},
  show: {},
  leads: {},
  lead: {},
  import: {},
  update: {},
  newOrder: {},
  newOrderGrid: {},
  exportLeads: {},
  forSelect: {},
  forSelectOrders: {},
  getOrderDetails: {},
  ApiUpdateAddress: {},
  getOrders: {},
  saveFollow: {},
};

Customer.all = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/customers/list?page=${filters.currentPage}&limit=${filters.perPage}&cpf=${filters.cpf}&email=${filters.email}&name=${filters.name}&cellphone=${filters.cellphone}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.leads = async function ({
  perPage: limit,
  currentPage: page,
  filters,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/interested/list?page=${page}&limit=${limit}&origin=${filters.origin}&email=${filters.email}&name=${filters.name}&rangeDate=${filters.rangeDate}&pageType=${filters.pageType}&popup=${filters.popup}&shift=${filters.shift}&preferToStudy=${filters.preferToStudy}&unit=${filters.unit}&time=${filters.time}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.exportLeads = async function ({
  perPage: limit,
  currentPage: page,
  filters,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/interested/leads/export?page=${page}&limit=${limit}&origin=${filters.origin}&email=${filters.email}&name=${filters.name}&rangeDate=${filters.rangeDate}&pageType=${filters.pageType}&popup=${filters.popup}&shift=${filters.shift}&preferToStudy=${filters.preferToStudy}&unit=${filters.unit}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.forSelect = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/customers/for-select?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.forSelectOrders = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/customers/for-select-orders?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.getOrderDetails = async function (customerUuid, orderUuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/customers/${customerUuid}/order/${orderUuid}/show`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.lead = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/interested/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/customers/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.newOrder = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/customers/${uuid}/order/store`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.newOrderGrid = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/customers/${uuid}/order/grid/store`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/customers/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.ApiUpdateAddress = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/customers/address/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.import = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/customers/import", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/customers/delete/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/customers/${uuid}/show`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.search = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/customers/search`, filters)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.getOrders = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/customers/orders/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Customer.saveFollow = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/customers/follow`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Customer;
