import useJwt from '@core/auth/jwt/useJwt'
import axios from 'axios'

import { STORE_URL } from '@/_constants';

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Accept-Language': 'pt-BR;q=0.8, en;q=0.6, es;q=0.4',
    domain: STORE_URL,
  },
});

const { jwt } = useJwt(axiosIns, {})
export default jwt
