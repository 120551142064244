// Customers
export default [
  {
    path: "/customers/list",
    name: "customers-list",
    component: () => import("@/views/customer/customer/List.vue"),
    meta: {
      resource: "Clientes",
      action: "Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-customer",
      },
      btnExport: false,
      btnImport: true,
      pageTitle: "Clientes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          active: true,
          to: { name: "customers-list" },
        },
      ],
    },
  },
  {
    path: "/customers/:uuid/edit",
    name: "customers-edit",
    component: () => import("@/views/customer/customer/Edit.vue"),
    meta: {
      resource: "Clientes",
      action: "Editar",
      customer: {
        btnBack: "customers-list",
      },
      pageTitle: "Clientes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/:uuid/view",
    name: "customers-view",
    component: () => import("@/views/customer/customer/View.vue"),
    meta: {
      resource: "Clientes",
      action: "Visualizar",
      customer: {
        btnBack: "customers-list",
      },
      pageTitle: "Clientes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Detalhes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/:uuid/enrollment/create",
    name: "customers-enrollment-create",
    component: () => import("@/views/customer/customer/enrollment/Create.vue"),
    meta: {
      resource: "Clientes",
      action: "Matricular",
      customer: {
        btnBack: "customers-list",
      },
      pageTitle: "Clientes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Matricular aluno",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/:uuid/enrollment/disciplines",
    name: "customers-enrollment-disciplines",
    component: () => import("@/views/customer/customer/enrollment/Disciplines.vue"),
    meta: {
      resource: "Clientes",
      action: "Matricular por disciplina",
      customer: {
        btnBack: "customers-list",
      },
      pageTitle: "Clientes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Matricular aluno",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/:uuid/enrollment/:uuid/show",
    name: "customers-enrollment-show",
    component: () => import("@/views/customer/customer/enrollment/Show.vue"),
    meta: {
      resource: "Clientes",
      action: "Matricular",
      customer: {
        btnBack: "customers-list",
      },
      pageTitle: "Clientes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Detalhes da Matricula",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/leads",
    name: "customers-lead-list",
    component: () => import("@/views/customer/lead/List.vue"),
    meta: {
      resource: "Captação de Lead",
      action: "Sales Force",
      pageTitle: "Sales Force",
      btnExport: true,
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Sales Force",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/passport",
    name: "customers-passport-list",
    component: () => import("@/views/customer/passport/List.vue"),
    meta: {
      resource: "Captação de Lead",
      action: "Passaporte",
      pageTitle: "Passaporte",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Clientes",
          to: { name: "customers-list" },
        },
        {
          text: "Passaporte",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customers/ranking",
    name: "customers-ranking-list",
    component: () => import("@/views/customer/ranking/List.vue"),
    meta: {
      resource: "Clientes",
      action: "Ranking",
      pageTitle: "Ranking",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Ranking",
          active: true,
        },
      ],
    },
  },
];
