import Bank from '@/_services/Http/bank';

const state = () => ({
});

const getters = {};

const actions = {
  async forSelect({}) {
    return await Bank.forSelect()
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
