// Pedagogical
export default [
  {
    path: "/pedagogical/disciplines",
    name: "disciplines-list",
    component: () => import("@/views/pedagogical/discipline/List.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Disciplina - Listar",
      btnCreate: false,
      btnCreateRouteName: "disciplines-create",
      btnExport: false,
      pageTitle: "Disciplinas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Disciplinas",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/disciplines/create",
    name: "disciplines-create",
    component: () => import("@/views/pedagogical/discipline/Create.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Disciplina - Cadastrar",
      btnBack: "disciplines-list",
      pageTitle: "Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Disciplinas",
          to: { name: "disciplines-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/disciplines/:uuid/edit",
    name: "disciplines-edit",
    component: () => import("@/views/pedagogical/discipline/Edit.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Disciplina - Editar",
      btnBack: "disciplines-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Disciplinas",
          to: { name: "disciplines-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/lessons",
    name: "lessons-list",
    component: () => import("@/views/pedagogical/lesson/List.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Aula - Listar",
      btnCreate: false,
      btnCreateRouteName: "lessons-create",
      btnExport: true,
      pageTitle: "Aulas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Aulas",
          to: { name: "lessons-list" },
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/lessons/create",
    name: "lessons-create",
    component: () => import("@/views/pedagogical/lesson/Create.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Aula - Cadastrar",
      btnBack: "lessons-list",
      pageTitle: "Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Aulas",
          to: { name: "lessons-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/lessons/:uuid/edit",
    name: "lessons-edit",
    component: () => import("@/views/pedagogical/lesson/Edit.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Aula - Editar",
      btnBack: "lessons-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Aulas",
          to: { name: "lessons-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/materials",
    name: "materials-v1-list",
    component: () => import("@/views/pedagogical/material/List.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Material - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-material",
      },
      pageTitle: "Materiais",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Materiais",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/materials/:uuid/edit",
    name: "materials-edit",
    component: () => import("@/views/pedagogical/material/Edit.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Material - Editar",
      btnBack: "materials-v1-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Materiais",
          to: { name: "materials-v1-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/teachers",
    name: "teachers-list",
    component: () => import("@/views/pedagogical/teacher/List.vue"),
    meta: {
      resource: "Professor",
      action: "Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-teacher",
      },
      pageTitle: "Professores",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Professores",
          to: { name: "teachers-list" },
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/teachers/:uuid/show",
    name: "teachers-show",
    component: () => import("@/views/pedagogical/teacher/Show.vue"),
    meta: {
      resource: "Professor",
      action: "Visualizar",
      btnBack: "teachers-list",
      pageTitle: "Professores",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Professores",
          to: { name: "teachers-list" },
        },
        {
          text: "Perfil",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/teachers/:uuid/edit",
    name: "teachers-edit",
    component: () => import("@/views/pedagogical/teacher/Edit.vue"),
    meta: {
      resource: "Professor",
      action: "Editar",
      btnBack: "teachers-list",
      pageTitle: "Professores",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Professores",
          to: { name: "teachers-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/approved-students",
    name: "approved-students-list",
    component: () => import("@/views/pedagogical/approved/List.vue"),
    meta: {
      resource: "Ensino a Distância",
      action: "Aprovado - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-approved",
      },
      pageTitle: "Aprovados",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Aprovados",
          to: { name: "approved-students-list" },
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
];
