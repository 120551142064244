import ApiService from '@/_services/Http/axios';

const Approved = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
};

Approved.all = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/approved-students?page=${filters.page}&limit=${filters.limit}&title=${filters.title}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Approved.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/approved-students/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Approved.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/approved-students/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Approved.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/approved-students/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Approved.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/approved-students/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Approved.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/approved-students/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Approved;
