import ApiService from '@/_services/Http/axios';

const Material = {
  all: {},
  store: {},
  delete: {},
  deleteFile: {},
  show: {},
  search: {},
  update: {},
  searchMaterial: {},
  searchType: {},
  forSelectSearchTypeUnits: {},
};


Material.all = async function ({ perPage: limit, currentPage: page, filters }) {
  return new Promise((resolve, reject) => {
    const tag_subject = filters.tag_subject ?? '';
    const tag_contest = filters.tag_contest ?? '';
    const tag_timeline = filters.tag_timeline ?? '';
    const rangeDate = filters.rangeDate ?? '';
    const teacher = filters.teacher ?? '';
    const title = filters.title ?? '';
    const ead = filters.ead ?? '';

    ApiService.get(`/v1/admin/materials/list?page=${page}&limit=${limit}&rangeDate=${rangeDate}&tag_subject=${tag_subject}&tag_contest=${tag_contest}&tag_timeline=${tag_timeline}&teacher=${teacher}&title=${title}&ead=${ead}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.allType = async function ({ perPage: limit, currentPage: page, filters }) {
  return new Promise((resolve, reject) => {
    const tag_subject = filters.tag_subject ?? '';
    const tag_contest = filters.tag_contest ?? '';
    const tag_timeline = filters.tag_timeline ?? '';
    const rangeDate = filters.rangeDate ?? '';
    const teacher = filters.teacher ?? '';
    const type = filters.type ?? '';
    const title = filters.title ?? '';

    ApiService.get(`/v1/admin/materials?page=${page}&limit=${limit}&rangeDate=${rangeDate}&tag_subject=${tag_subject}&tag_contest=${tag_contest}&tag_timeline=${tag_timeline}&teacher=${teacher}&type=${type}&title=${title}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/materials/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.searchType = async function (term, type) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/materials/search?term=${term}&type=${type}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.forSelectSearchTypeUnits = async function (term, type) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/materials/search?term=${term}&type=${type}&ead=0`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/materials/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/materials/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.store = async function (data) {  
  return new Promise((resolve, reject) => {
    ApiService.post(
      '/v1/admin/materials/store',
      data,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

Material.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/materials/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Material.deleteFile = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/materials/${uuid}/file`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Material;
