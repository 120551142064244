import ApiService from "@/_services/Http/axios";

const Bill = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: [],
};

Bill.all = async function ({
  perPage: limit,
  currentPage: page,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/bills/list?page=${page}&limit=${limit}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Bill.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bills/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Bill.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/bills/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Bill.update = async function ({ uuid, data } = data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/bills/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Bill.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/bills/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Bill.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/bills/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Bill;
