import Contract from "@/_services/Http/contract";

const actions = {
  async all({}, filters) {
    return await Contract.all(filters);
  },
  async edit({}, data) {
    return await Contract.edit(data);
  },
  async store({}, data) {
    return await Contract.store(data);
  },
  async create({}, data) {
    return await Contract.store(data);
  },
  async forSelect({}) {
    return await Contract.forSelectTypes();
  },
  async delete({}, uuid) {
    await Contract.delete(uuid);
  },
};

export default {
  namespaced: true,
  actions,
};
