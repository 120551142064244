import ApiService from '@/_services/Http/axios';

const Tag = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  categories: {},
  toCategory: [],
};

Tag.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/tags/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.categories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/tags/categories`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.toCategory = async function ( category_id ) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/tags?category=${category_id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.search = async function (category, term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/tags/search?term=${term}&category=${category}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/tags/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/tags/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/tags/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Tag.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/tags/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Tag;
