import Coupon from '@/_services/Http/coupon';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  coupons: [],
  coupon: {},
  coupon_uuid: '',
  deleted: false,
  searchCoupons: {},
});

const getters = {
  error: (state) => state.error,
  StateCoupon: (state) => state.coupon,
};

const actions = {
  async all({}, data) {
    return await Coupon.all(data)
  },
  async show({}, uuid) {
    return await Coupon.show(uuid);
  },
  async create({}, data) {
    return await Coupon.store(data)
  },
  async update({}, {uuid, data}) {
    return await Coupon.update({uuid, data})
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCHCOUPONS', []);
    await Coupon.search(term)
      .then((res) => {
        commit('SET_SEARCHCOUPONS', res);
      });
  },
  async delete({}, uuid) {
    await Coupon.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_COUPONS(state, data) {
    state.coupons = data;
  },
  SET_SELECT(state, data) {
    state.couponsSelect = data;
  },
  SET_COUPON(state, data) {
    state.coupon = data;
  },
  SET_SEARCHCOUPONS(state, data) {
    state.searchCoupons = data;
  },
  SET_UUID(state, data) {
    state.coupon_uuid = data.uuid;
  },
  DELETED(state, data) {
    state.deleted = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
