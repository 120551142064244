import ApiService from '@/_services/Http/axios';

const Auth = {
  User: {},
  Customer: {},
  Teacher: {},
  forgetPassword: {},
};

Auth.User.authUser = async function (form) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/auth/users/login', form)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Auth.User.createPassword = async function (form) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/jwt/user/validity', form)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Auth.User.forgetPassword = async function (email) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/jwt/user/forget-password?email=${email}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Auth;
