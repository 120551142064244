import Supplier from '@/_services/Http/supplier';

const actions = {
  async all({}, filters) {
    return await Supplier.all(filters)
  },
  async allCategories({}, filters) {
    return await Supplier.allCategories(filters)
  },
  async show({}, uuid) {
    return await Supplier.show(uuid)
  },
  async store({}, data) {
    return await Supplier.store(data)
  },
  async storeCategory({}, data) {
    return await Supplier.storeCategory(data)
  },  
  async create({}, data) {
    return await Supplier.store(data)
  },
  async forSelectCategories({}) {
    return await Supplier.forSelectCategories();
  },
  async forSelectTypes({}) {
    return await Supplier.forSelectTypes();
  },
  async update({}, {uuid, data}) {
    return await Supplier.update(uuid, data)
  },
  async updateCategory({}, {uuid, data}) {
    return await Supplier.updateCategory(uuid, data)
  },
  async delete({}, uuid) {
    await Supplier.delete(uuid);
  },
  async deleteCategory({}, uuid) {
    await Supplier.deleteCategory(uuid);
  },
  async deleteMedia({}, { uuid, media_uuid}) {
    await Supplier.deleteMedia(uuid, media_uuid);
  },

  async search({}, term) {
    return await Supplier.search(term);
  },
};

export default {
  namespaced: true,
  actions,
};
