import Permission from '@/_services/Http/permission';

const state = () => ({
});

const getters = {
};

const actions = {
  async all({}, filters) {
    return await Permission.all(filters);
  },
  async create({}, data) {
    return await Permission.create(data);
  },
  async update({}, {uuid, data}) {
    return await Permission.update(uuid, data);
  },
  async delete({}, {uuid}) {
    return await Permission.delete(uuid);
  },
  async show({}, uuid) {
    return await Permission.show(uuid);
  },
  async forSelect({}) {
    return await Permission.forSelect();
  }
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
