import ApiService from '@/_services/Http/axios';

const SF = {
  all: {},
  store: {},
  delete: {},
  update: {},
  show: {},
  search: {},
};

SF.all = async function ({ perPage: limit, currentPage: page, filters }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/salesforce/list?page=${page}&limit=${limit}&title=${filters.title ?? ''}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

SF.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/salesforce/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

SF.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/salesforce/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

SF.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/salesforce/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

SF.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/salesforce/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

SF.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/salesforce/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default SF;
