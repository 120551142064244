import Http from '@/_services/Http/auth';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
});

const getters = {
  error: (state) => state.error,
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
};

const actions = {
  async authLogin({
    state,
    commit,
    dispatch,
  }, form) {
    await Http.User.authUser(form)
      .then((res) => {
        dispatch('AuthUser/setStoreLoading', { status: false, message: 'Operação realizada com sucesso' }, { root: true });
        // dispatch('AuthUser/setUser');
        return {
          user: res.data.user,
          token: res.data.token.token,
        };
      })
      .catch((err) => {
        dispatch('AuthUser/setStoreLoading', { status: false, message: '', error: err }, { root: true });
      });
  },
  async newPassword({
    state,
    commit,
    dispatch,
  }, form) {
    await Http.User.createPassword(form)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        dispatch('AuthUser/setStoreLoading', { status: false, message: '', error: err }, { root: true });
      });
  },

  async forgetPassword({}, email) {
    return await Http.User.forgetPassword(email)
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
  async LogOut({ commit }) {
    commit('logout', null);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  setUser(state, user) {
    state.user = user;
  },
  LogOut(state) {
    state.user = null;
    state.posts = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
