import Client from '@/_services/Http/client';

const actions = {
  async all({}, filters) {
    return await Client.all(filters)
  },
  async allCategories({}, filters) {
    return await Client.allCategories(filters)
  },
  async show({}, uuid) {
    return await Client.show(uuid)
  },
  async store({}, data) {
    return await Client.store(data)
  },
  async storeCategory({}, data) {
    return await Client.storeCategory(data)
  },  
  async create({}, data) {
    return await Client.store(data)
  },
  async forSelectCategories({}) {
    return await Client.forSelectCategories();
  },
  async forSelectTypes({}) {
    return await Client.forSelectTypes();
  },
  async update({}, {uuid, data}) {
    return await Client.update(uuid, data)
  },
  async updateCategory({}, {uuid, data}) {
    return await Client.updateCategory(uuid, data)
  },
  async delete({}, uuid) {
    await Client.delete(uuid);
  },
  async deleteCategory({}, uuid) {
    await Client.deleteCategory(uuid);
  },
  async deleteMedia({}, { uuid, media_uuid}) {
    await Client.deleteMedia(uuid, media_uuid);
  },  
};

export default {
  namespaced: true,
  actions,
};
