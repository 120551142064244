import Organ from '@/_services/Http/organ';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  organs: [],
  organ: {},
  organ_uuid: '',
  deleted: false,
  organsSelect: [],
  searchOrgans: [],
});

const getters = {
  error: (state) => state.error,
  StateOrgan: (state) => state.organ,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Organ.all(data)
      .then((res) => {
        commit('SET_ORGANS', res);
      });
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCHORGANS', []);
    await Organ.search(term)
      .then((res) => {
        commit('SET_SEARCHORGANS', res);
      });
  },
  async allToSelect({
    commit,
  }) {
    await Organ.all()
      .then((res) => {
        commit('SET_ORGANS', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Organ.show(uuid)
      .then((res) => {
        commit('SET_ORGAN', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await Organ.store(data)
      .then((res) => {
        commit('SET_ORGAN', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Organ.update({uuid, data})
      .then((res) => {
        commit('SET_ORGAN', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async delete({}, uuid) {
    await Organ.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_ORGANS(state, organs) {
    state.organs = organs;
  },
  SET_SELECT(state, organs) {
    state.organsSelect = organs;
  },
  SET_ORGAN(state, organ) {
    state.organ = organ;
  },
  SET_SEARCHORGANS(state, organ) {
    state.searchOrgans = organ;
  },
  SET_UUID(state, organ) {
    state.organ_uuid = organ.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
