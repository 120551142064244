// Orders
export default [
  {
    path: "/orders",
    name: "transactions-orders-list",
    component: () => import("@/views/finance/transaction/order/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Pedido - Listar",
      btnExport: true,
      pageTitle: "Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Pedidos",
          active: true,
        },
      ],
    },
  },

  {
    path: "/orders/cancellations",
    name: "transactions-orders-cancellations-list",
    component: () =>
      import("@/views/finance/transaction/cancellation/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Cancelada - Listar",
      btnExport: true,
      pageTitle: "Cancelamentos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Cancelamentos",
          active: true,
        },
      ],
    },
  },

  {
    path: "/orders/installments",
    name: "transactions-orders-installments-list",
    component: () => import("@/views/finance/transaction/installment/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Parcela - Listar",
      btnExport: true,
      pageTitle: "Parcelas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Parcelas",
          active: true,
        },
      ],
    },
  },

  {
    path: "/orders/courses",
    name: "transactions-orders-courses-list",
    component: () => import("@/views/finance/transaction/course/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Por Curso - Listar",
      btnExport: true,
      pageTitle: "Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Por curso",
          active: true,
        },
      ],
    },
  },

  {
    path: "/orders/enrollments",
    name: "transactions-orders-enrollments-list",
    component: () => import("@/views/finance/transaction/enrollment/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Matrícula - Listar",
      btnExport: true,
      pageTitle: "Matrículas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Matrículas",
          active: true,
        },
      ],
    },
  },

  {
    path: "/orders/:uuid/show",
    name: "transactions-orders-preview",
    component: () => import("@/views/finance/transaction/order/View.vue"),
    meta: {
      resource: "Comercial",
      action: "Pedido - Visualizar",
      pageTitle: "Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedidos",
          to: { name: "transactions-orders-list" },
        },
        {
          text: "Detalhes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/create",
    name: "transactions-orders-create",
    component: () => import("@/views/finance/transaction/order/Create.vue"),
    meta: {
      resource: "Comercial",
      action: "Pedidos - Cadastrar",
      pageTitle: "Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedidos",
          to: { name: "transactions-orders-list" },
        },
        {
          text: "Detalhes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/products",
    name: "orders-products-list",
    component: () => import("@/views/finance/transaction/product/Index.vue"),
    meta: {
      resource: "Comercial",
      action: "Por Produto - Listar",
      pageTitle: "Pedidos",
      btnExport: true,
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Por produto",
          active: true,
        },
      ],
    },
  },

  {
    path: "/bills/receive/list",
    name: "bills-receive-list",
    component: () => import("@/views/finance/to-receive/List.vue"),
    meta: {
      resource: "Contas",
      action: "A Receber - Listar",
      btnCreateRouteName: "bills-create",
      pageTitle: "Contas a receber",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "A pagar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bills/receive/create",
    name: "bills-create",
    component: () => import("@/views/finance/bills/Create.vue"),
    meta: {
      resource: "Contas",
      action: "A Receber - Listar",
      btnCreateRouteName: "bills-create",
      pageTitle: "Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "A receber",
          to: { name: "bills-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bills/receive/edit",
    name: "bills-edit",
    component: () => import("@/views/finance/bills/Edit.vue"),
    meta: {
      resource: "Contas",
      action: "A Receber - Editar",
      btnCreateRouteName: "bills-edit",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "A pagar",
          to: { name: "bills-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  {
    path: "/bills/pay/edit",
    name: "bills-edit",
    component: () => import("@/views/finance/bills/Edit.vue"),
    meta: {
      resource: "Contas",
      action: "A Pagar - Listar",
      btnCreateRouteName: "bills-edit",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "A pagar",
          to: { name: "bills-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  {
    path: "/bills/calendar",
    name: "bills-calendar",
    component: () => import("@/views/finance/calendar/Index.vue"),
    meta: {
      resource: "Contas",
      action: "Calendário - Listar",
      pageTitle: "Calendário",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Calendário",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/categories",
    name: "orders-category-list",
    component: () => import("@/views/finance/transaction/category/Index.vue"),
    meta: {
      resource: "Comercial",
      action: "Por Categoria - Listar",
      pageTitle: "Por Categoria",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedidos",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/days",
    name: "orders-day-list",
    component: () => import("@/views/finance/transaction/day/Index.vue"),
    meta: {
      resource: "Comercial",
      action: "Por Dia - Listar",
      pageTitle: "Por Dia",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Por dia",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/sellers",
    name: "orders-seller-list",
    component: () => import("@/views/finance/transaction/seller/Index.vue"),
    meta: {
      resource: "Comercial",
      action: "Por Vendedor - Listar",
      pageTitle: "Por Vendedor",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Por vendedor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/subscriptions",
    name: "orders-subscriptions-list",
    component: () => import("@/views/finance/transaction/order/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Assinatura - Listar",
      pageTitle: "Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Assinatura",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/coupons",
    name: "transactions-orders-coupons",
    component: () => import("@/views/finance/transaction/order/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Por Cupom - Listar",
      pageTitle: "Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Por Cupom",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/jarvis",
    name: "transactions-ia-chat",
    component: () => import("@/views/finance/bot/Chat.vue"),
    meta: {
      resource: "Comercial",
      action: "Jarvis",
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
  {
    path: "/tickets-discount",
    name: "tickets-discount-list",
    component: () => import("@/views/finance/ticket-discount/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Ticket Desconto - Listar",
      btnExport: true,
      pageTitle: "Solicitações de desconto",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
          active: true,
        },
        {
          text: "Solicitações de desconto",
          active: true,
        },
      ],
    },
  },

  {
    path: "/acquirers",
    name: "acquirers-list",
    component: () => import("@/views/finance/acquirer/List.vue"),
    meta: {
      resource: "Adquirentes",
      action: "Listar",
      btnCreateRouteName: "acquirers-create",
      pageTitle: "Adquirentes",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Adquirentes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/acquirers/create/:uuid?",
    name: "acquirers-create",
    component: () => import("@/views/finance/acquirer/Create.vue"),
    meta: {
      resource: "Adquirentes",
      action: "Cadastrar",
      btnBack: "acquirers-list",
      pageTitle: "Cadastrar adquirente",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Adquirentes",
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/acquirers/:uuid/edit",
    name: "acquirers-edit",
    component: () => import("@/views/finance/acquirer/Edit.vue"),
    meta: {
      resource: "Adquirentes",
      action: "Editar",
      btnBack: "acquirers-list",
      pageTitle: "Editar adquirente",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Adquirentes",
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  {
    path: "/card-brands",
    name: "card-brands-list",
    component: () => import("@/views/finance/card-brand/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Pedido - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-card-brand",
      },
      pageTitle: "Bandeiras ",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Adquirentes",
        },
        {
          text: "Bandeiras",
          active: true,
        },
      ],
    },
  },

  // CLIENT
  {
    path: "/clients/categories/list",
    name: "clients-categories-list",
    component: () => import("@/views/finance/client/category/List.vue"),
    meta: {
      resource: "Contas",
      action: "Clientes - Categorias",
      pageTitle: "Categorias",
      btnBack: "clients-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-client-category",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Clientes",
          to: { name: "clients-list" },
        },
        {
          text: "Categorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/clients/list",
    name: "clients-list",
    component: () => import("@/views/finance/client/List.vue"),
    meta: {
      resource: "Contas",
      action: "Clientes - Listar",
      btnSubcategory: {
        route: "clients-categories-list",
        text: "Categorias",
        variant: "warning",
      },
      pageTitle: "Fornecedores",
      btnCreateRouteName: "clients-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Clientes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/clients/create",
    name: "clients-create",
    component: () => import("@/views/finance/client/Create.vue"),
    meta: {
      resource: "Contas",
      action: "Clientes - Cadastrar",
      pageTitle: "Cadastrar",
      btnBack: "clients-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Clientes",
          to: { name: "clients-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/clients/:uuid/edit",
    name: "clients-edit",
    component: () => import("@/views/finance/client/Edit.vue"),
    meta: {
      resource: "Contas",
      action: "Clientes - Editar",
      pageTitle: "Fornecedor",
      btnBack: "clients-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Clientes",
          to: { name: "clients-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  {
    path: "/orders/letter-credits/list",
    name: "orders-letters-credit-list",
    component: () => import("@/views/finance/letter-credit/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Pedido - Cartas de Crédito",
      pageTitle: "Cartas de crédito",
      // modal: {
      //   btnCreate: true,
      //   btnCreateModalId: "modal-letter-credits",
      // },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Cartas de crédito",
          active: true,
        },
      ],
    },
  },
  {
    path: "/indications/list",
    name: "indications-list",
    component: () => import("@/views/finance/indications/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Indicação - Listar",
      pageTitle: "Indicações",
      btnExport: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-indication",
        permission: {
          resource: "Comercial",
          action: "Indicação - Cadastrar",
        },
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Vendas",
        },
        {
          text: "Indicações",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/remittances/create",
    name: "transactions-remittances-create",
    component: () =>
      import("@/views/finance/transaction/remittance/Create.vue"),
    meta: {
      resource: "Comercial",
      action: "Repasse - Listar",
      pageTitle: "Repasses",
      btnExport: false,
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Repasses",
        },
        {
          text: "Criar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/remittances/approve-manager",
    name: "transactions-remittances-manager-approve-list",
    component: () =>
      import("@/views/finance/transaction/remittance/ApproveManager.vue"),
    meta: {
      resource: "Comercial",
      action: "Repasse - Listar",
      pageTitle: "Repasses",
      btnExport: false,
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Repasses",
        },
        {
          text: "Aprovar como Gerente",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/remittances/approve-finance",
    name: "transactions-remittances-finance-approve-list",
    component: () =>
      import("@/views/finance/transaction/remittance/ApproveFinance.vue"),
    meta: {
      resource: "Comercial",
      action: "Repasse - Listar",
      pageTitle: "Repasses",
      btnExport: false,
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Repasses",
        },
        {
          text: "Aprovar como Financeiro",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/remittances/approved",
    name: "transactions-remittances-approved-list",
    component: () =>
      import("@/views/finance/transaction/remittance/Approved.vue"),
    meta: {
      resource: "Comercial",
      action: "Repasse - Listar",
      pageTitle: "Repasses",
      btnExport: false,
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Repasses",
        },
        {
          text: "Aprovados",
          active: true,
        },
      ],
    },
  },
];
