import Vindi from "@/_services/Http/vindi";

const state = () => ({
  loading: true,
  message: "Aguarde",
  bills: [],
});

const getters = {};

const actions = {
  async bills({}, data) {
    return await Vindi.bills(data);
  },
  async paymentMethods() {
    return await Vindi.paymentMethods();
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
