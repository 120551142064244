import ApiService from '@/_services/Http/axios'

const TimeToCall = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
}

TimeToCall.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/timetocalls?page=${page}&limit=${limit}`)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

TimeToCall.list = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get('/v1/admin/timetocalls')
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

TimeToCall.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/timetocalls/${uuid}/show`)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

TimeToCall.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/timetocalls/store', data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

TimeToCall.update = async function ({ uuid, data }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/timetocalls/${uuid}`, data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

TimeToCall.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/timetocalls/${uuid}`)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default TimeToCall
