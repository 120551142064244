import ApiService from "@/_services/Http/axios";
import discipline from "@/store/discipline";

const Grid = {
  all: {},
  store: {},
  create: {},
  delete: {},
  deleteLesson: {},
  deleteLessons: {},
  updateLessons: {},
  updateLesson: {},
  show: {},
  search: {},
  weekdays: {},
  forSelect: {},
  forSelectDays: {},
  forSelectTypes: {},
  forSelectLessonCategories: {},
  fetchEventsOfGrid: {},
  updateEvent: {},
  showLesson: {},
  forSelectLessonStatus: {},
  getAllLessonsTeacherPoints: {},
  showGridLessonTeacherPoints: {},
  signatureTeacherPoints: {},
  signatureMultipleTeacherPoints: {},
  getAllLessonsTeacher: {},
  storeOnlyLesson: {},
  deleteLessonOfDisciplines: {},
  lessonConnect: {},
  view: {},
  generateTeacherPointsReport: {},
  getReportsTeacherPoints: {},
  getReportsTeacherPointsByUuid: {},
  deleteReportTeacherPoints: {},
  disciplines: {},
};

Grid.disciplineLessons = async function (data) {
  const uuid = data.discipline_id;
  const grid = data.grid_id;
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/lessons/discipline/${uuid}/grid/${grid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.disciplines = async function (data) {
  const id = data.grid_id;
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/${id}/disciplines`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.deleteReportTeacherPoints = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/grids/lessons/teacher-points/report/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.getReportsTeacherPointsByUuid = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/lessons/teacher-points/reports/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.getReportsTeacherPoints = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/grids/lessons/teacher-points/reports", {
      page: filters.currentPage ?? 1,
      limit: filters.perPage ?? 100,
      date: filters.date ?? "",
      teachers: filters.teachers ?? "",
      id: filters.id ?? "",
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.generateTeacherPointsReport = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/grids/lessons/teacher-points/report", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.getAllLessonsTeacherPoints = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/grids/lessons/teacher-points/list", {
      page: filters.currentPage,
      limit: filters.perPage,
      date: filters.date,
      classroom: filters.classroom,
      unit: filters.unit?.code ?? "",
      shift: filters.shift?.code ?? "",
      teachers: filters.teachers,
      signature: filters.signature,
      report: filters.report,
      type: filters.type,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.getAllLessonsTeacher = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/grids/lessons/teachers/list?page=${
        filters.currentPage
      }&limit=${filters.perPage}&dates=${filters.dates}&discipline=${
        filters.discipline?.code ?? ""
      }&status=${filters.status?.code ?? ""}&classroom=${
        filters.classroom
      }&unit=${filters.unit?.code ?? ""}&shift=${
        filters.shift?.code ?? ""
      }&teacher=${filters.teacher?.code ?? ""}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.showGridLessonTeacherPoints = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/lessons/teacher-points/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.lessonConnect = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/grids/lessons/connect`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.signatureTeacherPoints = async function (uuids, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/grids/lessons/teacher-points/${uuid}/signature`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.signatureMultipleTeacherPoints = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/grids/lessons/teacher-points/signature/multiple`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.all = async function ({ perPage: limit, currentPage: page, classroom }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/grids/list?page=${page}&limit=${limit}&classroom=${classroom}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.view = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/${uuid}/view`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.showLesson = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/lessons/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/grids/store", data)
      .then((res) => {
        resolve(res.data.uuid);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.storeOnlyLesson = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/grids/lessons/store/many", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.storeImport = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/grids/store-import", data)
      .then((res) => {
        resolve(res.data.uuid);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.create = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/${uuid}/create`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.updateEvent = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/grids/lessons/${uuid}/event/update`, data)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.updateLesson = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/grids/lessons/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.update = async function ({ gridUuid, classUuid, lesson }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/grids/${gridUuid}/update`, {
      classUuid: classUuid,
      lesson: lesson,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/grids/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.deleteLessonOfDisciplines = async function (uuid, data, mode) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/grids/${uuid}/disciplines/lessons/${mode}`, {
      disciplines: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.deleteLessons = async function (uuid, lessons) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/grids/lessons/${uuid}/selected`, {
      lessons: lessons,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.updateLessons = async function (uuids) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/grids/lessons/update-all`, { uuids: uuids })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.deleteLesson = async function (uuid, grid_uuid, mode) {
  return new Promise((resolve, reject) => {
    ApiService.delete(
      `/v1/admin/grids/lessons/${grid_uuid}/${uuid}/delete/${mode}`
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/search`, {
      term,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/for-select-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.forSelectLessonStatus = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/lessons/status/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.fetchEventsOfGrid = async function (uuid, filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/grids/lessons/${uuid}/for-calendar?filters=${JSON.stringify(
        filters
      )}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.forSelectLessonCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/lessons/categories/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.weekdays = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/weekdays`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Grid.forSelectDays = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/grids/for-select-days`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Grid;
