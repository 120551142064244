import ApiService from "@/_services/Http/axios";

const Streaming = {
  all: {},
  forSelect: {},
};

Streaming.all = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/streamings/list?page=1&limit=10`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Streaming.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/streamings/for-select`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Streaming;
