import ClassroomModality from '@/_services/Http/classroom-modalidity';

const state = () => ({
});

const getters = {
};

const actions = {
  async all({}, data) {
    return await ClassroomModality.all(data)
  },
  async show({}, uuid) {
    return await ClassroomModality.show(uuid)
  },
  async create({}, data) {
    return await ClassroomModality.store(data)
  },
  async update({}, {uuid, data}) {
    return await ClassroomModality.update({uuid, data})
  },
  async search({}, term) {
    return await ClassroomModality.search(term)
  },
  async delete({}, {uuid}) {
    await ClassroomModality.delete(uuid);
  },
  async forSelect({}, data) {
    return await ClassroomModality.forSelect(data)
  },  
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
