import ApiService from "@/_services/Http/axios";

const Course = {
  ApiAll: {},
  ApiStore: {},
  ApiDelete: {},
  ApiShow: {},
  ApiSearch: {},
  forSelect: {},
  forSelectTypes: {},
  ApiDeleteDiscipline: {},

  ApiSalesPanel: {},

  ApiAllTypes: {},
  ApiStoreType: {},
  ApiDeleteType: {},
  ApiShowType: {},
};

Course.ApiAll = async function (filters) {
  filters = {
    page: filters.page,
    limit: filters.limit,
    course_sale: filters.course_sale ? filters.course_sale.code : "",
    title: filters.title ?? "",
    course_type: filters.course_type ? filters.course_type.code : "",
    status: filters.status ? filters.status.code : "",
    datasheet: filters.datasheet ? filters.datasheet.code : "",
    scholarity: filters.scholarity ? filters.scholarity.code : "",
  };

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/courses/list?page=${filters.page}&limit=${filters.limit}&course_sale=${filters.course_sale}&title=${filters.title}&course_type=${filters.course_type}&status=${filters.status}&datasheet=${filters.datasheet}&scholarity=${filters.scholarity}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiAllTypes = async function (filters) {
  filters = {
    page: filters.currentPage,
    limit: filters.perPage,
    title: filters.title ?? "",
  };

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/courses/types/list?page=${filters.page}&limit=${filters.limit}&title=${filters.title}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiShow = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiSalesPanel = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/sales-panel/all`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiShowType = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/types/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiStore = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/courses/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiStoreType = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/courses/types/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiUpdate = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/courses/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiUpdateType = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/courses/types/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiDeleteType = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/courses/types/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiDeleteDiscipline = async function (uuid, discipline_uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/courses/${uuid}/discipline/${discipline_uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiSearch = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/search/?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.ApiSearchStatus = async function (term, status) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/search/?term=${term}&status=${status}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/for-select-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Course.forSelect = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/courses/for-select&term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Course;
