import Order from "@/_services/Http/order";

const state = () => ({
  error: "",
  orders: [],
  order: {},
});

const actions = {
  async all({}, data) {
    return await Order.all(data);
  },
  async fetchAllCancelled({}, data) {
    return await Order.allCancelled(data);
  },
  async updateCancelledState({}, data) {
    const response = await Order.updateCancelledState(data);
    return response.data;
  },
  async fetchSyncJson({}, uuid) {
    return await Order.syncJson(uuid);
  },
  async fetchSyncJsonByUuids({}, uuids) {
    return await Order.syncJsonByUuids(uuids);
  },
  async allEnrollments({}, data) {
    return await Order.allEnrollments(data);
  },
  async allProducts({}, data) {
    return await Order.allProducts(data);
  },
  async allCourses({}, data) {
    return await Order.allCourses(data);
  },
  async allInstallments({}, data) {
    return await Order.allInstallments(data);
  },
  async export({}, data) {
    return await Order.export(data);
  },
  async exportProducts({}, data) {
    return await Order.exportProducts(data);
  },
  async exportInstallments({}, data) {
    return await Order.exportInstallments(data);
  },
  async enrollmentsExport({}, data) {
    return await Order.enrollmentsExport(data);
  },
  async reverseLetterCredit({}, { uuid, data }) {
    return await Order.reverseLetterCredit(uuid, data)
  },
  async create({}, data) {
    return await Order.store(data);
  },
  async edit({}, { uuid: uuid, data: data }) {
    return await Order.edit(uuid, data);
  },
  async cancelOrder({}, { uuid, data }) {
    return await Order.cancelOrder(uuid, data);
  },
  async show({}, uuid) {
    return await Order.show(uuid);
  },
  async status({}) {
    return await Order.status();
  },
  async paymentMethods({}) {
    return await Order.paymentMethods();
  },
  async returnMethods({}) {
    return await Order.returnMethods();
  },
  async createEnrollment({}, { uuid, data }) {
    return await Order.storeEnrollment(uuid, data);
  },
  async requestDiscount({}, { uuid, data }) {
    return await Order.requestDiscount(uuid, data);
  },
  async requestDiscountProducts({}, { uuid, data }) {
    return await Order.requestDiscountProducts(uuid, data);
  },
  async requestDiscountStatus({}, sessionId) {
    return await Order.requestDiscountStatus(sessionId);
  },
  async createRemittance({}, data) {
    return await Order.storeRemittance(data);
  },
  async deleteRemittancesByIds({}, data) {
    return await Order.deleteRemittancesByIds(data);
  },
  async storeObservation({}, data) {
    return await Order.storeObservation(data);
  },
  async listRemittance({}, data) {
    return await Order.listRemittances(data);
  },
  async approveRemittances({}, data) {
    return await Order.approveRemittances(data);
  },
  async forSelectOrigins({}, data) {
    return await Order.forSelectOrigins(data);
  },
  async pageToReceive({}, data) {
    return await Order.getAllReceive(data);
  },
  async forSelectDocumentTypes({}) {
    return await Order.forSelectDocumentTypes();
  },
  async emitirNotaFiscal({}, data) {
    return await Order.emitirNotaFiscal(data);
  },
  async viewNFE({}, data) {
    return await Order.viewNFE(data);
  },
  async viewNFSE({}, data) {
    return await Order.viewNFSE(data);
  },
  async sendNFProduct({}, data) {
    return await Order.sendNFProduct(data);
  },
  async sendNFService({}, data) {
    return await Order.sendNFService(data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
