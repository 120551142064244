import ApiService from "@/_services/Http/axios";

const Supplier = {
  all: {},
  allCategories: {},
  store: {},
  storeCategory: {},
  delete: {},
  deleteCategory: {},
  show: {},
  search: {},
  forSelectCategories: {},
  forSelectTypes: {},
  update: {},
  updateCategory: {},
};

Supplier.all = async function ({
  perPage,
  currentPage,
  document,
  company_name,
  state,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/suppliers/list?page=${currentPage}&limit=${perPage}&document=${document}&state=${state}&company_name=${company_name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.allCategories = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/suppliers/categories/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/suppliers/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/suppliers/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.storeCategory = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/suppliers/categories/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/suppliers/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.updateCategory = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/suppliers/categories/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.deleteMedia = async function (uuid, media_uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/suppliers/${uuid}/delete/${media_uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/suppliers/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.deleteCategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/suppliers/categories/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/suppliers/search?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.forSelectCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/suppliers/categories/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Supplier.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/suppliers/types/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Supplier;
