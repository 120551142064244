import Opportunity from "@/_services/Http/opportunity";

const state = () => ({});

const actions = {
  async fetchSteps({}) {
    return await Opportunity.fetchSteps();
  },
  async fetchModalities({}) {
    return await Opportunity.fetchModalities();
  },
  async updateStep({}, data) {
    return await Opportunity.updateSteps(data);
  },
  async deleteStep({}, uuid) {
    return await Opportunity.deleteStep(uuid);
  },
  async storeTeam({}, data) {
    return await Opportunity.storeTeam(data);
  },
  async updateTeam({}, { uuid, data }) {
    return await Opportunity.updateTeam(uuid, data);
  },
  async deleteTeam({}, uuid) {
    return await Opportunity.deleteTeam(uuid)
  },
}

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
