import ApiService from "@/_services/Http/axios";

const Vindi = {
  bills: {},
};

Vindi.bills = async function (params) {
  try {
    const res = await ApiService.get(`/v1/admin/vindi/bills`, params);
    console.log(res);
    return res.data.bills;
  } catch (error) {}
};

Vindi.paymentMethods = async function () {
  try {
    const res = await ApiService.get(`/v1/admin/vindi/payment-methods`);
    return res.data.payment_methods;
  } catch (error) {}
};

export default Vindi;
