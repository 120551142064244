import Vue from 'vue'

// axios
import axios from 'axios'
import btoa from 'btoa';
import { STORE_URL } from '@/_constants';

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Accept-Language': 'pt-BR;q=0.8, en;q=0.6, es;q=0.4',
    School: btoa(btoa(btoa(STORE_URL))),
  },
});

Vue.prototype.$http = axiosIns

export default axiosIns
