import ModelContract from '@/_services/Http/model-contract';

const actions = {
  async all({}, filters) {
    return await ModelContract.all(filters)
  },
  async show({}, uuid) {
    return await ModelContract.show(uuid)
  },
  async store({}, data) {
    return await ModelContract.store(data)
  },
  async update({}, { uuid, data }) {
    return await ModelContract.update({uuid, data})
  },
  async forSelect({}, term) {
    return await ModelContract.forSelect(term)
  },
  async delete({}, uuid) {
    return await ModelContract.delete(uuid);
  }
}

export default {
  namespaced: true,
  actions,
};
