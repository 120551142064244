import ManagerDiscount from "@/_services/Http/manager-discount";

const state = () => ({
});

const getters = {
};

const actions = {
  async all({}, data) {
    return await ManagerDiscount.all(data);
  },
  async create({}, data) {
    return await ManagerDiscount.store(data);
  },
  async update({}, { uuid: uuid, data: data }) {
    return await ManagerDiscount.update(uuid, data);
  },
  async show({}, uuid) {
    return await ManagerDiscount.show(uuid)
  },
  async delete({}, data) {
    return await ManagerDiscount.delete(data.uuid);
  },
  async search({}, term) {
    return await ManagerDiscount.search(term);
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
