import ApiService from "@/_services/Http/axios";

const Contract = {
  all: {},
  store: {},
  delete: {},
  edit: {},
  update: {},
  forSelect: {},
};

Contract.all = async function ({ page, limit, category }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/contracts/list?page=${page}&limit=${limit}&category=${category}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contract.edit = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/contracts/${data.uuid}/edit`, data.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contract.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/contracts/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contract.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/contracts/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contract.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/contracts/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Contract.forSelect = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/contracts/for-select?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Contract;
