import Vue from "vue";
import Vuex from "vuex";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

// Auth
import AuthUser from "./auth/user";
import AuthCustomer from "./auth/customer";
import AuthTeacher from "./auth/teacher";

import Dashboard from "./dashboard";
import Lesson from "./lesson";
import User from "./user";
import School from "./school";
import Streaming from "./streaming";
import Teacher from "./teacher";
import Unit from "./unit";
import Faq from "./faq";
import PrivacyPolicy from "./privacy-policy";
import TeacherNotes from "./teacherNotes";
import Media from "./media";
import Banner from "./banner";
import Discipline from "./discipline";
import Approved from "./approved";
import Tag from "./tag";
import Gateway from "./gateway";
import Customer from "./customer";
import Product from "./product";
import Area from "./area";
import Organ from "./organ";
import Jury from "./jury";
import Office from "./office";
import Situation from "./situation";
import Scholarity from "./scholarity";
import Shift from "./shift";
import State from "./state";
import Datasheet from "./datasheet";
import TimeToCall from "./timetocall";
import Know from "./know";
import Contest from "./contest";
import SalesForce from "./salesforce";
import Partner from "./partner";
import Config from "./config";
import Material from "./material";
import Coupon from "./coupon";
import Sort from "./sort";
import Post from "./post";
import Order from "./order";
import Author from "./author";
import Supplier from "./supplier";
import Bill from "./bill";
import Course from "./course";
import CourseDiscipline from "./course-discipline";
import CourseSale from "./course-sale";
import Classroom from "./classroom";
import ClassroomModality from "./classroom-modality";
import Grid from "./grid";
import Permission from "./permission";
import ManagerDiscount from "./manager-discount";
import CardNetwork from "./card-network";
import Acquirer from "./acquirer";
import Warning from "./warning";
import Client from "./client";
import OrderPurchase from "./order-purchase";
import BusinessUnit from "./business-unit";
import StrategicUnit from "./strategic-unit";
import CostCenter from "./cost-center";
import Bank from "./bank";
import LetterCredit from "./letter-credit";
import Contract from "./contract";
import ModelContract from "./model-contract";
import BankAccount from "./bank-account";
import Company from "./company";
import Indications from "./indications";
import Vindi from "./vindi";
import Opportunity from './opportunity';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    "app-ecommerce": ecommerceStoreModule,
    AuthUser,
    AuthCustomer,
    AuthTeacher,
    Customer,
    User,
    Dashboard,
    School,
    Streaming,
    Lesson,
    Teacher,
    TeacherNotes,
    Unit,
    Media,
    Faq,
    PrivacyPolicy,
    Material,
    Banner,
    Discipline,
    Approved,
    Tag,
    Gateway,
    Product,
    Order,
    Area,
    Organ,
    Jury,
    Office,
    Scholarity,
    Situation,
    Shift,
    State,
    Datasheet,
    TimeToCall,
    Know,
    Contest,
    SalesForce,
    Partner,
    Config,
    Coupon,
    Sort,
    Post,
    Author,
    Supplier,
    Bill,
    Course,
    CourseDiscipline,
    CourseSale,
    Classroom,
    ClassroomModality,
    Grid,
    Permission,
    ManagerDiscount,
    CardNetwork,
    Acquirer,
    Warning,
    Client,
    OrderPurchase,
    BusinessUnit,
    StrategicUnit,
    CostCenter,
    Bank,
    LetterCredit,
    Contract,
    ModelContract,
    BankAccount,
    Company,
    Indications,
    Vindi,
    Opportunity,
  },
  strict: process.env.DEV,
});
