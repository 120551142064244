import OrderPurchase from "@/_services/Http/order-purchase";

const actions = {
  async all({}, filters) {
    return await OrderPurchase.all(filters);
  },
  async allPayments({}, filters) {
    return await OrderPurchase.allPayments(filters);
  },
  async allPaymentAnalysis({}, filters) {
    return await OrderPurchase.allPaymentAnalysis(filters);
  },
  async allPaymentStatus({}, filters) {
    return await OrderPurchase.allPaymentStatus(filters);
  },
  async allApprovedPurchases({}, filters) {
    return await OrderPurchase.allApprovedPurchases(filters);
  },
  async allApproved({}, filters) {
    return await OrderPurchase.allApproved(filters);
  },
  async allSteps({}) {
    return await OrderPurchase.allSteps();
  },
  async updatePurchaseOrderStep({}, { items, step, status, bank_account }) {
    return await OrderPurchase.updatePurchaseOrderStep(
      items,
      step,
      status,
      bank_account
    );
  },
  async edit({}, uuid) {
    return await OrderPurchase.edit(uuid);
  },
  async editPayment({}, uuid) {
    return await OrderPurchase.editPayment(uuid);
  },
  async updateBankAccount({}, { uuid, data }) {
    return await OrderPurchase.updateBankAccount(uuid, data);
  },
  async editBankAccount({}, uuid) {
    return await OrderPurchase.editBankAccount(uuid);
  },
  async editApprovedPurchase({}, uuid) {
    return await OrderPurchase.editApprovedPurchase(uuid);
  },
  async store({}, data) {
    return await OrderPurchase.store(data);
  },
  async create({}, data) {
    return await OrderPurchase.store(data);
  },
  async update({}, { uuid, data }) {
    return await OrderPurchase.update(uuid, data);
  },
  async updatePayment({}, { uuid, data }) {
    return await OrderPurchase.updatePayment(uuid, data);
  },
  async createNewPayment({}, data) {
    return await OrderPurchase.createNewPayment(data);
  },
  async updatePaymentInstallment({}, { uuid, data }) {
    return await OrderPurchase.updatePaymentInstallment(uuid, data);
  },
  async updatePayments({}, { uuids, status }) {
    return await OrderPurchase.updatePayments(uuids, status);
  },
  async updateApprovedPurchase({}, data) {
    return await OrderPurchase.updateApprovedPurchase(data);
  },
  async updateStep({}, { uuid, data }) {
    return await OrderPurchase.updateStep(uuid, data);
  },
  async deleteMedia({}, { uuid, media_uuid }) {
    await OrderPurchase.deleteMedia(uuid, media_uuid);
  },
  async delete({}, uuid) {
    await OrderPurchase.delete(uuid);
  },
  async forSelectSteps({}) {
    return await OrderPurchase.forSelectSteps();
  },
  async forSelectOfSteps({}, { ids, term }) {
    return await OrderPurchase.forSelectOfSteps(ids, term);
  },
  async forSelectInstallmentStatus({}, filters) {
    return await OrderPurchase.forSelectInstallmentStatus(filters);
  },
  async cancelPayment({}, data) {
    return await OrderPurchase.cancelPayment(data);
  },
  async updateInstallmentStatus({}, { items, status, completed_date }) {
    return await OrderPurchase.updateInstallmentStatus(
      items,
      status,
      completed_date
    );
  },
  async revertInstallmentStatus({}, { items }) {
    return await OrderPurchase.revertInstallmentStatus(items);
  },
  async getFiles({}, uuid) {
    return await OrderPurchase.getFiles(uuid);
  },
  async uploadFiles({}, { uuid, data }) {
    return await OrderPurchase.uploadFiles(uuid, data);
  },
  async deleteFile({}, uuid) {
    return await OrderPurchase.deleteFile(uuid);
  },
  /// PAYMENTS
  async forSelectPayments({}, term) {
    return await OrderPurchase.forSelectPayments(term);
  },

  async getPaymentByUuid({}, uuid) {
    return await OrderPurchase.getPaymentByUuid(uuid);
  },

  // TYPES
  async forSelectTypes({}) {
    return await OrderPurchase.forSelectTypes();
  },
  //////////

  // TYPE DOCUMENTS
  async forSelectDocumentTypes({}) {
    return await OrderPurchase.forSelectDocumentTypes();
  },
  //////////

  // CATEGORIES
  async allCategories({}, filters) {
    return await OrderPurchase.allCategories(filters);
  },
  async storeCategory({}, data) {
    return await OrderPurchase.storeCategory(data);
  },
  async forSelectCategories({}) {
    return await OrderPurchase.forSelectCategories();
  },
  async updateCategory({}, { uuid, data }) {
    return await OrderPurchase.updateCategory(uuid, data);
  },
  async deleteCategory({}, uuid) {
    await OrderPurchase.deleteCategory(uuid);
  },
  //////////////////

  // PLANS
  async allPlans({}, filters) {
    return await OrderPurchase.allPlans(filters);
  },
  async storePlan({}, data) {
    return await OrderPurchase.storePlan(data);
  },
  async forSelectPlans({}) {
    return await OrderPurchase.forSelectPlans();
  },
  async updatePlan({}, { uuid, data }) {
    return await OrderPurchase.updatePlan(uuid, data);
  },
  async deletePlan({}, uuid) {
    await OrderPurchase.deletePlan(uuid);
  },
  async forSelectBusinessUnitsByPlanUuid({}, uuid) {
    return await OrderPurchase.forSelectBusinessUnitsByPlanUuid(uuid);
  },
  //////////////////

  // OPERATIONS
  async allOperations({}, filters) {
    return await OrderPurchase.allOperations(filters);
  },
  async storeOperation({}, data) {
    return await OrderPurchase.storeOperation(data);
  },
  async forSelectOperations({}) {
    return await OrderPurchase.forSelectOperations();
  },
  async updateOperation({}, { uuid, data }) {
    return await OrderPurchase.updateOperation(uuid, data);
  },
  async deleteOperation({}, uuid) {
    await OrderPurchase.deleteOperation(uuid);
  },

  // STATUS
  async allStatus({}, filters) {
    return await OrderPurchase.allStatus(filters);
  },
  async storeStatus({}, data) {
    return await OrderPurchase.storeStatus(data);
  },
  async forSelectStatus({}) {
    return await OrderPurchase.forSelectStatus();
  },
  async updateStatus({}, { uuid, data }) {
    return await OrderPurchase.updateStatus(uuid, data);
  },
  async deleteStatus({}, uuid) {
    await OrderPurchase.deleteStatus(uuid);
  },

  // RELEASE TYPES
  async allReleaseTypes({}, filters) {
    return await OrderPurchase.allReleaseTypes(filters);
  },
  async storeReleaseTypes({}, data) {
    return await OrderPurchase.storeReleaseTypes(data);
  },
  async forSelectReleaseTypes({}) {
    return await OrderPurchase.forSelectReleaseTypes();
  },
  async updateReleaseTypes({}, { uuid, data }) {
    return await OrderPurchase.updateReleaseTypes(uuid, data);
  },
  async deleteReleaseTypes({}, uuid) {
    await OrderPurchase.deleteStatus(uuid);
  },

  // ROMANEIO
  async listMovements({}, filters) {
    return await OrderPurchase.listMovements(filters);
  },

  async getMovementByUuid({}, uuid) {
    return await OrderPurchase.getMovementByUuid(uuid);
  },

  async getPaymentMovementByUuid({}, uuid) {
    return await OrderPurchase.getPaymentMovementByUuid(uuid);
  },

  async listPaymentMovements({}, filters) {
    return await OrderPurchase.listPaymentMovements(filters);
  },
};

export default {
  namespaced: true,
  actions,
};
