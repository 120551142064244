import Grid from "@/_services/Http/grid";

const state = () => ({
  start: "",
  end: "",
  disciplines: [],
  days: [],
  discipline: "",
  events: [],
  uuid: "",
  event: "",
  filters: {
    disciplines: [],
    teachers: [],
  },
});

const getters = {};

const actions = {
  async all({}, data) {
    return await Grid.all(data);
  },
  async show({}, uuid) {
    return await Grid.show(uuid);
  },
  async fetchView({}, uuid) {
    return await Grid.view(uuid);
  },
  async create({}, uuid) {
    return await Grid.create(uuid);
  },
  async store({}, data) {
    return await Grid.store(data);
  },
  async storeOnlyLesson({}, data) {
    return await Grid.storeOnlyLesson(data);
  },
  async storeImport({}, data) {
    return await Grid.storeImport(data);
  },
  async update({}, { classUuid, gridUuid, lesson }) {
    return await Grid.update({ classUuid, gridUuid, lesson });
  },
  async search({}, term) {
    return await Grid.search(term);
  },
  async disciplines({}, data) {
    return await Grid.disciplines(data);
  },
  async delete({}, { uuid }) {
    await Grid.delete(uuid);
  },
  async deleteLesson({}, { uuid, grid_uuid, mode }) {
    await Grid.deleteLesson(uuid, grid_uuid, mode);
  },
  async forSelectTypes({}, data) {
    return await Grid.forSelectTypes(data);
  },
  async forSelect({}, data) {
    return await Grid.forSelect(data);
  },
  async forSelectLessonCategories({}, data) {
    return await Grid.forSelectLessonCategories(data);
  },
  async forSelectDays({}, data) {
    return await Grid.forSforSelectDayselect(data);
  },
  async forSelectLessonStatus({}, data) {
    return await Grid.forSelectLessonStatus(data);
  },
  async weekdays({}, data) {
    return await Grid.weekdays(data);
  },
  async showLesson({ commit }, uuid) {
    return await Grid.showLesson(uuid).then((lesson) => {
      commit("UPDATE_EVENT", lesson);
    });
  },
  async fetchEventsOfGrid({ commit }, { uuid, filters }) {
    return await Grid.fetchEventsOfGrid(uuid, filters).then((events) => {
      commit("UPDATE_EVENTS", events);
    });
  },
  async updateEvent({}, { uuid, data }) {
    return await Grid.updateEvent(uuid, data);
  },
  async updateLesson({}, { uuid, data }) {
    return await Grid.updateLesson(uuid, data);
  },
  async deleteLessonOfDisciplines({}, { uuid, data, mode }) {
    return await Grid.deleteLessonOfDisciplines(uuid, data, mode);
  },
  async getAllLessonsTeacherPoints({}, filters) {
    return await Grid.getAllLessonsTeacherPoints(filters);
  },
  async generateTeacherPointsReport({}, data) {
    return await Grid.generateTeacherPointsReport(data);
  },
  async getReportsTeacherPoints({}, filters) {
    return await Grid.getReportsTeacherPoints(filters);
  },
  async getReportsTeacherPointsByUuid({}, uuid) {
    return await Grid.getReportsTeacherPointsByUuid(uuid);
  },
  async deleteReportTeacherPoints({}, uuid) {
    return await Grid.deleteReportTeacherPoints(uuid);
  },
  async getAllLessonsTeacher({}, filters) {
    return await Grid.getAllLessonsTeacher(filters);
  },
  async signatureTeacherPoints({}, { uuid, data }) {
    return await Grid.signatureTeacherPoints(uuid, data);
  },
  async signatureMultipleTeacherPoints({}, data) {
    return await Grid.signatureMultipleTeacherPoints(data);
  },
  async showGridLessonTeacherPoints({}, uuid) {
    return await Grid.showGridLessonTeacherPoints(uuid);
  },
  async lessonConnect({}, data) {
    return await Grid.lessonConnect(data);
  },
  async deleteLessons({}, { uuid, lessons }) {
    return await Grid.deleteLessons(uuid, lessons);
  },
  async updateLessons({}, { uuids }) {
    return await Grid.updateLessons(uuids);
  },
  async disciplineLessons({}, data) {
    return await Grid.disciplineLessons(data);
  },
  updateRangeDate({ commit }, data) {
    commit("UPDATE_RANGE_DATE", data);
  },
  setUuid({ commit }, data) {
    commit("UPDATE_UUID", data);
  },
  setDisciplines({ commit }, data) {
    commit("UPDATE_DISCIPLINES", data);
  },
  setFiltersDisciplines({ commit }, data) {
    commit("UPDATE_FILTERS_DISCIPLINES", data);
  },
  setDiscipline({ commit }, data) {
    commit("UPDATE_DISCIPLINE", data);
  },
  updateDays({ commit }, days) {
    commit("UPDATE_DAYS", days);
  },
  resetDays({ commit }) {
    commit("RESET_DAYS");
  },
  updateEvents({ commit }, data) {
    commit("UPDATE_EVENTS", data);
  },
  resetCalendar({ commit }) {
    commit("RESET_CALENDAR");
  },
  resetEvent({ commit }) {
    commit("UPDATE_EVENT", "");
  },
};

const mutations = {
  RESET_CALENDAR(state) {
    state.start = "";
    state.end = "";
    state.disciplines = [];
    state.days = [];
    state.discipline = "";
    state.events = [];
    state.uuid = "";
    state.event = "";
    state.filters = {
      disciplines: [],
      teachers: [],
    };
  },
  RESET_DAYS(state) {
    state.days = [];
  },
  UPDATE_UUID(state, data) {
    state.uuid = data;
  },
  UPDATE_DAYS(state, data) {
    state.days = data;
  },
  UPDATE_DISCIPLINE(state, data) {
    state.discipline = data;
  },
  UPDATE_RANGE_DATE(state, data) {
    let copyState = state;
    copyState.start = data.start;
    copyState.end = data.end;

    state = copyState;
  },
  UPDATE_EVENTS(state, data) {
    let copyState = state;
    copyState.events = data;

    state = copyState;
  },
  UPDATE_DISCIPLINES(state, data) {
    state.disciplines = data;
  },
  UPDATE_FILTERS_DISCIPLINES(state, data) {
    state.filters.disciplines = data;
  },
  UPDATE_EVENT(state, data) {
    state.event = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
