import ApiService from "@/_services/Http/axios";

const CostCenter = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  forSelect: {},
  update: {},
};

CostCenter.all = async function ({
  perPage,
  currentPage,
  name,
  group,
  active
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/cost-centers/list?page=${currentPage}&limit=${perPage}&name=${name}&group=${group}&active=${active}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CostCenter.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/cost-centers/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CostCenter.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/cost-centers/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CostCenter.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/cost-centers/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CostCenter.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/cost-centers/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CostCenter.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/cost-centers/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CostCenter.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/cost-centers/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default CostCenter;
