import Teacher from '@/_services/Http/teacher';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  teachers: [],
  teacher: {},
  teacher_uuid: '',
  deleted: false,
  searchTeachers: [],
});

const getters = {
  error: (state) => state.error,
  StateTeacher: (state) => state.teacher,
};

const actions = {
  async all({
    commit,
  }, filters) {
    await Teacher.all(filters)
      .then((res) => {
        commit('SET_TEACHERS', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    await Teacher.show(uuid)
      .then((res) => {
        commit('SET_TEACHER', res.data);
      });
  },
  async search({}, term) {
    return await Teacher.search(term);
  },
  async getByTagsSubjects({}, {disciplineUuid: uuid}) {
    return await Teacher.getByTagsSubjects(uuid);
  },
  async create({}, data) {
    return await Teacher.store(data)
  },
  async update({}, data) {
    return await Teacher.update(data)
  },
  async delete({}, data) {
    await Teacher.delete(data.uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_TEACHERS(state, teachers) {
    state.teachers = teachers;
  },
  SET_SEARCH(state, teachers) {
    state.searchTeachers = teachers;
  },
  SET_TEACHER(state, teacher) {
    state.teacher = teacher;
  },
  SET_UUID(state, teacher) {
    state.teacher_uuid = teacher.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
