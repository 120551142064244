import ApiService from "@/_services/Http/axios";

const ManagerDiscount = {
  all: {},
  store: {},
  update: {},
  delete: {},
  search: {},
  show: {},
};

ManagerDiscount.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/managers-discount/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ManagerDiscount.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/managers-discount/store", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ManagerDiscount.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/managers-discount/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ManagerDiscount.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/managers-discount/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ManagerDiscount.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/managers-discount/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ManagerDiscount.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/managers-discount/search?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default ManagerDiscount;
