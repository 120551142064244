import ApiService from '@/_services/Http/axios';

const CourseSale = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
};

CourseSale.all = async function (filters) {

  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-sales/list?page=${filters.currentPage}&limit=${filters.perPage}&title=${filters.title}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-sales/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/course-sales/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/course-sales/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/course-sales/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-sales/search/?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-sales/for-select-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseSale.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-sales/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default CourseSale;
