import TeacherNotes from '@/_services/Http/teacherNotes';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: false,
  teacherNotes: [],
  teacherNote: {},
  teacherNote_uuid: '',
  deleted: false,
});

const getters = {
  error: (state) => state.error,
  StateTeacherNotes: (state) => state.teacherNote,
};

const actions = {
  async all({
    commit,
  }, data) {
    await TeacherNotes.all(data)
      .then((res) => {
        commit('SET_NOTES', res.data);
      });
  },
  async search({
    commit,
  }, name) {
    commit('SET_LOADING', true);
    commit('SET_SEARCH', []);
    await TeacherNotes.search(name)
      .then((res) => {
        commit('SET_SEARCH', res);
      });
    commit('SET_LOADING', false);
  },
  async create({
    commit,
  }, data) {
    await TeacherNotes.store(data);
  },
  async delete({}, data) {
    await TeacherNotes.delete(data.uuid);
  },
  async update({
    commit,
  }, data) {
    await TeacherNotes.update(data)
      .then((res) => {
        commit('SET', res.data.data);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_NOTES(state, res) {
    state.teacherNotes = res;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
  SET_UUID(state, uuid) {
    state.teacherNote_uuid = uuid;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
