import Api from '@/_services/Http/bank-account';

const actions = {
  async all({ }, filters) {
    return await Api.all(filters)
  },
  async allCategories({ }, filters) {
    return await Api.allCategories(filters)
  },
  async allTypes({ }, filters) {
    return await Api.allTypes(filters)
  },
  async show({ }, uuid) {
    return await Api.show(uuid)
  },
  async store({ }, data) {
    return await Api.store(data)
  },
  async showCategory({ }, uuid) {
    return await Api.showCategory(uuid)
  },
  async showType({ }, uuid) {
    return await Api.showType(uuid)
  },
  async storeType({ }, data) {
    return await Api.storeType(data)
  },
  async storeCategory({ }, data) {
    return await Api.storeCategory(data)
  },
  async create({ }, data) {
    return await Api.store(data)
  },
  async createCategory({ }, data) {
    return await Api.store(data)
  },
  async forSelect({ }, filters) {
    return await Api.forSelect(filters);
  },
  async forSelectCategories({ }) {
    return await Api.forSelectCategories();
  },
  async forSelectTypes({ }) {
    return await Api.forSelectTypes();
  },
  async update({ }, { uuid, data }) {
    return await Api.update(uuid, data)
  },
  async updateCategory({ }, { uuid, data }) {
    console.log(data)
    return await Api.updateCategory(uuid, data)
  },
  async updateType({ }, { uuid, data }) {
    return await Api.updateType(uuid, data)
  },
  async delete({ }, uuid) {
    await Api.delete(uuid);
  },
  async deleteCategory({ }, uuid) {
    await Api.deleteCategory(uuid);
  },
  async deleteType({ }, uuid) {
    await Api.deleteType(uuid);
  },

  async search({ }, term) {
    console.log(term)
    return await Api.search(term);
  },
};

export default {
  namespaced: true,
  actions,
};
