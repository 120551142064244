import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import filters from './mixin/filters';
// import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Toastification from "@core/components/toastification/Toastification";

import 'bootstrap-icons/font/bootstrap-icons.css'
import './global-components'
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
// import io from 'socket.io-client'

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(VueTheMask);
Vue.use(VueCompositionAPI);
Vue.use(Vuelidate);

Vue.mixin({
  filters: filters,
  methods: {
    notify(_title, _icon, _variant, _text, _time) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: _title,
            icon: _icon,
            variant: _variant,
            text: _text,
          },
        },
        {
          position: 'top-center',
          timeout: _time | 4000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          hideProgressBar: true,
          showCloseButtonOnHover: true,
        }
      );
    },
    notifyV2(_title, _icon, _variant, _text, _time) {
      this.$toast(
        {
          component: Toastification,
          props: {
            title: _title,
            icon: _icon,
            variant: _variant,
            text: _text,
          },
        },
        {
          position: 'top-center',
          timeout: _time | 4000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          hideProgressBar: true,
          showCloseButtonOnHover: true,
        }
      );
    },
    notifyDefault(v, title) {
      let _title, _icon, _variant;

      _icon = v === 'error' ? 'XIcon' : 'CheckIcon';
      _variant = v === 'error' ? 'danger' : 'success';

      switch (v) {
        case 'success':
          _title = title ?? 'Operação realizada com sucesso!';
          break
        case 'error':
          _title = title ?? 'Erro na operação';
          break
        default:
          _title = title ?? 'Verifique os campos obrigatórios.';
          break
      }

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: _title,
            icon: _icon,
            variant: _variant,
          },
        },
        {
          position: 'top-center',
          timeout: 4000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          hideProgressBar: true,
          showCloseButtonOnHover: true,
        }
      );
    },
    optionEditor({ height, selector }) {
      const options = {
        language: 'pt_BR',
        selector: selector ?? '.text-editor',
        plugins:
          "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars emoticons",
        imagetools_cors_hosts: ["picsum.photos"],
        menubar: "file edit view insert format tools table",
        emoticons_database: 'emojis',
        toolbar:
          "undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        image_advtab: true,
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        file_picker_callback: function (callback, value, meta) {
          if (meta.filetype === "file") {
            callback("https://www.google.com/logos/google.jpg", {
              text: "My text",
            });
          }

          if (meta.filetype === "image") {
            callback("https://www.google.com/logos/google.jpg", {
              alt: "My alt text",
            });
          }

          if (meta.filetype === "media") {
            callback("movie.mp4", {
              source2: "alt.ogg",
              poster: "https://www.google.com/logos/google.jpg",
            });
          }
        },
        templates: [
          {
            title: "Nova tabela",
            description: "Criar uma nova tabela",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
          },
          {
            title: "Começar a escrever",
            description: "",
            content: "",
          },
          {
            title: "Nova lista",
            description: "",
            content: '',              
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %d/%m/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %d/%m/%Y : %H:%M:%S]",
        height: height,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table",
        skin: "oxide",
        content_css: "default",
        content_style:
          "body { font-family: Poppins,sans-serif; font-size: 1rem }",
          color_map: [
            '00C390', '00C390', '00F7B7', '00F7B7', '00DDA3', '00DDA3', '031AF5', '031AF5'
          ]
      }

      return options;
    },
    optionEditorBasic({ height, selector }) {
      const options = {
        language: 'pt_BR',
        selector: selector ?? '.text-editor',
       
        emoticons_database: 'emojis',
        toolbar:
          "bold italic underline strikethrough | fontsizeselect",
        toolbar_sticky: true,
        height: height,
        skin: "oxide",
        content_css: "default",
        content_style:
          "body { font-family: Poppins,sans-serif; font-size: 1rem }",
          color_map: [
            '00C390', '00C390', '00F7B7', '00F7B7', '00DDA3', '00DDA3', '031AF5', '031AF5'
          ]
      }

      return options;
    }
  }
})

require('@core/assets/fonts/feather/iconfont.css');
require('@core/scss/core.scss');
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

// Vue.prototype.$socket = io(`${process.env.VUE_APP_API_URL}`)

// Inicializa a propriedade global para armazenar a rota anterior
Vue.prototype.$previousRoute = null;

// Adiciona um navigation guard global para atualizar a rota anterior antes de cada navegação
router.beforeEach((to, from, next) => {
  Vue.prototype.$previousRoute = from.fullPath;
  next();
});

new Vue({
  metaInfo: {
    meta: [
      { name: 'robots', content: 'noindex' }
    ]
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
