import Material from '@/_services/Http/material';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  materials: [],
  proofs: [],
  notices: [],
  material: {},
  material_uuid: '',
  deleted: false,
  searchMaterials: [],
  searchNotices: [],
  searchTests: [],
  searchNoticeLast: [],
  searchProofs: [],
});

const getters = {
  error: (state) => state.error,
  StateMaterial: (state) => state.material,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Material.all(data)
      .then((res) => {
        commit('SET_MATERIALS', res);
      });
  },
  async allType({
    commit,
  }, data) {
    await Material.allType(data)
      .then((res) => {
        switch (data.filters.type) {
          case 1:
            commit('SET_MATERIALS', res);
            break;
          case 4:
            commit('SET_NOTICES', res);
            break;
          case 5: 
            commit('SET_PROOFS', res);
            break;
          default:
            break;
        }
      });
  },
  async update({
    commit,
  }, {uuid: uuid, data: data}) {
    await Material.update(uuid, data)
      .then((res) => {
        commit('SET_MATERIAL', res.data.data);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Material.show(uuid)
      .then((res) => {
        commit('SET_MATERIAL', res);
        commit('SET_LOADING', false);
      });
  },
  async search({
    commit,
  }, name) {
    commit('SET_LOADING', true);
    commit('SET_SEARCH', []);
    await Material.search(name)
      .then((res) => {
        commit('SET_SEARCH', res);
      });
    commit('SET_LOADING', false);
  },
  async searchType({
    commit,
  }, {term: term, type: type}) {
    await Material.searchType(term, type)
      .then((res) => {
        switch(type) {
          case 1:
            commit('SET_SEARCHMATERIALS', res);
          break;
          case 4:
            commit('SET_SEARCHNOTICES', res);
          break;
          case 5:
            commit('SET_SEARCHPROOFS', res);
          break;
          default: 
          break;
        }
      });
  },
  async forSelectSearchTypeUnits({}, {term: term, type: type}) {
    return await Material.forSelectSearchTypeUnits(term, type)
  },
  async create({
    commit,
  }, data) {
    await Material.store(data)
      .then((res) => {
        commit('SET_MATERIAL', res);
      });
  },
  async delete({}, data) {
    await Material.delete(data.uuid);
  },
  async deleteFile({}, data) {
    await Material.deleteFile(data.uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_MATERIALS(state, data) {
    state.materials = [];
    state.materials = data;
  },
  SET_NOTICES(state, data) {
    state.notices = [];
    state.notices = data;
  },
  SET_PROOFS(state, data) {
    state.proofs = [];
    state.proofs = data;
  },
  SET_SEARCH(state, data) {
    state.searchMaterials = [];
    state.searchMaterials = data;
  },
  SET_SEARCHMATERIALS(state, data) {
    state.searchMaterials = [];
    state.searchMaterials = data;
  },
  SET_SEARCHNOTICES(state, data) {
    state.searchNotices = [];
    state.searchNotices = data;
  },
  SET_SEARCHPROOFS(state, data) {
    state.searchProofs = [];
    state.searchProofs = data;
  },
  SET_MATERIAL(state, material) {
    state.material = material;
  },
  SET_UUID(state, material) {
    state.material_uuid = material.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
