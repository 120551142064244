import ApiService from '@/_services/Http/axios';

const State = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: [],
};

State.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/states?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

State.list = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get('/v1/admin/states')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

State.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/states/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

State.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/states/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

State.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/states/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

State.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/states/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

State.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/states/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default State;
