import ApiService from '@/_services/Http/axios';

const Bank = {
  forSelect: {},
};

Bank.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/banks/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Bank;
