import ApiService from '@/_services/Http/axios';

const Discipline = {
  all: {},
  store: {},
  edit: {},
  delete: {},
  show: {},
  update: {},
  search: {},
  searchForSelectForCourse: {},
};

Discipline.all = async function ({ perPage: limit, currentPage: page, filters }) {  
  return new Promise((resolve, reject) => {
    const tag_subject = filters.tag_subject ?? '';
    const tag_contest = filters.tag_contest ?? '';
    const tag_timeline = filters.tag_timeline ?? '';
    const rangeDate = filters.rangeDate ?? '';
    const teacher = filters.teacher ?? '';
    const title = filters.title ?? '';
    const ead = filters.ead ?? 0;

    ApiService.get(`/v1/admin/disciplines/list?page=${page}&limit=${limit}&rangeDate=${rangeDate}&tag_subject=${tag_subject}&teacher=${teacher}&tag_contest=${tag_contest}&tag_timeline=${tag_timeline}&title=${title}&ead=${ead}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Discipline.search = async function (term, ead = 0) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/disciplines/search?term=${term}&ead=${ead}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Discipline.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/disciplines/store', data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Discipline.update = async function ({ uuid: uuid, data: data }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/disciplines/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Discipline.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/disciplines/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Discipline.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/disciplines/delete/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Discipline;
