import Config from '@/_services/Http/config';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  email: '',
});

const getters = {
  error: (state) => state.error,
};

const actions = {
  async show({}) {
    return await Config.show();
  },
  async updateSchoolBling({}, data) {
    return await Config.updateSchoolBling(data)
  },
  async updateSchoolOpens({}, data) {
    return await Config.updateSchoolOpens(data)
  },
  async update({}, data) {
    return await Config.update(data)
  },
  async fetchSchoolBling({}) {
    return await Config.fetchSchoolBling()
  },
  async fetchSchoolOpens({}) {
    return await Config.fetchSchoolOpens()
  },
  async email({
    commit,
  }) {
    commit('SET_LOADING', true);
    await Config.email()
      .then((res) => {
        commit('SET_EMAIL', res);
        commit('SET_LOADING', false);
      });
  },
  async emailUpdate({
    commit,
  }, data) {
    commit('SET_LOADING', true);
    await Config.emailUpdate(data)
      .then((res) => {
        commit('SET_LOADING', false);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async forSelectTypeCompanies({}) {
    return await Config.forSelectTypeCompanies()
  },
  async fetchOctadesk({}) {
    return await Config.fetchSchoolOctadesk()
  },
  async fetchOctadeskTemplates({}) {
    return await Config.fetchOctadeskTemplates()
  },
  async fetchSchoolApps({}) {
    return await Config.fetchSchoolApps()
  },
  async deleteSchoolOctadeskTemplate({}, uuid) {
    return await Config.deleteSchoolOctadeskTemplate(uuid)
  },
  async deleteSchoolEmailSendgridTemplate({}, uuid) {
    return await Config.deleteSchoolEmailSendgridTemplate(uuid)
  },
  async updateSchoolOctadesk({}, data) {
    return await Config.updateSchoolOctadesk(data)
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_EMAIL(state, data) {
    state.email = data;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
