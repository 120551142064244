import Product from "@/_services/Http/product";

const state = () => ({
  loading: true,
  message: "Aguarde",
  error: false,
  products: [],
  productsOnline: [],
  productOnline: {},
  productOnline_uuid: "",
  deleted: false,
  productSubcategories: [],
  productSubcategory: {},
  searchSubcategory: {},
  searchSubcategories: [],
  categories: [],
});

const getters = {
  error: (state) => state.error,
  StateProduct: (state) => state.productOnline,
};

const actions = {
  async all({}, data) {
    return await Product.all(data);
  },
  async allDiscounts({}, data) {
    return await Product.allDiscounts(data);
  },
  async allSubcategories({ commit }, data) {
    await Product.allSubcategories(data).then((res) => {
      commit("SET_PRODUCTSUBCATEGORIES", res);
    });
  },
  async allCategory({}, categoryId) {
    return await Product.allCategory(categoryId);
  },
  async create({}, data) {
    return await Product.store(data);
  },
  async createSubcategory({}, { uuid, data }) {
    await Product.storeSubcategory(uuid, data);
  },
  async show({}, uuid) {
    return await Product.show(uuid);
  },
  async showDiscount({}, uuid) {
    return await Product.showDiscount(uuid);
  },
  async update({ commit }, uuid, data) {
    await Product.update(uuid, data).then((res) => {
      commit("SET_PRODUCT", res.data);
    });
  },
  async showSubcategory({ commit }, uuid) {
    await Product.showSubcategory(uuid).then((res) => {
      if (res) {
        commit("SET_PRODUCTSUBCATEGORY", res.data);
      }
    });
  },
  async delete({}, uuid) {
    await Product.deleteOnline(uuid);
  },
  async deleteSubcategory({}, uuid) {
    await Product.deleteSubcategory(uuid);
  },
  async update({ commit }, uuid, data) {
    await Product.update(uuid, data).then((res) => {
      commit("SET_PRODUCT", res.data.data);
    });
  },
  async updateSubcategory({}, uuid, data) {
    await Product.updateSubcategory(uuid, data);
  },
  async search({}, { term, type, course }) {
    return await Product.search(term, type, course);
  },
  async searchOfCategories({}, { term: term, categories: categories }) {
    return await Product.searchOfCategories(term, categories);
  },
  async searchOfCategoriesByStatus({}, { term, categories, status }) {
    return await Product.searchOfCategoriesByStatus(term, categories, status);
  },
  async searchCategories({}, { term: term, categories: categories }) {
    return await Product.searchCategories(term, categories);
  },
  async searchOfClassroom({}, term) {
    return await Product.searchOfClassroom(term);
  },
  async searchGrid({}, { term: term }) {
    return await Product.searchGrid(term);
  },
  async storeDiscount({}, data) {
    return await Product.storeDiscount(data);
  },
  async updateDiscount({}, { uuid, data }) {
    return await Product.updateDiscount(uuid, data);
  },
  async categories({}, data) {
    return await Product.categories(data);
  },
  async searchSubcategory({ commit }, { uuid: uuid, term: term }) {
    commit("SET_SEARCHSUBCATEGORY", []);
    await Product.searchSubcategory(uuid, term).then((res) => {
      commit("SET_SEARCHSUBCATEGORY", res);
    });
  },
  async setStoreLoading({ commit }, payload) {
    await commit("SET_LOADING", payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit("SET_LOADING", {
      status: false,
      message: "",
    });
    await commit("SET_ERROR", errorCode);
  },
  async forSelectTypes({}, data) {
    return await Product.forSelectTypes(data);
  },
  async forSelect({}) {
    return await Product.forSelect();
  },
};

const mutations = {
  SET_LOADING(state, { status, message }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_PRODUCTS(state, data) {
    state.products = data;
  },
  SET_PRODUCTSUBCATEGORIES(state, data) {
    state.productSubcategories = data;
  },
  SET_PRODUCTSUBCATEGORY(state, data) {
    state.productSubcategory = data;
  },
  SET_PRODUCT(state, product) {
    state.product = product;
  },
  SET_SEARCHSUBCATEGORY(state, data) {
    state.searchSubcategories = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
