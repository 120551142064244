import CostCenter from '@/_services/Http/cost-center';

const actions = {
  async all({}, filters) {
    return await CostCenter.all(filters)
  },
  async store({}, data) {
    return await CostCenter.store(data)
  },
  async create({}, data) {
    return await CostCenter.store(data)
  },
  async forSelect({}) {
    console.log(2222222)
    return await CostCenter.forSelect();
  },
  async update({}, {uuid, data}) {
    return await CostCenter.update(uuid, data)
  },
  async delete({}, uuid) {
    await CostCenter.delete(uuid);
  },
};

export default {
  namespaced: true,
  actions,
};
