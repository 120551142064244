import Banner from '@/_services/Http/banner';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  banners: [],
  banner: {},
  uuid: '',
  deleted: false,
  searchCategories: [],
});

const getters = {
  error: (state) => state.error,
  StateBanner: (state) => state.banner,
};

const actions = {
  async all({}, data) {
    return await Banner.all(data);
  },
  async sort({}, { category, banners}) {
    return await Banner.sort(category, banners);
  },
  async categories() {
    return await Banner.categories();
  },
  async update({
    commit,
  }, {uuid: uuid, data: data}) {
    await Banner.update(uuid, data)
      .then((res) => {
        commit('SET_BANNER', res.data.data);
      });
  },
  async show({}, uuid) {
    return await Banner.show(uuid);
  },
  async search({
    commit,
  }, name) {
    commit('SET_LOADING', true);
    commit('SET_SEARCH', []);
    await Banner.search(name)
      .then((res) => {
        commit('SET_SEARCH', res);
      });
    commit('SET_LOADING', false);
  },
  async create({
    commit,
  }, data) {
    await Banner.store(data)
      .then((res) => {
        commit('SET_BANNER', res);
      });
  },
  async delete({}, uuid) {
    await Banner.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_BANNERS(state, banners) {
    state.banners = banners;
  },
  SET_SEARCH(state, banners) {
    state.searchCategories = banners;
  },
  SET_BANNER(state, banner) {
    state.banner = banner;
  },
  SET_UUID(state, banner) {
    state.banner_uuid = banner.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
