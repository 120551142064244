import ApiService from '@/_services/Http/axios';

const Gateway = {
  show: {},
  update: {},
};

Gateway.show = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/gateway/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Gateway.update = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/gateway/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Gateway;
