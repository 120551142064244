import Api from '@/_services/Http/company';

const actions = {
  async all({}, filters) {
    return await Api.all(filters)
  },
  async edit({}, uuid) {
    return await Api.edit(uuid)
  },
  async store({}, data) {
    return await Api.store(data)
  },
  async create({}, data) {
    return await Api.store(data)
  },
  async update({}, {uuid, data}) {
    return await Api.update(uuid, data)
  },
  async delete({}, uuid) {
    await Api.delete(uuid);
  },
  async search({}, term) {
    return await Api.search(term);
  },
};

export default {
  namespaced: true,
  actions,
};
