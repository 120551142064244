import ApiService from "@/_services/Http/axios";

const OrderPurchase = {
  all: {},
  allPayments: {},
  allPaymentAnalysis: {},
  allPaymentStatus: {},
  allApproved: {},
  allApprovedPurchases: {},

  toApprove: {},
  toDisapprove: {},

  getFiles: {},

  allSteps: {},

  editApprovedPurchase: {},
  updateApprovedPurchase: {},

  store: {},
  delete: {},
  edit: {},
  search: {},
  forSelectTypes: {},
  forSelectDocumentTypes: {},
  update: {},
  cancelOrder: {},

  updateStep: {},
  forSelectSteps: {},

  forSelectOfSteps: {},

  forSelectPayments: {},
  getPaymentByUuid: {},
  editPayment: {},
  updatePayment: {},
  updatePayments: {},
  updatePaymentInstallment: {},
  createNewPayment: {},
  forSelectInstallmentStatus: {},
  updateInstallmentStatus: {},
  revertInstallmentStatus: {},
  cancelPayment: {},

  uploadFiles: {},
  deleteFile: {},

  allCategories: {},
  storeCategory: {},
  deleteCategory: {},
  forSelectCategories: {},
  updateCategory: {},

  allPlans: {},
  storePlan: {},
  deletePlan: {},
  forSelectPlans: {},
  updatePlan: {},
  forSelectBusinessUnitsByPlanUuid: {},

  allOperations: {},
  storeOperation: {},
  deleteOperation: {},
  forSelectOperations: {},
  updateOperation: {},

  allStatus: {},
  storeStatus: {},
  deleteStatus: {},
  forSelectStatus: {},
  updateStatus: {},

  allReleaseTypes: {},
  storeReleaseTypes: {},
  deleteReleaseTypes: {},
  forSelectReleaseTypes: {},
  updateReleaseTypes: {},

  editBankAccount: {},
  updateBankAccount: {},
  listMovements: {},
  getMovementByUuid: {},
  listPaymentMovements: {},
  getPaymentMovementByUuid: {},
};

OrderPurchase.createNewPayment = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/purchases/payments/store`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.listPaymentMovements = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/movements/payments/list`, {
      ...filters,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.listMovements = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/movements/list`, {
      ...filters,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.getMovementByUuid = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/movements/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.getPaymentMovementByUuid = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/movements/payments/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.all = async function ({
  perPage,
  currentPage,
  company,
  status,
  period,
  step,
  document_number,
  document_type,
  id,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/list?page=${currentPage}&limit=${perPage}&step=${step}&company=${company}&document_number=${document_number}&document_type=${document_type}&id=${id}&status=${status}&period=${period}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.allApproved = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/approved/list?page=${filters.currentPage}&limit=${filters.perPage}&company=${filters.company}&status=${filters.status}&step=${filters.step}&period=${filters.period}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.allApprovedPurchases = async function ({
  perPage,
  currentPage,
  company,
  status,
  period,
  exportData,
  bank_account,
  id,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/approved-purchases/list?page=${currentPage}&limit=${perPage}&company=${company}&exportData=${exportData}&id=${id}&status=${status}&bank_account=${bank_account}&period=${period}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.allPayments = async function (params) {
  try {
    // Filtra apenas os parâmetros definidos (não undefined ou null)
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== undefined && value !== null
      )
    );

    const { data } = await ApiService.get(
      `/v1/admin/orders/purchases/payments/list`,
      {
        params: filteredParams,
      }
    );

    return data;
  } catch (err) {
    throw err;
  }
};

OrderPurchase.allPaymentAnalysis = async function ({
  perPage,
  currentPage,
  company,
  period,
  bank_account,
  status,
  id,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/payments/analysis?page=${currentPage}&limit=${perPage}&company=${company}&bank_account=${bank_account}&id=${id}&period=${period}&status=${status}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.allPaymentStatus = async function ({
  perPage,
  supplier,
  created_at,
  currentPage,
  status,
  bank_account,
  period,
  id,
  exportData,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/payments/status/${status}/list?page=${currentPage}&bank_account=${
        bank_account ?? ""
      }&limit=${perPage}&exportData=${exportData}&supplier=${supplier}&created_at=${created_at}&id=${id}&period=${period}&status=${status}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.getFiles = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/${uuid}/files`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.allSteps = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/steps/list`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.edit = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/${uuid}/edit`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.editPayment = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/payments/${uuid}/edit`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.editApprovedPurchase = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/approved-purchases/${uuid}/edit`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/purchases/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deleteFile = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/file/${uuid}/delete`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.uploadFiles = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/purchases/${uuid}/files`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updatePurchaseOrderStep = async function (
  items,
  step,
  status,
  bank_account
) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/update/items/step`, {
      items: items,
      step: step,
      status: status,
      bank_account,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updatePayment = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/payments/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updatePaymentInstallment = async function (uuid, data) {
  console.log("data", data);
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/orders/purchases/payments/installments/${uuid}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.cancelPayment = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/payments/installments/cancel`, {
      uuid: data.uuid,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateInstallmentStatus = async function (
  items,
  status,
  completed_date
) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/payments/installments/status`, {
      items: items,
      status: status,
      date: completed_date,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.revertInstallmentStatus = async function (items) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/orders/purchases/payments/installments/revert-installment-status`,
      {
        items,
      }
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateApprovedPurchase = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/orders/purchases/approved-purchases/items/update`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateStep = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/steps/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectSteps = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/steps/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectInstallmentStatus = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.post(
      `/v1/admin/orders/purchases/payments/forselect-status`,
      filters
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectPayments = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/payments/forselect-payments?=term${term}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.getPaymentByUuid = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/payments/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectOfSteps = async function (ids, term) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/purchases/forselect-steps?term=${term}`, {
      ids: ids,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deleteMedia = async function (uuid, media_uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/${uuid}/delete/${media_uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// CATEGORIES
OrderPurchase.allCategories = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/categories/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.storeCategory = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/purchases/categories/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateCategory = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/categories/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deleteCategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/categories/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/categories/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

// PLANS
OrderPurchase.allPlans = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/plans/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.storePlan = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/purchases/plans/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updatePlan = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/plans/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectBusinessUnitsByPlanUuid = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/plans/${uuid}/business-units`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deletePlan = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/plans/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectPlans = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/plans/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

// OPERATIONS
OrderPurchase.allOperations = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/operations/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.storeOperation = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/purchases/operations/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateOperation = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/operations/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deleteOperation = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/operations/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectOperations = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/operations/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

// STATUS
OrderPurchase.allStatus = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/status/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.storeStatus = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/purchases/status/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateStatus = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/status/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deleteStatus = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/status/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectStatus = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/status/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

// RELEASE TYPES
OrderPurchase.allReleaseTypes = async function ({
  perPage,
  currentPage,
  name,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/purchases/release-types/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.storeReleaseTypes = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/purchases/release-types/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateReleaseTypes = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/orders/purchases/release-types/${uuid}/update`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.updateBankAccount = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/orders/purchases/release-types/${uuid}/update`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.editBankAccount = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/purchases/${uuid}/bank-account`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.deleteReleaseTypes = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/purchases/release-types/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

OrderPurchase.forSelectReleaseTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/purchases/release-types/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

// TYPES
OrderPurchase.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/types/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

// DOCUMENT TYPES
OrderPurchase.forSelectDocumentTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/documents/types/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////
export default OrderPurchase;
