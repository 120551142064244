import Sort from '@/_services/Http/sort';

const actions = {
  async allProducts({}, {id}) {
    return await Sort.allProducts(id);
  },

  async allContests({}, {id}) {
    return await Sort.allContests(id);
  },

  async allContestsHome({}, {id}) {
    return await Sort.allContestsHome(id);
  },

  async updateProducts({}, {id: id, items: data}) {
    return await Sort.updateProducts(id, data);
  },

  async allNewsHome({}, {id}) {
    return await Sort.allNewsHome(id);
  },

  async updateNewsHome({}, {id: id, items: data}) {
    return await Sort.updateNewsHome(id, data);
  },

  async updateContests({}, {id: id, items: data}) {
    return await Sort.updateContests(id, data);
  },
  async updateAreasBlog({}, data) {
    return await Sort.updateAreasBlog(data);
  },
  async updateContestsHome({}, {id: id, items: data}) {
    return await Sort.updateContestsHome(id, data);
  },
};


export default {
  namespaced: true,
  actions,
};
