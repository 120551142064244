import Indications from "@/_services/Http/indications";

const state = () => ({});

const getters = {};

const actions = {
  async all({}, filters) {
    return await Indications.all(filters);
  },

  async delete({}, uuid) {
    return await Indications.delete(uuid);
  },
  async create({}, data) {
    return await Indications.store(data);
  },

  async update({}, data) {
    return await Indications.update(data);
  },

  async search({}, filters) {
    return await Indications.search(filters);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
