import ApiService from "@/_services/Http/axios";

const Product = {
  allOnline: {},
  allSubcategories: {},
  storeOnline: {},
  editOnline: {},
  deleteOnline: {},
  showOnline: {},
  showSubcategory: {},
  updateOnline: {},
  storeSubcategory: {},
  storeOnline: {},
  deleteSubcategory: {},
  search: {},
  searchGrid: {},
  searchSubcategory: {},
  update: {},
  storeDiscount: {},
  updateDiscount: {},
  categories: {},
  searchOfCategories: {},
  searchOfCategoriesByStatus: {},
  searchCategories: {},
  allCategory: {},
  allDiscounts: {},
  showDiscount: {},
  updateSubcategory: {},
  searchOfClassroom: {},
};

Product.all = async function ({
  title,
  uuid,
  course = 0,
  perPage: limit,
  currentPage: page,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/${uuid}/list`, {
      limit,
      page,
      title,
      course,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.allDiscounts = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/discounts?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.categories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/categories`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.allOnline = async function ({
  perPage: limit,
  currentPage: page,
  uuid,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/products/${uuid}/list?page=${page}&limit=${limit}`
    )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.allCategory = async function (categoryId) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/${categoryId}/category`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.allSubcategories = async function ({
  perPage: limit,
  currentPage: page,
  uuid,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/products/subcategories/${uuid}/list?page=${page}&limit=${limit}`
    )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.storeOnline = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/products/online", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/products/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.storeDiscount = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/products/discounts/store", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.updateDiscount = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/products/discounts/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.storeSubcategory = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/products/subcategories/store/${uuid}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.updateOnline = async function ({ uuid, data } = data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/products/online/${uuid}`, data)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.updateSubcategory = async function ({ uuid, data } = data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/products/subcategories/${uuid}`, data)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.showOnline = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/online/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.showDiscount = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/discounts/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.showSubcategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/subcategories/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.deleteOnline = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/products/online/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.deleteSubcategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/products/subcategories/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.search = async function (term, type, course = 0) {
  return new Promise((resolve, reject) => {
    let route = 0;
    switch (type) {
      case 1:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_PRESENCIAL_UUID;
        break;
      case 2:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_ONLINE_UUID;
        break;
      case 3:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_LIVE_UUID;
        break;
      case 4:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_FAST_UUID;
        break;
      case 5:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_FREE_UUID;
        break;
      case 6:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_BOOK_UUID;
        break;
      case 7:
        route = process.env.VUE_APP_PRODUCT_CATEGORY_PASSPORT_UUID;
        break;
      default:
        break;
    }

    ApiService.get(
      `/v1/admin/products/search/${route}/category?term=${term}&course=${course}`
    )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.searchOfCategories = async function (term, categories) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/products/search/of/categories?term=${term}&categories=${categories}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.searchOfCategoriesByStatus = async function (term, categories, status) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/products/search/of/categories?term=${term}&categories=${categories}&status=${status}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.searchCategories = async function (term, categories) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/products/search/categories?term=${term}&categories=${categories}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.searchOfClassroom = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/search/classrooms?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.searchGrid = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/search/grid?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.searchSubcategory = async function (uuid, term) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/products/search/${uuid}/subcategories?term=${term}`
    )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.update = async function ({ uuid, data }) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/products/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/for-select-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Product.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/products/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Product;
