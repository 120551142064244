import Classroom from "@/_services/Http/classroom";

const state = () => ({});

const getters = {};

const actions = {
  async all({}, data) {
    return await Classroom.all(data);
  },
  async allOfProduct({}, uuid) {
    return await Classroom.allOfProduct(uuid);
  },
  async show({}, uuid) {
    return await Classroom.show(uuid);
  },
  async create({}, data) {
    return await Classroom.store(data);
  },
  async installments({}, uuid) {
    return await Classroom.installments(uuid);
  },
  async update({}, { uuid, data }) {
    return await Classroom.update({ uuid, data });
  },
  async search({}, term) {
    return await Classroom.search(term);
  },
  async delete({}, { uuid }) {
    await Classroom.delete(uuid);
  },
  async forSelectTypes({}, data) {
    return await Classroom.forSelectTypes(data);
  },
  async forSelect({}, data) {
    return await Classroom.forSelect(data);
  },
  async searchNoGrid({}, term) {
    return await Classroom.searchNoGrid(term);
  },
  async forSelectDays({}, data) {
    return await Classroom.forSforSelectDayselect(data);
  },
  async transfer({}, { uuid, data }) {
    return await Classroom.transfer(uuid, data);
  },
  async weekdays({}, data) {
    return await Classroom.weekdays(data);
  },
  async forSelectByProduct({}, uuid) {
    return await Classroom.forSelectByProduct(uuid);
  },
  async forSelectParams({}, data) {
    const term = data.term;
    const filters = {
      modality: data.modality?.code ?? "",
      unit: data.unit?.code ?? "",
      status: data.status ?? "",
      disciplines: data.disciplines ?? "",
    };
    return await Classroom.forSelectParams(term, filters);
  },
  async forSelectDisciplinesByParams({}, data) {
    const term = data.term;

    return await Classroom.forSelectDisciplinesByParams(term);
  },
  async getAllClassroomsStart({}, filters) {
    return await Classroom.getAllClassroomsStart(filters);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
