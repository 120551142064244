import ApiService from "@/_services/Http/axios";

const Opportunity = {
  steps: {},
  fetchSteps: {},
  updateSteps: {},
  deleteStep: {},
  fetchModalities: {},
  storeTeam: {},
  updateTeam: {},
  deleteTeam: {},
};

Opportunity.deleteTeam = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/opportunities/team/${uuid}/delete`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Opportunity.storeTeam = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(
      `/v1/admin/opportunities/team/store`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Opportunity.updateTeam = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/opportunities/team/${uuid}/update`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Opportunity.fetchSteps = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/opportunities/steps/for-select`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Opportunity.fetchModalities = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/opportunities/modalities/for-select`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Opportunity.updateSteps = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/opportunities/steps/update`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

Opportunity.deleteStep = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(
      `/v1/admin/opportunities/steps/${uuid}/delete`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default Opportunity