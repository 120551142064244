import ApiService from '@/_services/Http/axios';

const Coupon = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
};

Coupon.all = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/coupons/list?page=${filters.currentPage}&limit=${filters.perPage}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Coupon.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/coupons/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Coupon.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/coupons/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Coupon.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/coupons/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Coupon.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/coupons/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Coupon.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/coupons/search/?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Coupon;
