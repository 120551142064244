import Media from '@/_services/Http/media';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  medias: [],
  materials: [],
  proofs: [],
  notices: [],
  media: {},
  media_uuid: '',
  deleted: false,
  searchMedias: [],
  searchMaterials: [],
  searchNotices: [],
  searchTests: [],
  searchNoticeLast: [],
  searchProofs: [],
});

const getters = {
  error: (state) => state.error,
  StateMedia: (state) => state.media,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Media.all(data)
      .then((res) => {
        commit('SET_MEDIAS', res);
      });
  },
  async materials({
    commit,
  }, data) {
    await Media.materials(data)
      .then((res) => {
        commit('SET_MATERIALS', res);
      });
  },
  async allType({
    commit,
  }, data) {
    await Media.allType(data)
      .then((res) => {
        switch (data.filters.type) {
          case 1:
            commit('SET_MATERIALS', res);
            break;
          case 4:
            commit('SET_NOTICES', res);
            break;
          case 5: 
            commit('SET_PROOFS', res);
            break;
          default:
            break;
        }
      });
  },
  async update({
    commit,
  }, {uuid: uuid, data: data}) {
    await Media.update(uuid, data)
      .then((res) => {
        commit('SET_MEDIA', res.data.data);
      });
  },
  async show({}, uuid) {
    return await Media.show(uuid)
  },
  async search({
    commit,
  }, name) {
    commit('SET_LOADING', true);
    commit('SET_SEARCH', []);
    await Media.search(name)
      .then((res) => {
        commit('SET_SEARCH', res);
      });
    commit('SET_LOADING', false);
  },
  async searchType({
    commit,
  }, {term: term, type: type}) {
    await Media.searchType(term, type)
      .then((res) => {
        switch(type) {
          case 1:
            commit('SET_SEARCHMATERIALS', res);
          break;
          case 4:
            commit('SET_SEARCHNOTICES', res);
          break;
          case 5:
            commit('SET_SEARCHPROOFS', res);
          break;
          default: 
          break;
        }
      });
  },
  async create({
    commit,
  }, data) {
    await Media.store(data)
      .then((res) => {
        commit('SET_MEDIA', res);
      });
  },
  async delete({}, data) {
    await Media.delete(data.uuid);
  },
  async deleteFile({}, data) {
    await Media.deleteFile(data.uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_MEDIAS(state, medias) {
    state.medias = medias;
  },
  SET_MATERIALS(state, data) {
    state.material= [];
    state.materials = data;
  },
  SET_NOTICES(state, data) {
    state.notices = [];
    state.notices = data;
  },
  SET_PROOFS(state, data) {
    state.proofs = [];
    state.proofs = data;
  },
  SET_SEARCH(state, medias) {
    state.searchMedias = medias;
  },
  SET_SEARCHMATERIALS(state, data) {
    state.searchMaterials = [];
    state.searchMaterials = data;
  },
  SET_SEARCHNOTICES(state, data) {
    state.searchNotices = [];
    state.searchNotices = data;
  },
  SET_SEARCHPROOFS(state, data) {
    state.searchProofs = [];
    state.searchProofs = data;
  },
  SET_MEDIA(state, media) {
    state.media = media;
  },
  SET_UUID(state, media) {
    state.media_uuid = media.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
