export default [
  // COMPANIES
  {
    path: "/companies/list",
    name: "companies-list",
    component: () => import("@/views/finance/company/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Empresa - Listar",
      pageTitle: "Empresas",
      btnCreateRouteName: "companies-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Empresas",
          active: true,
        },
      ],
    },
  },
  {
    path: "/companies/create",
    name: "companies-create",
    component: () => import("@/views/finance/company/Create.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Empresa - Cadastrar",
      pageTitle: "Status",
      btnBack: "companies-list",
      btnCreateRouteName: "companies-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Empresas",
          to: { name: "companies-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/companies/:uuid/edit",
    name: "companies-edit",
    component: () => import("@/views/finance/company/Edit.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Empresa - Editar",
      pageTitle: "Status",
      btnBack: "companies-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Empresas",
          to: { name: "companies-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/approved-purchases/list",
    name: "orders-purchases-approved-purchases-list",
    component: () =>
      import("@/views/finance/purchase/approved-purchase/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Aprovado - Listar",
      pageTitle: "Compras aprovadas",
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Aprovados",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/list",
    name: "orders-purchases-payments-list",
    component: () => import("@/views/finance/purchase/payment/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pagamento - Listar",
      pageTitle: "Pagamentos",
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Pagamentos",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/create",
    name: "orders-purchases-payments-create",
    component: () => import("@/views/finance/purchase/payment/Create.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pagamento - Criar",
      pageTitle: "Pagamentos - Criar",
      btnBack: "orders-purchases-payments-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Pagamentos",
          to: { name: "orders-purchases-payments-list" },
        },
        {
          text: "Criar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/:uuid/edit/:payment_uuid",
    name: "orders-purchases-payments-edit",
    component: () => import("@/views/finance/purchase/payment/Edit.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pagamento - Editar",
      pageTitle: "Pagamentos",
      btnBack: "orders-purchases-payments-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Pagamentos",
          to: { name: "orders-purchases-payments-list" },
        },
        {
          text: "Editar",
        },
      ],
    },
  },

  {
    path: "/orders/purchases/:uuid/print",
    name: "orders-purchases-print",
    component: () => import("@/views/finance/purchase/Print.vue"),
    meta: {
      layout: "full",
      resource: "Financeiro - ADM",
      action: "Lançado - Visualizar",
      pageTitle: "Pedido de compra",
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Pedido de compra",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/analysis/list",
    name: "orders-purchases-analysis-list",
    component: () => import("@/views/finance/purchase/analysis/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Em Análise - Listar",
      pageTitle: "Análise",
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Pagamentos",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/write-off-payments/list",
    name: "orders-purchases-write-off-payment-list",
    component: () =>
      import("@/views/finance/purchase/write-off-payment/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Dar Baixa - Listar",
      pageTitle: "Pagamentos",
      btnOnlyExport: true,
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Pagamentos",
          to: { name: "orders-purchases-payments-list" },
        },
        {
          text: "Dar baixa",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/romaneio/list",
    name: "orders-purchases-romaneio-list",
    component: () => import("@/views/finance/purchase/romaneio/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Romaneio - Listar",
      pageTitle: "Pagamentos",
      btnOnlyExport: false,
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Romaneio",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/romaneio/:uuid",
    name: "orders-purchases-romaneio-view",
    component: () => import("@/views/finance/purchase/romaneio/View.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Romaneio - Visualizar",
      pageTitle: "Pagamentos",
      btnOnlyExport: false,
      btnBack: "orders-purchases-romaneio-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Romaneio",
          to: { name: "orders-purchases-romaneio-list" },
        },
      ],
    },
  },

  // Romaneio Pagamentos
  {
    path: "/orders/purchases/payments/romaneio-payments/list",
    name: "orders-purchases-romaneio-payments-list",
    component: () =>
      import("@/views/finance/purchase/romaneio_payments/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Romaneio Pagamentos - Listar",
      pageTitle: "Pagamentos",
      btnOnlyExport: false,
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Romaneio Pagamentos",
        },
      ],
    },
  },
  {
    path: "/orders/purchases/payments/romaneio-payments/:uuid",
    name: "orders-purchases-romaneio-payments-view",
    component: () =>
      import("@/views/finance/purchase/romaneio_payments/View.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Romaneio Pagamentos - Visualizar",
      pageTitle: "Pagamentos",
      btnOnlyExport: false,
      btnBack: "orders-purchases-romaneio-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Romaneio",
          to: { name: "orders-purchases-romaneio-payments-list" },
        },
      ],
    },
  },
  {
    path: "/orders/purchases/approved/list",
    name: "orders-purchases-approved-list",
    component: () => import("@/views/finance/purchase/approved/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Para Aprovação - Listar",
      pageTitle: "Aprovação",
      btnBack: "orders-purchases-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-approved",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Para Aprovação",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/bank-accounts/list",
    name: "orders-purchases-bank-account-list",
    component: () => import("@/views/finance/bank-account/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Conta Bancária - Listar",
      pageTitle: "Contas bancárias",
      btnBack: "orders-purchases-list",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-bank-accounts",
        permission: {
          action: "Conta Bancária - Cadastrar",
          resource: "Financeiro - ADM",
        },
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Contas bancárias",
          to: { name: "orders-purchases-list" },
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/bank-accounts/types/list",
    name: "orders-purchases-bank-accounts-types-list",
    component: () => import("@/views/finance/bank-account/type/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Conta Bancária Tipo - Listar",
      pageTitle: "Tipos",
      btnBack: "orders-purchases-bank-account-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-bank-account-types",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Contas bancárias",
          to: { name: "orders-purchases-bank-account-list" },
        },
        {
          text: "Tipos",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/bank-accounts/categories/list",
    name: "orders-purchases-bank-accounts-categories-list",
    component: () => import("@/views/finance/bank-account/category/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Conta Bancária Categoria - Listar",
      pageTitle: "Categorias",
      btnBack: "orders-purchases-bank-account-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-bank-account-categories",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Contas bancárias",
          to: { name: "orders-purchases-bank-account-list" },
        },
        {
          text: "Categorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/categories/list",
    name: "orders-purchases-categories-list",
    component: () => import("@/views/finance/purchase/category/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pedido de Compra - Categoria - Listar",
      pageTitle: "Categorias",
      btnBack: "orders-purchases-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-purchase-subcategory",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Categorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/plans/list",
    name: "orders-purchases-plans-list",
    component: () => import("@/views/finance/purchase/plan/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pedido de Compra - Histórico PC - Listar",
      pageTitle: "Histórico PC",
      btnBack: "orders-purchases-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-purchase-plan",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Histórico Pc",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/operations/list",
    name: "orders-purchases-operations-list",
    component: () => import("@/views/finance/purchase/operation/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pedido de Compra - Operação - Listar",
      pageTitle: "Operações",
      btnBack: "orders-purchases-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-purchase-operation",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Operações",
          active: true,
        },
      ],
    },
  },
  ////////////
  // COST CENTER
  {
    path: "/cost-centers/list",
    name: "orders-purchases-cost-center-list",
    component: () => import("@/views/finance/purchase/cost-center/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Pedido de Compra - Centro de Custo - Listar",
      pageTitle: "Categorias",
      btnBack: "orders-purchases-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-purchase-cost-center",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedido de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Centro de custo",
          active: true,
        },
      ],
    },
  },
  ///////////
  // PURCHASE ORDER
  {
    path: "/orders/purchases/list",
    name: "orders-purchases-list",
    component: () => import("@/views/finance/purchase/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Lançado - Listar",
      optionsPurchase: [
        {
          permission: {
            resource: "Financeiro - ADM",
            action: "Pedido de Compra - Categoria - Listar",
          },
          route: "orders-purchases-categories-list",
          text: "Categorias",
          variant: "warning",
          icon: "",
        },
        {
          permission: {
            resource: "Financeiro - ADM",
            action: "Pedido de Compra - Operação - Listar",
          },
          route: "orders-purchases-operations-list",
          text: "Operações",
          variant: "warning",
        },
        {
          permission: {
            resource: "Financeiro - ADM",
            action: "Etapa - Listar",
          },
          route: "orders-purchases-steps-list",
          text: "Etapas",
          variant: "warning",
        },
        {
          permission: {
            resource: "Financeiro - ADM",
            action: "Pedido de Compra - Centro de Custo - Listar",
          },
          route: "orders-purchases-cost-center-list",
          text: "Centro de custo",
          variant: "warning",
        },
        {
          permission: {
            resource: "Financeiro - ADM",
            action: "Pedido de Compra - Histórico PC - Listar",
          },
          route: "orders-purchases-plans-list",
          text: "Histórico PC",
          variant: "warning",
        },
      ],
      btnCreateRouteName: "orders-purchases-create",
      pageTitle: "Pedidos de compra",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/create/:uuid?",
    name: "orders-purchases-create",
    component: () => import("@/views/finance/purchase/Create.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Lançado - Cadastrar",
      btnBack: "orders-purchases-list",
      pageTitle: "Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/:uuid/edit",
    name: "orders-purchases-edit",
    component: () => import("@/views/finance/purchase/Edit.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Lançado - Editar",
      btnBack: "orders-purchases-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/status/list",
    name: "orders-purchases-status-list",
    component: () => import("@/views/finance/purchase/status/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Status",
      pageTitle: "Status",
      btnBack: "orders-purchases-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-order-purchase-status",
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Status",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/steps/list",
    name: "orders-purchases-steps-list",
    component: () => import("@/views/finance/purchase/steps/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Etapa - Listar",
      pageTitle: "Etapas",
      btnBack: "orders-purchases-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Pedidos de compra",
          to: { name: "orders-purchases-list" },
        },
        {
          text: "Etapas",
          active: true,
        },
      ],
    },
  },
  // SUPPLIERS
  {
    path: "/suppliers/categories/list",
    name: "suppliers-categories-list",
    component: () => import("@/views/finance/supplier/category/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Fornecedor - Categoria",
      pageTitle: "Categorias",
      btnBack: "suppliers-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-supplier-category",
        permission: {
          action: "Fornecedor - Cadastrar",
          resource: "Financeiro - ADM",
        },
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Fornecedores",
          to: { name: "suppliers-list" },
        },
        {
          text: "Categorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/suppliers/list",
    name: "suppliers-list",
    component: () => import("@/views/finance/supplier/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Fornecedor - Listar",
      btnSubcategory: {
        route: "suppliers-categories-list",
        text: "Categorias",
        variant: "warning",
        permission: {
          action: "Fornecedor - Categoria",
          resource: "Financeiro - ADM",
        },
      },
      pageTitle: "Fornecedores",
      btnCreateRouteName: "suppliers-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Fornecedores",
          active: true,
        },
      ],
    },
  },
  {
    path: "/suppliers/create",
    name: "suppliers-create",
    component: () => import("@/views/finance/supplier/Create.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Fornecedor - Cadastrar",
      pageTitle: "Cadastrar",
      btnBack: "suppliers-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Fornecedores",
          to: { name: "suppliers-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/suppliers/:uuid/edit",
    name: "suppliers-edit",
    component: () => import("@/views/finance/supplier/Edit.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Fornecedor - Editar",
      pageTitle: "Fornecedor",
      btnBack: "suppliers-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Fornecedores",
          to: { name: "suppliers-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  ///////////

  // BUSINESS UNIT
  {
    path: "/business-units/categories/list",
    name: "business-units-categories-list",
    component: () => import("@/views/finance/business-unit/category/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade de Negócio - Categoria",
      pageTitle: "Categorias",
      btnBack: "business-units-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-business-unit-category",
        permission: {
          action: "Unidade de Negócio - Categoria",
          resource: "Financeiro - ADM",
        },
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidades de negócio",
          to: { name: "business-units-list" },
        },
        {
          text: "Categorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business-units/list",
    name: "business-units-list",
    component: () => import("@/views/finance/business-unit/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade de Negócio - Listar",
      btnSubcategory: {
        route: "business-units-categories-list",
        text: "Categorias",
        variant: "warning",
        permission: {
          action: "Unidade de Negócio - Cadastrar",
          resource: "Financeiro - ADM",
        },
      },
      pageTitle: "Unidades de negócio",
      btnCreateRouteName: "business-units-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidades de negócio",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business-units/create",
    name: "business-units-create",
    component: () => import("@/views/finance/business-unit/Create.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade de Negócio - Cadastrar",
      pageTitle: "Cadastrar",
      btnBack: "business-units-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidades de negócio",
          to: { name: "business-units-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business-units/:uuid/edit",
    name: "business-units-edit",
    component: () => import("@/views/finance/business-unit/Edit.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade de Negócio - Editar",
      pageTitle: "Fornecedor",
      btnBack: "business-units-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidade de negócio",
          to: { name: "business-units-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  ///////////

  // STRATEGIC UNIT
  {
    path: "/strategic-units/categories/list",
    name: "strategic-units-categories-list",
    component: () => import("@/views/finance/strategic-unit/category/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade Estratégica - Categoria",
      pageTitle: "Categorias",
      btnBack: "strategic-units-list",
      btnCreate: true,
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-strategic-unit-category",
        permission: {
          action: "Unidade Estratégica - Cadastrar",
          resource: "Financeiro - ADM",
        },
      },
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidade estratégica",
          to: { name: "strategic-units-list" },
        },
        {
          text: "Categorias",
          active: true,
        },
      ],
    },
  },
  {
    path: "/strategic-units/list",
    name: "strategic-units-list",
    component: () => import("@/views/finance/strategic-unit/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade Estratégica - Listar",
      btnSubcategory: {
        route: "strategic-units-categories-list",
        text: "Categorias",
        variant: "warning",
        permission: {
          action: "Unidade Estratégica - Categoria",
          resource: "Financeiro - ADM",
        },
      },
      pageTitle: "Unidade estratégica",
      btnCreateRouteName: "strategic-units-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidade estratégica",
          active: true,
        },
      ],
    },
  },
  {
    path: "/strategic-units/create",
    name: "strategic-units-create",
    component: () => import("@/views/finance/strategic-unit/Create.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade Estratégica - Cadastrar",
      pageTitle: "Cadastrar",
      btnBack: "strategic-units-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidade estratégica",
          to: { name: "strategic-units-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/strategic-units/:uuid/edit",
    name: "strategic-units-edit",
    component: () => import("@/views/finance/strategic-unit/Edit.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Unidade Estratégica - Editar",
      pageTitle: "Fornecedor",
      btnBack: "strategic-units-list",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Unidade estratégica",
          to: { name: "strategic-units-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/vindi/list",
    name: "orders-purchases-vindi-list",
    component: () => import("@/views/finance/vindi/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Vindi - Listar",
      btnCreate: false,
      btnExport: false,
      pageTitle: "Integração Vindi ",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Integração Vindi",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/orders/purchases/cancellations/list",
    name: "orders-purchases-cancellations-list",
    component: () =>
      import("@/views/finance/transaction/cancel-registration/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Cancelamento Solicitado - Listar",
      cancel_action: "Cancelamento Solicitado - Marcar como Conferido",
      pageTitle: "Cancelamento Solicitado",
      step_id: 1,
      buttonText: "Marcar como Conferido",
    },
  },
  {
    path: "/orders/purchases/cancellations/confirmed-list",
    name: "orders-purchases-cancellations-confirmed-list",
    component: () =>
      import("@/views/finance/transaction/cancel-registration/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Cancelamento Conferido - Listar",
      cancel_action: "Cancelamento Conferido - Marcar como Aprovado",
      pageTitle: "Cancelamento Conferido",
      step_id: 2,
      buttonText: "Marcar como Aprovado",
    },
  },
  {
    path: "/orders/purchases/cancellations/approved-list",
    name: "orders-purchases-cancellations-approved-list",
    component: () =>
      import("@/views/finance/transaction/cancel-registration/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Cancelamento Aprovado - Listar",
      cancel_action: "Cancelamento Aprovado - Marcar como Efetivado",
      pageTitle: "Cancelamento Aprovado",
      step_id: 3,
      buttonText: "Marcar como Efetivado",
    },
  },
  {
    path: "/orders/purchases/cancellations/effectived-list",
    name: "orders-purchases-cancellations-effectived-list",
    component: () =>
      import("@/views/finance/transaction/cancel-registration/List.vue"),
    meta: {
      resource: "Financeiro - ADM",
      action: "Cancelamento Efetivado - Listar",
      pageTitle: "Cancelamento Efetivado",
      step_id: 4,
    },
  },
];
