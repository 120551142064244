import StrategicUnit from '@/_services/Http/strategic-unit';

const actions = {
  async all({}, filters) {
    return await StrategicUnit.all(filters)
  },
  async allCategories({}, filters) {
    return await StrategicUnit.allCategories(filters)
  },
  async show({}, uuid) {
    return await StrategicUnit.show(uuid)
  },
  async store({}, data) {
    return await StrategicUnit.store(data)
  },
  async storeCategory({}, data) {
    return await StrategicUnit.storeCategory(data)
  },  
  async create({}, data) {
    return await StrategicUnit.store(data)
  },
  async forSelect({}, term) {
    return await StrategicUnit.forSelect(term);
  },
  async forSelectCategories({}) {
    return await StrategicUnit.forSelectCategories();
  },
  async forSelectTypes({}) {
    return await StrategicUnit.forSelectTypes();
  },
  async update({}, {uuid, data}) {
    return await StrategicUnit.update(uuid, data)
  },
  async updateCategory({}, {uuid, data}) {
    return await StrategicUnit.updateCategory(uuid, data)
  },
  async delete({}, uuid) {
    await StrategicUnit.delete(uuid);
  },
  async deleteCategory({}, uuid) {
    await StrategicUnit.deleteCategory(uuid);
  },
  async deleteMedia({}, { uuid, media_uuid}) {
    await StrategicUnit.deleteMedia(uuid, media_uuid);
  },  
};

export default {
  namespaced: true,
  actions,
};
