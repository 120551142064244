import ApiService from "@/_services/Http/axios";

const Classroom = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  weekdays: {},
  forSelect: {},
  forSelectDays: {},
  forSelectTypes: {},
  installments: {},
  allOfProduct: {},
  forSelectParams: {},
  forSelectDisciplinesByParams: {},
  getAllClassroomsStart: {},
  searchNoGrid: {},
  forSelectByProduct: {},
  transfer: {},
};

Classroom.getAllClassroomsStart = async function (filters) {
  filters.currentPage = filters.currentPage;
  filters.perPage = filters.perPage;
  filters.course = filters.course ? filters.course.code : "";
  filters.modality = filters.modality ? filters.modality.code : "";
  filters.shift = filters.shift ? filters.shift.code : "";
  filters.unit = filters.unit ? filters.unit.code : "";
  filters.status = filters.status ? filters.status.code : "";
  filters.name = filters.name ?? "";
  filters.rangeDate = filters.rangeDate ?? "";
  filters.confirmed = filters.confirmed ?? "";
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/classrooms/start?page=${filters.currentPage}&limit=${filters.perPage}&name=${filters.name}&course=${filters.course}&modality=${filters.modality}&shift=${filters.shift}&unit=${filters.unit}&status=${filters.status}&rangeDate=${filters.rangeDate}&confirmed=${filters.confirmed}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.all = async function (filters) {
  filters = {
    page: filters.page,
    limit: filters.limit,
    course: filters.course ? filters.course.code : "",
    display_panel: filters.display_panel ? filters.display_panel.code : "",
    course_sale: filters.course_sale ? filters.course_sale.code : "",
    modality: filters.modality ? filters.modality.code : "",
    shift: filters.shift ? filters.shift.code : "",
    unit: filters.unit ? filters.unit.code : "",
    status: filters.status ? filters.status.code : "",
    name: filters.name ?? "",
    rangeDate: filters.rangeDate ?? "",
    id: filters.id ?? "",
  };

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/classrooms/list?page=${filters.page}&rangeDate=${filters.rangeDate}&limit=${filters.limit}&display_panel=${filters.display_panel}&name=${filters.name}&course=${filters.course}&course_sale=${filters.course_sale}&modality=${filters.modality}&shift=${filters.shift}&unit=${filters.unit}&status=${filters.status}&id=${filters.id}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.allOfProduct = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/${uuid}/product`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.installments = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/${uuid}/installments`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/classrooms/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.update = async function ({ uuid, data }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/classrooms/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/classrooms/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.searchNoGrid = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/search/no-grid?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.forSelectTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/for-select-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.forSelectByProduct = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/product/${uuid}/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.weekdays = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/weekdays`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.forSelectDays = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/classrooms/for-select-days`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.forSelectParams = async function (term, filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/classrooms/for-select-params/?term=${term}&disciplines=${filters.disciplines}&unit=${filters.unit}&modality=${filters.modality}&status=${filters.status}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.forSelectDisciplinesByParams = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/classrooms/for-select-disciplines-params/?term=${term}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Classroom.transfer = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/classrooms/${uuid}/transfer`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Classroom;
