import ApiService from '@/_services/Http/axios'

const Unit = {
  all: {},
  store: {},
  edit: {},
  delete: {},
  show: {},
  update: {},
  search: {},
}

Unit.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve) => {
    ApiService.get(`/v1/admin/units/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data.data);
      })
  })
}

Unit.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/units/store', data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

Unit.update = async function ({ uuid, data } = data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/units/${uuid}/update`, data)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

Unit.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/units/search?term=${term}`)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

Unit.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/units/${uuid}/show`)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

Unit.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/units/${uuid}/delete`)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

Unit.forSelect = async function (status) {
  return new Promise((resolve) => {
    ApiService.get(`/v1/admin/units/for-select?status=${status}`)
      .then((res) => {
        resolve(res.data);
      })
  })
}

export default Unit
