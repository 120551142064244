import State from '@/_services/Http/state';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  states: [],
  stat: {},
  stat_uuid: '',
  deleted: false,
  statesSelect: [],
  searchStates: [],
});

const getters = {
  error: (state) => state.error,
  StateState: (state) => state.stat,
};

const actions = {
  async all({
    commit,
  }, data) {
    await State.all(data)
      .then((res) => {
        commit('SET_STATES', res);
      });
  },
  async allToSelect({
    commit,
  }) {
    await State.all()
      .then((res) => {
        commit('SET_STATES', res);
      });
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCHSTATES', []);
    await State.search(term)
      .then((res) => {
        commit('SET_SEARCHSTATES', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await State.show(uuid)
      .then((res) => {
        commit('SET_STATE', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await State.store(data)
      .then((res) => {
        commit('SET_STATE', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await State.update({uuid, data})
      .then((res) => {
        commit('SET_STATE', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async delete({}, uuid) {
    await State.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_STATES(state, states) {
    state.states = states;
  },
  SET_SEARCHSTATES(state, states) {
    state.searchStates = states;
  },
  SET_SELECT(state, states) {
    state.statesSelect = states;
  },
  SET_STATE(state, stat) {
    state.stat = stat;
  },
  SET_UUID(state, stat) {
    state.stat_uuid = stat.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
