import Discipline from '@/_services/Http/discipline';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: false,
  disciplines: [],
  discipline: {},
  discipline_uuid: '',
  deleted: false,
  searchDisciplines: [],
});

const getters = {
  error: (state) => state.error,
  StateDiscipline: (state) => state.discipline,
};

const actions = {
  async all({}, data) {
    return await Discipline.all(data)
  },
  async create({
    commit,
  }, data) {
    await Discipline.store(data)
      .then((res) => {
        commit('SET_DISCIPLINE', res.data);
      });
  },
  async show({}, uuid) {
    return await Discipline.show(uuid)
  },
  async search({}, { term, ead }) {
    return await Discipline.search(term, ead)
  },
  async delete({}, uuid) {
    await Discipline.delete(uuid);
  },
  async update({
    commit,
  }, uuid, data) {
    await Discipline.update(uuid, data)
      .then((res) => {
        commit('SET_DISCIPLINE', res.data.data);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_DISCIPLINES(state, disciplines) {
    state.disciplines = disciplines;
  },
  SET_SEARCH(state, data) {
    state.searchDisciplines = data;
  },
  SET_DISCIPLINE(state, discipline) {
    state.discipline = discipline;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
  SET_UUID(state, uuid) {
    state.discipline_uuid = uuid;
  },
  SET_FIND(state, find) {
    state.findsDisciplines = find;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
