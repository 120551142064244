// Config
export default [
  {
    path: "/config/edit",
    name: "config-edit",
    component: () => import("@/views/config/Config.vue"),
    meta: {
      resource: "Sistema",
      action: "Informação",
      pageTitle: "Configurações",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Informações",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/crm",
    name: "config-crm",
    component: () => import("@/views/config/crm/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "Informação",
      pageTitle: "Configurações - CRM",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "CRM",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/octadesk",
    name: "config-octadesk",
    component: () => import("@/views/config/octadesk/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "Informação",
      pageTitle: "Configurações - Octadesk",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Octadesk",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/bling",
    name: "config-bling",
    component: () => import("@/views/config/bling/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "Informação",
      pageTitle: "Configurações - Bling",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Bling",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/opens",
    name: "config-opens",
    component: () => import("@/views/config/opens/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "Informação",
      pageTitle: "Configurações - Opens",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Opens",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/units",
    name: "config-unit-list",
    component: () => import("@/views/config/unit/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Unidade",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-unit",
      },
      pageTitle: "Unidades",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Unidades",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/managers-discount",
    name: "config-managers-discount-list",
    component: () => import("@/views/config/manager-discount/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Gestor de Desconto",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-manager-ticket-discount",
      },
      pageTitle: "Gestor de desconto",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Gestores de desconto",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/salesforce",
    name: "config-salesforce-list",
    component: () => import("@/views/config/salesforce/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Sales Force",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-salesforce",
      },
      pageTitle: "Sales Force",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Sales Force",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/partners",
    name: "config-partners-list",
    component: () => import("@/views/config/partner/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Parceiro",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-partner",
      },
      btnExport: false,
      pageTitle: "Parceiros",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Parceiros",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/contracts",
    name: "config-contracts-list",
    component: () => import("@/views/config/contract/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Parceiro",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-contract",
      },
      btnExport: false,
      pageTitle: "Contratos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Contratos",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/about",
    name: "config-about-edit",
    component: () => import("@/views/config/about/Edit.vue"),
    meta: {
      resource: "Sistema",
      action: "Sobre",
      btnCreate: false,
      btnCreateElementName: null,
      btnExport: false,
      pageTitle: "Sobre",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Sobre",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/privacy-policy",
    name: "config-privacy-policy-index",
    component: () => import("@/views/config/privacy-policy/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "Política de Privacidade",
      btnCreate: false,
      btnCreateElementName: null,
      btnExport: false,
      pageTitle: "Política de Privacidade",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Política de Privacidade",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/permissions",
    name: "config-permissions-list",
    component: () => import("@/views/config/permission/List.vue"),
    meta: {
      btnCreateRouteName: "config-permissions-create",
      resource: "Sistema",
      action: "Permissão",
      pageTitle: "Permissões",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Permissões",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/permissions/create",
    name: "config-permissions-create",
    component: () => import("@/views/config/permission/Create.vue"),
    meta: {
      btnBack: "config-permissions-list",
      resource: "Sistema",
      action: "Permissão",
      pageTitle: "Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Permissões",
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/permissions/:uuid/edit",
    name: "config-permissions-edit",
    component: () => import("@/views/config/permission/Edit.vue"),
    meta: {
      btnBack: "config-permissions-list",
      resource: "Sistema",
      action: "Permissão",
      pageTitle: "Edit",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Permissões",
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/users",
    name: "config-users-list",
    component: () => import("@/views/config/user/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Usuário",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-user",
      },
      pageTitle: "Usuários",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Usuários",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/users/:uuid/edit",
    name: "config-users-edit",
    component: () => import("@/views/config/user/Edit.vue"),
    meta: {
      resource: "Sistema",
      action: "Usuário",
      pageTitle: "Usuário",
      contentClass: "ecommerce-application",
      btnBack: "config-users-list",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Usuários",
          to: { name: "config-users-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/tags",
    name: "config-tags-list",
    component: () => import("@/views/config/tag/List.vue"),
    meta: {
      resource: "Sistema",
      action: "Tag",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-tag",
      },
      pageTitle: "Tags",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Tags",
          to: { name: "tags" },
        },
        {
          text: "Listar tags",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/email",
    name: "config-email",
    component: () => import("@/views/config/email/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "E-mail",
      pageTitle: "E-mail",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "E-mail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/config/faq",
    name: "config-faq-index",
    component: () => import("@/views/config/faq/Index.vue"),
    meta: {
      resource: "Sistema",
      action: "Faq",
      pageTitle: "Faq",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Sistema",
        },
        {
          text: "Configurações",
        },
        {
          text: "Faq",
          active: true,
        },
      ],
    },
  },
];
