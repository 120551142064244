import Warning from "@/_services/Http/warning";

const state = () => ({
  warnings: [],
  warning: {},
});

const getters = {};

const actions = {
  async all({}, data) {
    return await Warning.all(data);
  },
  async create({}, data) {
    return await Warning.store(data);
  },
  async update({}, data) {
    console.log("data", data);
    return await Warning.update(data.uuid, data);
  },
  async show({}, uuid) {
    return await Warning.show(uuid);
  },
  async delete({}, data) {
    return await Warning.delete(data.uuid);
  },

  async fetchWarningTypes({}, data) {
    return await Warning.fetchWarningTypes(data);
  },

  async searchSlugs({}, data) {
    return await Warning.searchSlugs(data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
