// Auth
const DEFAULT_TITLE = process.env.VUE_APP_NAME;

export default [
  {
    path: '/auth/login',
    name: 'auth-user-login',
    component: () => import('@/views/auth/user/Login.vue'),
    meta: {
      title: `Entrar - ${DEFAULT_TITLE}`,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/auth/register',
    name: 'auth-user-register',
    component: () => import('@/views/auth/user/Register.vue'),
    meta: {
      title: `Cadastre-se - ${DEFAULT_TITLE}`,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-user-forgot-password',
    component: () => import('@/views/auth/user/ForgotPassword.vue'),
    meta: {
      title: `Esqueci minha senha - ${DEFAULT_TITLE}`,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-user-reset-password',
    component: () => import('@/views/auth/user/ResetPassword.vue'),
    meta: {
      title: `Resetar minha senha - ${DEFAULT_TITLE}`,
      layout: 'full',
    },
  },
  {
    path: '/auth/verify/:token',
    name: 'register-user-confirmation',
    component: () => import('@/views/auth/user/Confirmation.vue'),
    meta: {
      title: `Confirmação de cadastro - ${DEFAULT_TITLE}`,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
];
