import ApiService from "@/_services/Http/axios";

const Indications = {
  all: {},
  store: {},
  delete: {},
  search: {},
};

Indications.all = async function (filters) {
  filters.page = filters.currentPage ?? 1;
  filters.limit = filters.perPage ?? 100;
  filters.rangeDate = filters.rangeDate ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/indications`, {
      ...filters,
      page: filters.page,
      limit: filters.limit,
      rangeDate: filters.rangeDate,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Indications.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/indications/${uuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Indications.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/indications`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Indications.update = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/indications/${data.uuid}`, {
      indicated_by: data.indicated_by,
      indicated: data.indicated,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Indications;
