import ApiService from '@/_services/Http/axios';

const ModelContract = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
};

ModelContract.all = async function (filters) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/datasheets/contract-models/list?page=${filters.currentPage}&limit=${filters.perPage}&title=${filters.title}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ModelContract.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/datasheets/contract-models/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ModelContract.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/datasheets/contract-models/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ModelContract.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/datasheets/contract-models/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ModelContract.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/datasheets/contract-models/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ModelContract.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/datasheets/contract-models/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default ModelContract;
