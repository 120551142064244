import ApiService from "@/_services/Http/axios";

const BusinessUnit = {
  all: {},
  allCategories: {},
  store: {},
  storeCategory: {},
  delete: {},
  deleteCategory: {},
  show: {},
  search: {},
  forSelectCategories: {},
  forSelectTypes: {},
  forSelect: {},
  update: {},
  updateCategory: {},
};

BusinessUnit.all = async function ({
  perPage,
  currentPage,
  document,
  company_name,
  state,
}) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/business-units/list?page=${currentPage}&limit=${perPage}&document=${document}&state=${state}&company_name=${company_name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.allCategories = async function ({ perPage, currentPage, name }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/business-units/categories/list?page=${currentPage}&limit=${perPage}&name=${name}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/business-units/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/business-units/store", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.storeCategory = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/business-units/categories/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/business-units/${uuid}/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.updateCategory = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/business-units/categories/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.deleteMedia = async function (uuid, media_uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/business-units/${uuid}/delete/${media_uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/business-units/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.deleteCategory = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/business-units/categories/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/business-units/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.forSelectCategories = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/business-units/categories/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

BusinessUnit.forSelect = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/business-units/forselect?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default BusinessUnit;
