let host = '';

if (process.env.VUE_APP_MODE === 'localhost') {
  host = STORE_URL = process.env.VUE_APP_DOMAIN;
} else {
  host = location.host.replace(/^(?:https?:\/\/)?(?:www\.)|(\/)/ig, '')
}

export let STORE_URL = location.host
  ? host
  : null;

export const SUPPORTED_LANGS = {
  PT: 'pt-br',
  EN: 'en-us',
  ES: 'en-es',
};

export const ERROR = {
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};
