import Office from '@/_services/Http/office';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  offices: [],
  office: {},
  office_uuid: '',
  deleted: false,
  officesSelect: [],
  searchOffices: [],
});

const getters = {
  error: (state) => state.error,
  StateOffice: (state) => state.office,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Office.all(data)
      .then((res) => {
        commit('SET_OFFICES', res);
      });
  },
  async allToSelect({
    commit,
  }) {
    await Office.all()
      .then((res) => {
        commit('SET_OFFICES', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Office.show(uuid)
      .then((res) => {
        commit('SET_OFFICE', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await Office.store(data)
      .then((res) => {
        commit('SET_OFFICE', res.data);
      })
      .catch(() => {
      });
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCHOFFICES', []);
    await Office.search(term)
      .then((res) => {
        commit('SET_SEARCHOFFICES', res);
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Office.update({uuid, data})
      .then((res) => {
        commit('SET_OFFICE', res.data);
      })
      .catch(() => {
      });
  },
  async delete({}, uuid) {
    await Office.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_OFFICES(state, offices) {
    state.offices = offices;
  },
  SET_SELECT(state, offices) {
    state.officesSelect = offices;
  },
  SET_OFFICE(state, office) {
    state.office = office;
  },
  SET_SEARCHOFFICES(state, data) {
    state.searchOffices = data;
  },
  SET_UUID(state, office) {
    state.office_uuid = office.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
