import useJwt from "@/auth/jwt/useJwt";

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem("userData") &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole) return { name: "dashboard-ecommerce" };
  return { name: "auth-user-login" };
};

export const permissionsDefault = () => [
  {
    actions: [
      { value: "Pedido - Listar", checked: false },
      { value: "Pedido - Editar", checked: false },
      { value: "Pedido - Visualizar", checked: false },
      { value: "Pedido - Exportar", checked: false },
      { value: "Pedido - Imprimir", checked: false },
      { value: "Pedido - Cancelar", checked: false },
      { value: "Pedido - Cartas de Crédito", checked: false },
      { value: "Pedido - Gerar Carta de Crédito", checked: false },
      { value: "Pedido - Sincronizar com o Consys", checked: false },
      { value: "Pedido - Estorno (Carta de crédito)", checked: false },

      { value: "Parcela - Listar", checked: false },

      { value: "Cancelada - Listar", checked: false },

      { value: "Matrícula - Listar", checked: false },

      { value: "Jarvis", checked: false },

      { value: "Por Cupom - Listar", checked: false },

      { value: "Por Curso - Listar", checked: false },

      { value: "Por Produto - Listar", checked: false },

      { value: "Por Vendedor - Listarr", checked: false },

      { value: "Por Dia - Listar", checked: false },

      { value: "Por Categoria - Listar", checked: false },

      { value: "Cupom - Listar", checked: false },
      { value: "Cupom - Editar", checked: false },
      { value: "Cupom - Excluir", checked: false },
      { value: "Cupom - Cadastrar", checked: false },

      { value: "Campanha - Listar", checked: false },
      { value: "Campanha - Editar", checked: false },

      { value: "Indicação - Listar", checked: false },
      { value: "Indicação - Editar", checked: false },
      { value: "Indicação - Excluir", checked: false },
      { value: "Indicação - Cadastrar", checked: false },

      { value: "Repasse - Listar", checked: false },
      { value: "Repasse - Criar", checked: false },
      { value: "Repasse - Reprovar", checked: false },
      { value: "Repasse - Aprovar como Gerente", checked: false },
      { value: "Repasse - Aprovar como Financeiro", checked: false },
      { value: "Repasse - Aprovados", checked: false },

      { value: "Carta de Crédito - Cadastrar", checked: false },
    ],
    subject: "Comercial",
  },
  {
    actions: [
      { value: "Online - Listar", checked: false },
      { value: "Online - Editar", checked: false },
      { value: "Online - Cadastrar", checked: false },
      { value: "Online - Excluir", checked: false },

      { value: "Live - Listar", checked: false },
      { value: "Live - Editar", checked: false },
      { value: "Live - Cadastrar", checked: false },
      { value: "Live - Excluir", checked: false },

      { value: "Presencial - Listar", checked: false },
      { value: "Presencial - Editar", checked: false },
      { value: "Presencial - Cadastrar", checked: false },
      { value: "Presencial - Excluir", checked: false },
      { value: "Passaporte - Listar", checked: false },

      { value: "Questões - Listar", checked: false },
      { value: "Questões - Editar", checked: false },
      { value: "Questões - Cadastrar", checked: false },
      { value: "Questões - Excluir", checked: false },

      { value: "Passaporte - Listar", checked: false },
      { value: "Passaporte - Editar", checked: false },
      { value: "Passaporte - Cadastrar", checked: false },
      { value: "Passaporte - Excluir", checked: false },

      { value: "Rápido - Listar", checked: false },
      { value: "Rápido - Editar", checked: false },
      { value: "Rápido - Cadastrar", checked: false },
      { value: "Rápido - Excluir", checked: false },

      { value: "Grátis - Listar", checked: false },
      { value: "Grátis - Editar", checked: false },
      { value: "Grátis - Cadastrar", checked: false },
      { value: "Grátis - Excluir", checked: false },

      { value: "Apostila - Listar", checked: false },
      { value: "Apostila - Editar", checked: false },
      { value: "Apostila - Cadastrar", checked: false },
      { value: "Apostila - Excluir", checked: false },

      { value: "Subcategoria - Listar", checked: false },
      { value: "Subcategoria - Editar", checked: false },
      { value: "Subcategoria - Cadastrar", checked: false },
      { value: "Subcategoria - Excluir", checked: false },

      { value: "Combo Questões - Listar", checked: false },
      { value: "Combo Questões - Editar", checked: false },
      { value: "Combo Questões - Excluir", checked: false },
      { value: "Combo Questões - Cadastrar", checked: false },
    ],
    subject: "Produtos",
  },
  {
    actions: [
      { value: "Concurso - Listar", checked: false },
      { value: "Concurso - Cadastrar", checked: false },
      { value: "Concurso - Editar", checked: false },
      { value: "Concurso - Excluir", checked: false },
      { value: "Ficha Técnica", checked: false },
      { value: "Edital", checked: false },
      { value: "Prova", checked: false },
      { value: "Cargo", checked: false },
      { value: "Orgão", checked: false },
      { value: "Banca", checked: false },
      { value: "Escolaridade", checked: false },
      { value: "Estado", checked: false },
      { value: "Situação", checked: false },
      { value: "Área", checked: false },
      { value: "Turno", checked: false },
      { value: "Que horas ligar", checked: false },
      { value: "Como conheceu", checked: false },
    ],
    subject: "Concursos",
  },
  {
    actions: [{ value: "Dashboard", checked: false }],
    subject: "Dashboard",
  },
  {
    actions: [
      { value: "Listar", checked: false },
      { value: "Cadastrar", checked: false },
      { value: "Editar", checked: false },
      { value: "Excluir", checked: false },
      { value: "Visualizar", checked: false },
      { value: "Editar Nome", checked: false },
      { value: "Editar CPF", checked: false },
      { value: "Editar Celular", checked: false },
      { value: "Editar E-mail", checked: false },
      { value: "Editar Nascimento", checked: false },
      { value: "Editar Senha", checked: false },
      { value: "Editar Endereço", checked: false },

      { value: "Matricular", checked: false },
      { value: "Matricular por disciplina", checked: false },
      { value: "Gerenciar Carrinho", checked: false },
      { value: "Matrícula - EAD", checked: false },
      { value: "Cortesia - EAD", checked: false },
      { value: "Cortesia - Pedagógico", checked: false },
      { value: "Acessar Conta", checked: false },
    ],
    subject: "Clientes",
  },
  {
    actions: [
      { value: "Sales Force", checked: false },
      { value: "Passaporte", checked: false },
    ],
    subject: "Captação de Lead",
  },
  {
    actions: [
      { value: "Disciplina - Listar", checked: false },
      { value: "Disciplina - Editar", checked: false },
      { value: "Disciplina - Cadastrar", checked: false },
      { value: "Disciplina - Excluir", checked: false },

      { value: "Aula - Listar", checked: false },
      { value: "Aula - Editar", checked: false },
      { value: "Aula - Cadastrar", checked: false },
      { value: "Aula - Excluir", checked: false },

      { value: "Material - Listar", checked: false },
      { value: "Material - Editar", checked: false },
      { value: "Material - Cadastrar", checked: false },
      { value: "Material - Excluir", checked: false },

      { value: "Aprovado - Listar", checked: false },
      { value: "Aprovado - Editar", checked: false },
      { value: "Aprovado - Cadastrar", checked: false },
      { value: "Aprovado - Excluir", checked: false },
    ],
    subject: "Ensino a Distância",
  },
  {
    actions: [
      { value: "Curso Venda - Listar", checked: false },
      { value: "Curso Venda - Editar", checked: false },
      { value: "Curso Venda - Cadastrar", checked: false },
      { value: "Curso Venda - Excluir", checked: false },

      { value: "Disciplina - Listar", checked: false },
      { value: "Disciplina - Editar", checked: false },
      { value: "Disciplina - Cadastrar", checked: false },
      { value: "Disciplina - Excluir", checked: false },

      { value: "Curso - Listar", checked: false },
      { value: "Curso - Editar", checked: false },
      { value: "Curso - Cadastrar", checked: false },
      { value: "Curso - Excluir", checked: false },

      { value: "Turma - Listar", checked: false },
      { value: "Turma - Editar", checked: false },
      { value: "Turma - Cadastrar", checked: false },
      { value: "Turma - Excluir", checked: false },

      { value: "Grade - Listar", checked: false },
      { value: "Grade - Editar", checked: false },
      { value: "Grade - Visualizar", checked: false },
      { value: "Grade - Cadastrar", checked: false },
      { value: "Grade - Excluir", checked: false },

      { value: "Aula - Listar", checked: false },
      { value: "Aula - Editar", checked: false },
      { value: "Aula - Cadastrar", checked: false },
      { value: "Aula - Excluir", checked: false },

      { value: "Material - Listar", checked: false },
      { value: "Material - Editar", checked: false },
      { value: "Material - Cadastrar", checked: false },
      { value: "Material - Excluir", checked: false },

      { value: "Aprovado - Listar", checked: false },
      { value: "Aprovado - Editar", checked: false },
      { value: "Aprovado - Cadastrar", checked: false },
      { value: "Aprovado - Excluir", checked: false },

      { value: "Aulas por Professor", checked: false },

      { value: "Ponto do Professor - Listar", checked: false },
      { value: "Ponto do Professor - Editar Aula", checked: false },
      { value: "Ponto do Professor - Concluir Aula", checked: false },
      { value: "Ponto do Professor - Gerar Relatório", checked: false },

      { value: "Relatório de Ponto - Listar", checked: false },
      { value: "Relatório de Ponto - Visualizar", checked: false },
      { value: "Relatório de Ponto - Excluir", checked: false },

      { value: "Início das Turmas", checked: false },

      { value: "Painel de Vendas", checked: false },

      { value: "Sincronizar Pedidos", checked: false },
    ],
    subject: "Pedagógico",
  },
  {
    actions: [
      { value: "Listar", checked: false },
      { value: "Editar", checked: false },
      { value: "Cadastrar", checked: false },
      { value: "Excluir", checked: false },
      { value: "Valor Médio", checked: false },
      { value: "Valor Superior", checked: false },
    ],
    subject: "Professor",
  },
  {
    actions: [
      { value: "Organizar", checked: false },
      { value: "Banner", checked: false },
      { value: "Arquivo", checked: false },
    ],
    subject: "Mídia",
  },
  {
    actions: [
      { value: "Fornecedor - Listar", checked: false },
      { value: "Fornecedor - Editar", checked: false },
      { value: "Fornecedor - Cadastrar", checked: false },
      { value: "Fornecedor - Excluir", checked: false },
      { value: "Fornecedor - Categoria", checked: false },

      { value: "Unidade de Negócio - Listar", checked: false },
      { value: "Unidade de Negócio - Editar", checked: false },
      { value: "Unidade de Negócio - Cadastrar", checked: false },
      { value: "Unidade de Negócio - Excluir", checked: false },
      { value: "Unidade de Negócio - Categoria", checked: false },

      { value: "Unidade Estratégica - Listar", checked: false },
      { value: "Unidade Estratégica - Editar", checked: false },
      { value: "Unidade Estratégica - Cadastrar", checked: false },
      { value: "Unidade Estratégica - Excluir", checked: false },
      { value: "Unidade Estratégica - Categoria", checked: false },

      // { value: "Pedidos de Venda - Listar", checked: false },
      // { value: "Pedidos de Venda - Editar", checked: false },
      // { value: "Pedidos de Venda - Cadastrar", checked: false },
      // { value: "Pedidos de Venda - Excluir", checked: false },

      { value: "Calendário - Listar", checked: false },

      { value: "Pedido de Compra - Categoria - Listar", checked: false },
      { value: "Pedido de Compra - Status - Listar", checked: false },
      { value: "Pedido de Compra - Operação - Listar", checked: false },
      { value: "Pedido de Compra - Centro de Custo - Listar", checked: false },

      { value: "Pedido de Compra - Histórico PC - Listar", checked: false },
      { value: "Pedido de Compra - Histórico PC - Editar", checked: false },
      { value: "Pedido de Compra - Histórico PC - Cadastrar", checked: false },
      { value: "Pedido de Compra - Histórico PC - Excluir", checked: false },

      { value: "Lançado - Listar", checked: false },
      { value: "Lançado - Editar", checked: false },
      { value: "Lançado - Aprovar", checked: false },
      { value: "Lançado - Reprovar", checked: false },
      { value: "Lançado - Excluir", checked: false },
      { value: "Lançado - Cadastrar", checked: false },
      { value: "Lançado - Visualizar", checked: false },

      { value: "Cancelamento Solicitado - Listar", checked: false },
      { value: "Cancelamento Conferido - Listar", checked: false },
      { value: "Cancelamento Aprovado - Listar", checked: false },
      { value: "Cancelamento Efetivado - Listar", checked: false },

      {
        value: "Cancelamento Solicitado - Marcar como Conferido",
        checked: false,
      },
      {
        value: "Cancelamento Conferido - Marcar como Aprovado",
        checked: false,
      },
      {
        value: "Cancelamento Aprovado - Marcar como Efetivado",
        checked: false,
      },

      { value: "Para Aprovação - Listar", checked: false },
      { value: "Para Aprovação - Aprovar", checked: false },
      { value: "Para Aprovação - Reprovar", checked: false },

      { value: "Aprovado - Listar", checked: false },
      { value: "Aprovado - Cadastrar Conta Bancária", checked: false },
      { value: "Aprovado - Alterar Conta Bancária", checked: false },

      { value: "Em Análise - Listar", checked: false },
      { value: "Em Análise - Aprovar Baixa", checked: false },

      { value: "Dar Baixa - Listar", checked: false },

      { value: "Romaneio - Listar", checked: false },
      { value: "Romaneio - Visualizar", checked: false },

      { value: "Romaneio Pagamentos - Listar", checked: false },
      { value: "Romaneio Pagamentos - Visualizar", checked: false },

      { value: "Pagamento - Listar", checked: false },
      { value: "Pagamento - Exportar", checked: false },
      { value: "Pagamento - Cancelar", checked: false },
      { value: "Pagamento - Solicitar Baixa", checked: false },
      { value: "Pagamento - Anexar Arquivo", checked: false },
      { value: "Pagamento - Editar", checked: false },
      { value: "Pagamento - Criar", checked: false },

      { value: "Conta Bancária - Listar", checked: false },
      { value: "Conta Bancária - Editar", checked: false },
      { value: "Conta Bancária - Cadastrar", checked: false },
      { value: "Conta Bancária - Excluir", checked: false },

      { value: "Histórico PC - Listar", checked: false },

      { value: "Centro de Custo - Listar", checked: false },

      { value: "Status - Listar", checked: false },

      { value: "Etapa - Listar", checked: false },

      { value: "Empresa - Listar", checked: false },
      { value: "Empresa - Editar", checked: false },
      { value: "Empresa - Cadastrar", checked: false },
      { value: "Empresa - Excluir", checked: false },

      { value: "Vindi - Listar", checked: false },
    ],
    subject: "Financeiro - ADM",
  },
  {
    actions: [
      { value: "Listar", checked: false },
      { value: "Editar", checked: false },
      { value: "Duplicar", checked: false },
      { value: "Cadastrar", checked: false },
      { value: "Excluir", checked: false },
    ],
    subject: "Adquirentes",
  },
  {
    actions: [
      { value: "Informação", checked: false },
      { value: "Permissão", checked: false },
      { value: "Sobre", checked: false },
      { value: "Parceiro", checked: false },
      { value: "Unidade", checked: false },
      { value: "Sales Force", checked: false },
      { value: "Usuário", checked: false },
      { value: "Tag", checked: false },
      { value: "E-mail", checked: false },
      { value: "Faq", checked: false },
      { value: "Política de Privacidade", checked: false },
      { value: "Gestor de Desconto", checked: false },
    ],
    subject: "Sistema",
  },
  {
    actions: [
      { value: "Post", checked: false },
      { value: "Área", checked: false },
      { value: "Jornalista", checked: false },
    ],
    subject: "Blog",
  },
];
