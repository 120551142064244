import Situation from '@/_services/Http/situation';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  situations: [],
  situation: {},
  situation_uuid: '',
  deleted: false,
  situationsSelect: [],
  searchSituations: [],
});

const getters = {
  error: (state) => state.error,
  StateSituation: (state) => state.situation,
};

const actions = {
  async all({}, data) {
    return await Situation.all(data);
  },
  async allToSelect({}) {
    return await Situation.allToSelect();
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCHSITUATIONS', []);
    await Situation.search(term)
      .then((res) => {
        commit('SET_SEARCHSITUATIONS', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Situation.show(uuid)
      .then((res) => {
        commit('SET_SITUATION', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await Situation.store(data)
      .then((res) => {
        commit('SET_SITUATION', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Situation.update({uuid, data})
      .then((res) => {
        commit('SET_SITUATION', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async delete({}, uuid) {
    await Situation.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SITUATIONS(state, situations) {
    state.situations = situations;
  },
  SET_SELECT(state, situations) {
    state.situationsSelect = situations;
  },
  SET_SITUATION(state, situation) {
    state.situation = situation;
  },
  SET_SEARCHSITUATIONS(state, situations) {
    state.searchSituations = situations;
  },
  SET_UUID(state, situation) {
    state.situation_uuid = situation.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
