import Streaming from '@/_services/Http/streaming';

const state = () => ({
  streamings: [],
});

const getters = {
  error: (state) => state.error,
};

const actions = {
  async fetchAll({commit }) {
    await Streaming.all()
      .then((data) => {
        commit('SET_ALL', data);
      });
  },
  async forSelect({}, data) {
    return await Streaming.forSelect(data);
  },
};

const mutations = {
  SET_ALL(state, data) {
    state.streamings = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
