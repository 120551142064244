import Course from '@/_services/Http/course';

const state = () => ({
});

const getters = {
};

const actions = {
  async fetchAll({}, filters) {
    return await Course.ApiAll(filters)
  },
  async fetchShow({}, uuid) {
    return await Course.ApiShow(uuid)
  },
  async fetchStore({}, data) {
    return await Course.ApiStore(data)
  },
  async fetchSalesPanel({}, data) {
    return await Course.ApiSalesPanel(data)
  },
  async fetchUpdate({}, {uuid, data}) {
    return await Course.ApiUpdate(uuid, data)
  },
  async fetchSearch({}, term, status) {
    return await Course.ApiSearch(term, status)
  },
  async fetchSearchStatus({}, {term, status}) {
    return await Course.ApiSearchStatus(term, status)
  },
  async fetchDelete({}, {uuid}) {
    await Course.ApiDelete(uuid);
  },
  async fetchDeleteDiscipline({}, { uuid, discipline_uuid }) {
    await Course.ApiDeleteDiscipline(uuid, discipline_uuid);
  },
  async forSelectTypes({}, data) {
    return await Course.forSelectTypes(data)
  },
  async forSelect({}, data) {
    return await Course.forSelect(data)
  },

  async fetchAllTypes({}, filters) {
    return await Course.ApiAllTypes(filters)
  },
  async fetchShowType({}, uuid) {
    return await Course.ApiShowType(uuid)
  },
  async fetchStoreType({}, data) {
    return await Course.ApiStoreType(data)
  },
  async fetchUpdateType({}, {uuid, data}) {
    return await Course.ApiUpdateType(uuid, data)
  },
  async fetchDeleteType({}, {uuid}) {
    await Course.ApiDeleteType(uuid);
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
