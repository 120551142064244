import Scholarity from '@/_services/Http/scholarity';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  scholarities: [],
  scholarity: {},
  scholarity_uuid: '',
  deleted: false,
  scholaritiesSelect: [],
  searchScholarities: [],
});

const getters = {
  error: (state) => state.error,
  StateScholarity: (state) => state.scholarity,
};

const actions = {
  async all({}, data) {
    return await Scholarity.all(data);
  },
  async allToSelect({}) {
    return await Scholarity.allToSelect();
  },
  async show({}, uuid) {
    return await Scholarity.show(uuid)
  },
  async create({
    commit,
  }, data) {
    await Scholarity.store(data)
      .then((res) => {
        commit('SET_SCHOLARITY', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Scholarity.update({uuid, data})
      .then((res) => {
        commit('SET_SCHOLARITY', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async search({}, term) {
    return await Scholarity.search(term)
  },
  async delete({}, uuid) {
    await Scholarity.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SCHOLARITIES(state, scholarities) {
    state.scholarities = scholarities;
  },
  SET_SEARCHSCHOLARITIES(state, scholarities) {
    state.searchScholarities = scholarities;
  },
  SET_SELECT(state, scholarities) {
    state.scholaritiesSelect = scholarities;
  },
  SET_SCHOLARITY(state, scholarity) {
    state.scholarity = scholarity;
  },
  SET_UUID(state, scholarity) {
    state.scholarity_uuid = scholarity.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
