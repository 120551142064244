import Acquirer from "@/_services/Http/acquirer";

const state = () => ({});

const getters = {};

const actions = {
  async all({}, data) {
    return await Acquirer.all(data);
  },
  async show({}, uuid) {
    return await Acquirer.show(uuid);
  },
  async create({}, data) {
    return await Acquirer.store(data);
  },
  async update({}, { uuid, data }) {
    return await Acquirer.update({ uuid, data });
  },
  async search({}, term) {
    return await Acquirer.search(term);
  },
  async addUnits({}, { uuid, data }) {
    return await Acquirer.addUnits({ uuid, data });
  },
  async delete({}, { uuid }) {
    return await Acquirer.delete(uuid);
  },
  async deleteUnit({}, { uuid, unit }) {
    return await Acquirer.deleteUnit(uuid, unit);
  },
  async forSelect({}, data) {
    return await Acquirer.forSelect(data);
  },
  async forSelectOfUnit({}, unitUuid) {
    return await Acquirer.forSelectOfUnit(unitUuid);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
