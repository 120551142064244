import ApiService from '@/_services/Http/axios';

const Scholarity = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: [],
};

Scholarity.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/scholarities/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.allToSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/scholarities/all`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/scholarities/search/?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.list = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get('/v1/admin/scholarities')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/scholarities/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/scholarities/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/scholarities/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Scholarity.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/scholarities/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Scholarity;
