import CourseDiscipline  from '@/_services/Http/course-discipline';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: false,
  disciplines: [],
  discipline: {},
  uuid: '',
  deleted: false,
  search: [],
});

const getters = {
};

const actions = {
  async all({}, data) {
    return await CourseDiscipline.all(data)
  },
  async create({}, data) {
    return await CourseDiscipline.store(data)
  },
  async show({}, uuid) {
    return await CourseDiscipline.show(uuid)
  },
  async search({}, term) {
    return await CourseDiscipline.search(term)
  },
  async searchCourse({}, term) {
    return await CourseDiscipline.searchCourse(term)
  },
  async workloadRegistered({}, term) {
    return await CourseDiscipline.workloadRegistered(term)
  },
  async allOfCourse({}, uuid) {
    return await CourseDiscipline.allOfCourse(uuid)
  },
  async allOfTag({}, uuid) {
    return await CourseDiscipline.allOfTag(uuid)
  },
  async delete({}, data) {
    return await CourseDiscipline.delete(data.uuid);
  },
  async update({}, uuid, data) {
    return await CourseDiscipline.update(uuid, data)
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
