import ApiService from "@/_services/Http/axios";

const Config = {
  email: {},
  show: {},
  emailUpdate: {},
  forSelectTypeCompanies: {},
  fetchApps: {},
  fetchSchoolOctadesk: {},
  updateSchoolOctadesk: {},
  deleteSchoolOctadeskTemplate: {},
  deleteSchoolEmailSendgridTemplate: {},
  fetchOctadeskTemplates: {},
  fetchSchoolBling: {},
  updateSchoolBling: {},
  fetchSchoolOpens: {},
  updateSchoolOpens: {},
};

Config.fetchSchoolOpens = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/school/opens")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.fetchSchoolBling = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/school/bling")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.updateSchoolBling = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put("/v1/admin/config/school/bling", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.updateSchoolOpens = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put("/v1/admin/config/school/opens", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.show = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/show")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.fetchApps = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/apps")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.fetchOctadeskTemplates = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/octadesk/templates")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.deleteSchoolOctadeskTemplate = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/config/octadesk/${uuid}/delete`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.deleteSchoolEmailSendgridTemplate = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/config/email/sendgrid/${uuid}/delete`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.updateSchoolOctadesk = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put("/v1/admin/config/octadesk/update", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

Config.fetchSchoolOctadesk = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/school/octadesk")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.email = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/config/email")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.update = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/config/update", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Config.emailUpdate = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.put("/v1/admin/config/email", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// TYPE COMPANIES
Config.forSelectTypeCompanies = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/config/types/companies/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/////////////////////

export default Config;
