import Contest from '@/_services/Http/contest';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: false,
  contests: [],
  contest: {},
  contest_uuid: '',
  deleted: false,
  finds: [],
  searchContests: [],
});

const getters = {
  error: (state) => state.error,
  StateContest: (state) => state.contest,
};

const actions = {
  async all({
    commit,
  }, filters) {
    await Contest.all(filters)
      .then((res) => {
        commit('SET_CONTESTS', res);
      });
  },
  async create({
    commit,
  }, data) {
    await Contest.store(data)
      .then((res) => {
        commit('SET_CONTEST', res.data);
      });
  },
  async show({}, uuid) {
    return await Contest.show(uuid)
  },
  async delete({}, uuid) {
    await Contest.delete(uuid);
  },
  async deleteTag({}, {contest_uuid, tag_uuid}) {
    await Contest.deleteTag(contest_uuid, tag_uuid);
  },
  async update({
    commit,
  }, uuid, data) {
    await Contest.update(uuid, data)
      .then((res) => {
        commit('SET_CONTEST', res.data);
      });
  },
  async search({}, {term: term }) {
    return await Contest.search(term);
  },
  async find({
    commit,
  }, filters) {
    commit('SET_FIND', []);
    await Contest.find(filters)
      .then((res) => {
        commit('SET_FIND', res);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CONTESTS(state, contests) {
    state.contests = contests;
  },
  SET_CONTEST(state, contest) {
    state.contest = contest;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
  SET_UUID(state, uuid) {
    state.contest_uuid = uuid;
  },
  SET_FIND(state, find) {
    state.finds = find;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
