import School from '@/_services/Http/school';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  school: {},
});

const getters = {
  error: (state) => state.error,
};

const actions = {
  async fetchSchool({
    commit,
  }) {
    await School.findDomain()
      .then((res) => {
        commit('SET_SCHOOL', res.data.school);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SCHOOL(state, school) {
    state.school = school;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
