import ApiService from "@/_services/Http/axios";

const Order = {
  all: {},
  store: {},
  show: {},
  status: {},
  paymentMethods: {},
  edit: {},
  cancelOrder: {},
  export: {},
  storeEnrollment: {},
  forSelectOrigins: {},
  requestDiscount: {},
  requestDiscountProducts: {},
  requestDiscountStatus: {},
  forSelectDocumentTypes: {},
  syncJson: {},
  syncJsonByUuids: {},
  allProducts: {},
  allCancelled: {},
  allEnrollments: {},
  enrollmentsExport: {},
  allInstallments: {},
  exportInstallments: {},
  updateCancelledState: {},
  returnMethods: {},
  storeRemittance: {},
  listRemittances: {},
  emitirNotaFiscal: {},
  viewNFE: {},
  viewNFSE: {},
  sendNFProduct: {},
  sendNFService: {},
  deleteRemittancesByIds: {},
  storeObservation: {},
  reverseLetterCredit: {},
};

Order.all = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const product = filters.product ?? "";
  const coupon = filters.coupon ?? "";
  const id = filters.id ?? "";
  const category = filters.category ?? "";
  const origin = filters.origin ?? "";
  const perPage = filters.perPage ?? "";
  const currentPage = filters.currentPage ?? "";
  const turma = filters.turma ?? "";
  const checkConsys =
    filters.checkConsys === true
      ? true
      : filters.checkConsys === false
      ? false
      : undefined;
  const step_id = filters.step_id ?? "";
  const returnMethod = filters.returnMethod ?? "";
  const requestDate = filters.requestDate ?? "";
  const completionDate = filters.completionDate ?? "";
  const cancelation = filters.cancelation ?? "";
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/list?page=${currentPage}&limit=${perPage}&rangeDate=${rangeDate}&origin=${origin}&category=${category}&coupon=${coupon}&status=${status}&id=${id}&product=${product}&cpf=${cpf}&method=${method}&turma=${turma}&checkConsys=${checkConsys}&step_id=${step_id}&requestDate=${requestDate}&completionDate=${completionDate}&returnMethod=${returnMethod}&cancelation=${cancelation}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.allCancelled = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const product = filters.product ?? "";
  const coupon = filters.coupon ?? "";
  const id = filters.id ?? "";
  const category = filters.category ?? "";
  const origin = filters.origin ?? "";
  const perPage = filters.perPage ?? "";
  const currentPage = filters.currentPage ?? "";
  const step_id = filters.step_id ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/cancelled/list?page=${currentPage}&limit=${perPage}&rangeDate=${rangeDate}&origin=${origin}&category=${category}&coupon=${coupon}&status=${status}&id=${id}&product=${product}&cpf=${cpf}&method=${method}&step_id=${step_id}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.allEnrollments = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const classroom = filters.classroom ?? "";
  const id = filters.id ?? "";
  const course = filters.course ?? "";
  const origin = filters.origin ?? "";
  const unit = filters.unit ?? "";
  const perPage = filters.perPage ?? "";
  const currentPage = filters.currentPage ?? "";
  const remittance = filters.remittance ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/enrollments/list?page=${currentPage}&limit=${perPage}&rangeDate=${rangeDate}&origin=${origin}&unit=${unit}&course=${course}&classroom=${classroom}&status=${status}&id=${id}&cpf=${cpf}&method=${method}&remittance=${remittance}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.cancelOrder = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/${uuid}/cancel`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.updateCancelledState = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/update-cancelled-state`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.allProducts = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const product = filters.product ?? "";
  const coupon = filters.coupon ?? "";
  const id = filters.id ?? "";
  const category = filters.category ?? "";
  const origin = filters.origin ?? "";
  const perPage = filters.perPage ?? "";
  const currentPage = filters.currentPage ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/products/list?page=${currentPage}&limit=${perPage}&rangeDate=${rangeDate}&origin=${origin}&category=${category}&coupon=${coupon}&status=${status}&id=${id}&product=${product}&cpf=${cpf}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.allCourses = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const course = filters.course ?? "";
  const origin = filters.origin ?? "";
  const perPage = filters.perPage ?? "";
  const currentPage = filters.currentPage ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/courses/list?page=${currentPage}&limit=${perPage}&rangeDate=${rangeDate}&origin=${origin}&status=${status}&course=${course}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.allInstallments = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const product = filters.product ?? "";
  const coupon = filters.coupon ?? "";
  const id = filters.id ?? "";
  const category = filters.category ?? "";
  const origin = filters.origin ?? "";
  const perPage = filters.perPage ?? "";
  const currentPage = filters.currentPage ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/installments/list?page=${currentPage}&limit=${perPage}&rangeDate=${rangeDate}&origin=${origin}&category=${category}&coupon=${coupon}&status=${status}&id=${id}&product=${product}&cpf=${cpf}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.export = async function ({ filters }) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const product = filters.product ?? "";
  const coupon = filters.coupon ?? "";
  const id = filters.id ?? "";
  const category = filters.category ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/export?rangeDate=${rangeDate}&origin=${origin}&category=${category}&coupon=${coupon}&status=${status}&id=${id}&product=${product}&cpf=${cpf}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.enrollmentsExport = async function ({ filters }) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const unit = filters.unit ?? "";
  const course = filters.course ?? "";
  const classroom = filters.classroom ?? "";
  const id = filters.id ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/enrollments/export?rangeDate=${rangeDate}&origin=${origin}&unit=${unit}&course=${course}&classroom=${classroom}&status=${status}&id=${id}&cpf=${cpf}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.exportInstallments = async function ({ filters }) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const id = filters.id ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/installments/export?rangeDate=${rangeDate}&origin=${origin}&status=${status}&id=${id}&cpf=${cpf}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.exportProducts = async function (filters) {
  const rangeDate = filters.rangeDate ?? "";
  const status = filters.status ?? "";
  const method = filters.method ?? "";
  const cpf = filters.cpf ?? "";
  const product = filters.product ?? "";
  const coupon = filters.coupon ?? "";
  const id = filters.id ?? "";
  const category = filters.category ?? "";
  const origin = filters.origin ?? "";

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/export/products?rangeDate=${rangeDate}&origin=${origin}&category=${category}&coupon=${coupon}&status=${status}&id=${id}&product=${product}&cpf=${cpf}&method=${method}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.edit = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.status = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/status`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.syncJson = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/${uuid}/sync/consys`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.syncJsonByUuids = async function (uuids) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/sync/consys-uuids`, { uuids: uuids })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.getAllReceive = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/page/to/receive?page=${page}&limit=${limit}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.requestDiscount = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(
      `/v1/admin/orders/enrollments/${uuid}/request-discount`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.requestDiscountProducts = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(
      `/v1/admin/orders/enrollments/${uuid}/request-discount-products`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.requestDiscountStatus = async function (id) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/enrollments/${id}/request-discount-status`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.paymentMethods = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/methods`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.returnMethods = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/return-methods`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/store", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.storeEnrollment = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/enrollments/${uuid}/store`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.forSelectOrigins = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/orders/origins/for-select${data ? "?ids=" + data : ""}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.forSelectDocumentTypes = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/orders/documents/types/forselect")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.listRemittances = async function (filters = {}) {
  return new Promise((resolve, reject) => {
    ApiService.get("/v1/admin/orders/remittances/list", filters)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

Order.storeRemittance = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/remittances`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.approveRemittances = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/remittances/status`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.emitirNotaFiscal = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/installments/emitir-nota-fiscal`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.viewNFE = async function (id) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/installments/view-nfe/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.viewNFSE = async function (id) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/installments/view-nfse/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.sendNFProduct = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/installments/enviar-nf-produto`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.sendNFService = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/installments/enviar-nf-servico`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.deleteRemittancesByIds = async function (ids) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/remittances/delete/by-ids`, { ids })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.storeObservation = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/observations/store`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Order.reverseLetterCredit = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/orders/${uuid}/reverse-letter-credit`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Order;
