import ApiService from '@/_services/Http/axios';

const Lesson = {
  all: {},
  store: {},
  edit: {},
  delete: {},
  deleteTag: {},
  show: {},
  update: {},
  search: {},
};

Lesson.all = async function ({ perPage: limit, currentPage: page, filters }) {  
  return new Promise((resolve, reject) => {
    const tag_subject = filters.tag_subject ?? '';
    const tag_contest = filters.tag_contest ?? '';
    const tag_timeline = filters.tag_timeline ?? '';
    const rangeDate = filters.rangeDate ?? '';
    const teacher = filters.teacher ?? '';
    const title = filters.title ?? '';
    const ead = filters.ead ?? '';

    ApiService.get(`/v1/admin/lessons/list?page=${page}&limit=${limit}&rangeDate=${rangeDate}&tag_subject=${tag_subject}&tag_contest=${tag_contest}&tag_timeline=${tag_timeline}&teacher=${teacher}&title=${title}&ead=${ead}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/lessons/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.find = async function (filters) {
  const tag_subject = filters.tag_subject && filters.tag_subject.code ? filters.tag_subject.code : '';
  const tag_contest = filters.tag_contest && filters.tag_contest.code ? filters.tag_contest.code : '';
  const tag_timeline = filters.tag_timeline && filters.tag_timeline.code ? filters.tag_timeline.code : '';
  const teacher = filters.teacher && filters.teacher.code ? filters.teacher.code : '';
  const title = filters.title ?? '';

  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/lessons/finds?tag_subject=${tag_subject}&title=${title}&tag_contest=${tag_contest}&tag_timeline=${tag_timeline}&teacher=${teacher}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/lessons/store', data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.update = async function ({ uuid: uuid, data: data }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/lessons/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/lessons/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/lessons/delete/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Lesson.deleteTag = async function (lesson_uuid, tag_uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/lessons/delete/${lesson_uuid}/${tag_uuid}/tag`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Lesson;
