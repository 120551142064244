import { layout } from "echarts/lib/layout/barGrid";

export default [
  // TEACHER LESSONS
  {
    path: "/pedagogical/units/start-classes",
    name: "start-classes-list",
    component: () => import("@/views/pedagogical-units/start-class/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Início das Turmas",
      btnPrintOut: {
        id: "print-out-start-classes",
      },
      pageTitle: "Início das Turmas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Início das Turmas",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },

  // TEACHER LESSONS
  {
    path: "/pedagogical/units/teacher-lessons",
    name: "teacher-lessons-list",
    component: () =>
      import("@/views/pedagogical-units/teacher-lesson/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Aulas por Professor",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-material",
      },
      btnPrintOut: {
        id: "print-out-teacher-lessons",
      },
      pageTitle: "Aulas por Professor",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Aulas por Professor",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },

  // TEACHER POINTS REPORT
  {
    path: "/pedagogical/units/teacher-points-generate",
    name: "teacher-points-generate",
    component: () =>
      import("@/views/pedagogical-units/teacher-point-report/Generate.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Ponto do Professor - Gerar Relatório",
      pageTitle: "Gerar Relatório de Pontos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Gerar Relatório de Pontos",
          active: true,
        },
      ],
    },
  },
  // TEACHER POINTS
  {
    path: "/pedagogical/units/teacher-points",
    name: "teacher-points-list",
    component: () => import("@/views/pedagogical-units/teacher-point/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Ponto do Professor - Listar",
      btnPrintOut: {
        id: "print-out-teacher-points",
      },
      btnOnlyExport: true,
      pageTitle: "Ponto do professor",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Ponto do professor",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/teacher-points/print",
    name: "teacher-points-list-print",
    component: () =>
      import("@/views/pedagogical-units/teacher-point/Print.vue"),
    meta: {
      layout: "full",
      resource: "Pedagógico",
      pageTitle: "Ponto do professor",
      action: "Ponto do Professor - Listar",
    },
  },
  // TEACHER POINT REPORT PREVIEW
  {
    path: "/pedagogical/units/teacher-points/report",
    name: "teacher-points-reports",
    component: () =>
      import("@/views/pedagogical-units/teacher-point-report/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Relatório de Ponto - Listar",
      pageTitle: "Relatório de Ponto do professor",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Relátorio de Pontos do Professor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/teacher-points/report/:uuid",
    name: "teacher-points-report-view",
    component: () =>
      import("@/views/pedagogical-units/teacher-point-report/View.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Relatório de Ponto - Visualizar",
      pageTitle: "Relatório de Ponto do professor",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Relátorio de Pontos do Professor",
          to: { name: "teacher-points-reports" },
        },
        {
          text: "Visualizar",
          active: true,
        },
      ],
    },
  },

  // MATERIALS
  {
    path: "/pedagogical/units/materials",
    name: "materials-v2-list",
    component: () => import("@/views/pedagogical-units/material/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Material - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-material",
      },
      pageTitle: "Materiais",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Materiais",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/materials/:uuid/edit",
    name: "materials-v2-edit",
    component: () => import("@/views/pedagogical-units/material/Edit.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Material - Editar",
      btnBack: "materials-v2-list",
      pageTitle: "Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Materiais",
          to: { name: "materials-v2-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  // DISCIPLINES
  {
    path: "/pedagogical/units/courses/disciplines",
    name: "course-disciplines-list",
    component: () => import("@/views/pedagogical-units/discipline/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Disciplina - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-discipline",
      },
      pageTitle: "Disciplinas - Listagem",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Cursos",
          to: { name: "courses-list" },
        },
        {
          text: "Disciplinas",
          active: true,
        },
      ],
    },
  },

  // COURSES SALE
  {
    path: "/pedagogical/units/courses/sale",
    name: "courses-sale-list",
    component: () => import("@/views/pedagogical-units/course/sale/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Curso Venda - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-course-sale",
      },
      pageTitle: "Cursos Venda - Listagem",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Cursos",
          to: { name: "courses-list" },
        },
        {
          text: "Cursos Venda",
          active: true,
        },
      ],
    },
  },

  /** COURSES **/
  {
    path: "/pedagogical/units/courses",
    name: "courses-list",
    component: () => import("@/views/pedagogical-units/course/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Curso - Listar",
      btnCreateRouteName: "courses-create",
      pageTitle: "Cursos - Listagem",
      contentClass: "ecommerce-application",
      dropdown: [
        {
          permission: "",
          route: "courses-types-list",
          text: "Tipos de curso",
          variant: "",
          icon: "",
        },
      ],
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Cursos",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/courses/create",
    name: "courses-create",
    component: () => import("@/views/pedagogical-units/course/Create.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Curso - Cadastrar",
      btnBack: "courses-list",
      pageTitle: "Cursos - Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Cursos",
          to: { name: "courses-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/courses/:uuid/edit",
    name: "courses-edit",
    component: () => import("@/views/pedagogical-units/course/Edit.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Curso - Editar",
      btnBack: "courses-list",
      pageTitle: "Cursos - Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Cursos",
          to: { name: "courses-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/courses/types/list",
    name: "courses-types-list",
    component: () => import("@/views/pedagogical-units/course/type/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Curso - Editar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-course-type",
      },
      btnBack: "courses-list",
      pageTitle: "Tipos de Cursos - Listar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Cursos",
          to: { name: "courses-list" },
        },
        {
          text: "Tipos de curso",
          active: true,
        },
      ],
    },
  },

  /** CLASSES **/
  {
    path: "/pedagogical/units/classrooms",
    name: "classrooms-list",
    component: () => import("@/views/pedagogical-units/classroom/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Turma - Listar",
      btnCreateRouteName: "classrooms-create",
      pageTitle: "Turmas - Listagem",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Turmas",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/classrooms/create",
    name: "classrooms-create",
    component: () => import("@/views/pedagogical-units/classroom/Create.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Turma - Cadastrar",
      btnBack: "classrooms-list",
      pageTitle: "Turmas - Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Turmas",
          to: { name: "classrooms-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/classrooms/:uuid/edit",
    name: "classrooms-edit",
    component: () => import("@/views/pedagogical-units/classroom/Edit.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Turma - Editar",
      btnBack: "classrooms-list",
      pageTitle: "Turmas - Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Turmas",
          to: { name: "classrooms-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },

  /** MODALIDITES **/
  {
    path: "/pedagogical/units/classrooms/modalities",
    name: "classrooms-modality-list",
    component: () =>
      import("@/views/pedagogical-units/classroom/modality/List.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Modalidade - Listar",
      btnCreate: true,
      pageTitle: "Modalidades - Listagem",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Turnmas",
          to: { name: "classrooms-list" },
        },
        {
          text: "Modalidades",
          active: true,
        },
      ],
    },
  },

  /** PAINEL DE VENDAS **/
  {
    path: "/pedagogical/units/sales-panel",
    name: "sales-panel-index",
    component: () => import("@/views/pedagogical-units/sales-panel/Index.vue"),
    meta: {
      layout: "full",
      resource: "Pedagógico",
      action: "Painel de Vendas",
      btnCreate: true,
      pageTitle: "Painel de Vendas",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Painel de Vendas",
          active: true,
        },
      ],
    },
  },

  /** SINCRONIZAR PEDIDOS **/
  {
    path: "/pedagogical/units/sync-orders",
    name: "sync-orders-index",
    component: () => import("@/views/pedagogical-units/sync-orders/Index.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Sincronizar Pedidos",
      btnCreate: true,
      pageTitle: "Sincronizar Pedidos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Sincronizar Pedidos",
          active: true,
        },
      ],
    },
  },

  /** GRADE **/
  {
    path: "/pedagogical/units/grids",
    name: "grids-list",
    component: () => import("@/views/pedagogical-units/grid/List.vue"),
    meta: {
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-grid",
      },
      resource: "Pedagógico",
      action: "Grade - Listar",
      pageTitle: "Grade - Listagem",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Grade",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/grids/:uuid/create",
    name: "grids-create",
    component: () => import("@/views/pedagogical-units/grid/Create.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Grade - Cadastrar",
      btnBack: "grids-list",
      pageTitle: "Grade - Cadastrar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Grade",
          to: { name: "grids-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/grids/:uuid/edit",
    name: "grids-edit",
    component: () => import("@/views/pedagogical-units/grid/Edit.vue"),
    meta: {
      resource: "Pedagógico",
      action: "Grade - Editar",
      btnBack: "grids-list",
      pageTitle: "Grade - Editar",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Grade",
          to: { name: "grids-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pedagogical/units/grids/:uuid/show",
    name: "grids-show",
    component: () => import("@/views/pedagogical-units/grid/Show.vue"),
    meta: {
      layout: "full",
      resource: "Pedagógico",
      action: "Painel de Vendas",
      btnCreate: true,
      pageTitle: "Visualizar Grade",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pedagógico",
        },
        {
          text: "Grade",
          to: { name: "grids-list" },
        },
        {
          text: "Visualizar",
          active: true,
        },
      ],
    },
  },
];
