import ApiService from "@/_services/Http/axios";

const Post = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: [],
  home: {},
  areas: {},
  area: {},
  areaPosts: {},
  areaUpdate: {},
};

Post.all = async function ({
  perPage: limit,
  currentPage: page,
  title: title,
  created: created,
  updated: updated,
  area: area,
  contest: contest,
  author: author,
}) {
  contest = contest ? contest.code : '';
  area = area ? area.code : '';
  author = author ? author.code : '';

  return new Promise((resolve, reject) => {
    ApiService.get(
      `/v1/admin/posts?page=${page}&limit=${limit}&updated=${updated ?? ''}&area=${area}&author=${author}&contest=${contest}&title=${title}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/posts/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.area = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/posts/area/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.home = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/posts/home`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/posts/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.postsHome = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/posts/home", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.update = async function ({ uuid, data } = data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/posts/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.areaUpdate = async function ({ uuid, data } = data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/posts/area/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/posts/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/posts/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.areas = async function (uuid) {
  console.log(uuid);
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/posts/areas`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Post.areaPosts = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/posts/areas/${uuid}/posts`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Post;
