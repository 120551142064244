import LetterCredit from '@/_services/Http/letter-credit';

const state = () => ({
});

const getters = {
};

const actions = {
  async all({}, filters) {
    return await LetterCredit.all(filters)
  },
  async edit({}, uuid) {
    return await LetterCredit.edit(uuid)
  },
  async store({}, data) {
    return await LetterCredit.store(data)
  },
  async update({}, {uuid, data}) {
    return await LetterCredit.update(uuid, data)
  },
  async create({}, data) {
    return await LetterCredit.store(data)
  },
  async delete({}, uuid) {
    return await LetterCredit.delete(uuid)
  },
  async forSelect({}, term) {
    return await LetterCredit.forSelect(term);
  },
  async forSelectOfCustomer({}, uuid) {
    return await LetterCredit.forSelectOfCustomer(uuid);
  },
  async forSelectStatus({}) {
    return await LetterCredit.forSelectStatus();
  },
}

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
