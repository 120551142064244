import Customer from "@/_services/Http/customer";

const state = () => ({
  loading: true,
  message: "Aguarde",
  error: "",
  customers: [],
  leads: [],
  customer: {},
  deleted: false,
  orders: [],
});

const getters = {
  error: (state) => state.error,
  StateCustomer: (state) => state.customer,
};

const actions = {
  async all({}, data) {
    return await Customer.all(data);
  },
  async forSelect({}, term) {
    return await Customer.forSelect(term);
  },
  async forSelectOrders({}, term) {
    return await Customer.forSelectOrders(term);
  },
  async getOrderDetails({}, { customerUuid, orderUuid }) {
    return await Customer.getOrderDetails(customerUuid, orderUuid);
  },
  async leads({}, data) {
    return await Customer.leads(data);
  },
  async lead({}, uuid) {
    return await Customer.lead(uuid);
  },
  async create({}, data) {
    return await Customer.store(data);
  },
  async newOrder({}, { uuid, data }) {
    return await Customer.newOrder(uuid, data);
  },
  async exportLeads({}, data) {
    return await Customer.exportLeads(data);
  },
  async newOrderGrid({}, { uuid, data }) {
    return await Customer.newOrderGrid(uuid, data);
  },
  async update({}, { uuid: uuid, data: data }) {
    return await Customer.update(uuid, data);
  },
  async import({}, data) {
    return await Customer.import(data);
  },
  async show({}, uuid) {
    return await Customer.show(uuid);
  },
  async delete({}, uuid) {
    return await Customer.delete(uuid);
  },
  async search({}, filters) {
    return await Customer.search(filters);
  },
  async getOrders({}, uuid) {
    return await Customer.getOrders(uuid);
  },
  async saveFollow({}, data) {
    return await Customer.saveFollow(data);
  },
  async setStoreLoading({ commit }, payload) {
    await commit("SET_LOADING", payload);
  },
  async fetchUpdateAddress({}, { uuid: uuid, data: data }) {
    return await Customer.ApiUpdateAddress(uuid, data);
  },
  async setStoreError({ commit }, errorCode) {
    await commit("SET_LOADING", {
      status: false,
      message: "",
    });
    await commit("SET_ERROR", errorCode);
  },
};

const mutations = {
  SET_LOADING(state, { status, message }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CUSTOMERS(state, customers) {
    state.customers = customers;
  },
  SET_LEADS(state, data) {
    state.leads = data;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = customer;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
  SET_ORDERS(state, data) {
    state.orders = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
