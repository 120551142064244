import Tag from '@/_services/Http/tag';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  tags: [],
  tag: {},
  tag_uuid: '',
  deleted: false,
  searchTagsTema: [],
  searchTagsConcurso: [],
  searchTagsLinhadoTempo: [],
  tagCategories: [],
  tagsToCategory: [],
});

const getters = {
  error: (state) => state.error,
  StateTag: (state) => state.tag,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Tag.all(data)
      .then((res) => {
        commit('SET_TAGS', res);
      });
  },
  async allCategories({
    commit,
  }) {
    await Tag.categories()
      .then((res) => {
        commit('SET_TAGCATEGORIES', res);
      });
  },
  async allToCategory({
    commit,
  }, category_id) {
    await Tag.toCategory(category_id)
      .then((res) => {
        commit('SET_TAGTOCATEGORY', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Tag.show(uuid)
      .then((res) => {
        commit('SET_TAG', res.data);
        commit('SET_LOADING', false);
      });
  },
  async search({}, name) {
    return await Tag.search(name)
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Tag.update({uuid, data})
      .then((res) => {
        commit('SET_TAG', res.data);
      })
      .catch(() => {
      });
  },
  async searchTags({}, { category_id, term }) {
    return await Tag.search(category_id, term)
  },
  async create({
    commit,
  }, data) {
    await Tag.store(data)
      .then((res) => {
        commit('SET_TAG', res.data);
      });
  },
  async delete({}, data) {
    await Tag.delete(data.uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
  SET_SEARCHTAGSTEMA(state, tags) {
    state.searchTagsTema = tags;
  },
  SET_SEARCHTAGSCONCURSO(state, tags) {
    state.searchTagsConcurso = tags;
  },
  SET_SEARCHTAGSLINHADOTEMPO(state, tags) {
    state.searchTagsLinhadoTempo = tags;
  },
  SET_TAGCATEGORIES(state, tags) {
    state.tagCategories = tags;
  },
  SET_TAGTOCATEGORY(state, tags) {
    state.tagsToCategory = tags;
  },
  SET_SEARCH(state, tags) {
    state.searchTags = tags;
  },
  SET_TAG(state, tag) {
    state.tag = tag;
  },
  SET_UUID(state, tag) {
    state.tag_uuid = tag.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
