import ApiService from '@/_services/Http/axios';

const Acquirer = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  forSelect: {},
  forSelectOfUnit: {},
  deleteUnit: {},
  addUnits: {},
};

Acquirer.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/acquirers/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/acquirers/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/acquirers/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.addUnits = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/acquirers/${uuid}/store/units`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/acquirers/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/acquirers/${uuid}/delete`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.deleteUnit = async function (uuid, unit) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/acquirers/${uuid}/delete-unit/${unit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/acquirers/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/acquirers/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Acquirer.forSelectOfUnit = async function (unitUuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/acquirers/for-select-unit/${unitUuid}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Acquirer;
