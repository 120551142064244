import ApiService from '@/_services/Http/axios';

const School = {
  findDomain: {},
};

School.findDomain = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/find/school`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default School;
