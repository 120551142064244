import Dashboard from '@/_services/Http/dashboard';

const actions = {
  async index({}) {
    return await Dashboard.index();
  },
};

export default {
  namespaced: true,
  actions,
};
