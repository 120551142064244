import axios from "axios";
import btoa from "btoa";
// eslint-disable-next-line import/no-cycle
// import Slack from '@/_services/webhook/Slack';

import { STORE_URL } from "@/_constants";
const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Accept-Language": "pt-BR;q=0.8, en;q=0.6, es;q=0.4",
    domain: STORE_URL,
  },
});

// eslint-disable-next-line consistent-return
async function errorResponseHandler(error) {
  if (
    Object.prototype.hasOwnProperty.call(error.config, "errorHandled") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }
  if (error.response) {
    // await Slack.error(error);
  }

  return Promise.reject(error);
}

request.interceptors.response.use((response) => response, errorResponseHandler);

const ApiService = {
  init() {
    request.defaults.baseURL = process.env.VUE_APP_API_URL;
  },
  setHeader() {
    request.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
  },
  removeHeader() {
    request.defaults.headers.common = {};
  },
  get(resource, params) {
    this.setHeader();
    return request.get(resource, {
      params,
    });
  },
  post(resource, data) {
    this.setHeader();
    return request.post(resource, data);
  },
  put(resource, data) {
    this.setHeader();
    return request.put(resource, {
      ...data,
    });
  },
  delete(resource) {
    this.setHeader();
    return request.delete(resource);
  },
};

export default ApiService;
