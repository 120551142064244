// Marketing
export default [
  {
    path: "/discounts",
    name: "discounts-list",
    component: () => import("@/views/finance/discount/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Campanha - Listar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-coupon-mass",
      },
      pageTitle: "Campanhas de descontos",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Campanhas de descontos",
          active: true,
        },
      ],
    },
  },
  {
    path: "/finance/coupons",
    name: "coupons-list",
    component: () => import("@/views/finance/coupon/List.vue"),
    meta: {
      resource: "Comercial",
      action: "Cupom - Listar",
      btnCreateRouteName: "coupons-create",
      pageTitle: "Cupons",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Cupons",
          active: true,
        },
      ],
    },
  },
  {
    path: "/finance/coupons/create",
    name: "coupons-create",
    component: () => import("@/views/finance/coupon/Create.vue"),
    meta: {
      resource: "Comercial",
      action: "Cupom - Cadastrar",
      btnBack: "coupons-list",
      pageTitle: "Cadastrar cupom",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Cupons",
          to: { name: "coupons-list" },
        },
        {
          text: "Cadastrar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/finance/coupons/:uuid/edit",
    name: "coupons-edit",
    component: () => import("@/views/finance/coupon/Edit.vue"),
    meta: {
      resource: "Comercial",
      action: "Cupom - Editar",
      btnBack: "coupons-list",
      pageTitle: "Editar cupom",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Comercial",
        },
        {
          text: "Cupons",
          to: { name: "coupons-list" },
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/medias/pixels",
    name: "pixels-list",
    component: () => import("@/views/media/pixel/List.vue"),
    meta: {
      pageTitle: "Pixels",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Pixels",
          active: true,
        },
      ],
    },
  },
];
