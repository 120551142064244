import ApiService from "@/_services/Http/axios";

const Warning = {
  all: {},
  store: {},
  update: {},
  delete: {},
  show: {},
  search: {},
  fetchWarningTypes: {},
  searchSlugs: {},
};

Warning.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/warnings/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Warning.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/warnings/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Warning.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/warnings/store", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Warning.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/warnings/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Warning.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/warnings/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Warning.fetchWarningTypes = async function () {
  console.log("fetchWarningTypes");
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/warnings/types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Warning.searchSlugs = async function (search) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/warnings/slugs`, { params: { search } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Warning.search = async function (term) {
//   return new Promise((resolve, reject) => {
//     ApiService.get(`/v1/admin/areas/search?term=${term}`)
//       .then((res) => {
//         resolve(res.data.data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export default Warning;
