import ApiService from '@/_services/Http/axios';

const CourseDiscipline = {
  all: {},
  store: {},
  edit: {},
  delete: {},
  show: {},
  update: {},
  search: {},
  searchCourse: {}
};

CourseDiscipline.all = async function ({ perPage: limit, currentPage: page }) {  
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.searchCourse = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/search-course?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.workloadRegistered = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/workload-registered?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.allOfCourse = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/all-of-course?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.allOfTag = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/all-of-tag?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/course-disciplines/store', data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.update = async function ({ uuid: uuid, data: data }) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/course-disciplines/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/course-disciplines/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CourseDiscipline.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/course-disciplines/delete/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default CourseDiscipline;
