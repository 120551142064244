import ApiService from '@/_services/Http/axios';

const Api = {
  all: {},
  delete: {},
  update: {},
};

Api.all = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/privacy-policies/list`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.update = async function (items) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/privacy-policies/update`, { items: items })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Api.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/privacy-policies/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Api;
