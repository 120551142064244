import CardNetwork from "@/_services/Http/card-network";

const state = () => ({});

const getters = {};

const actions = {
  async all({}, data) {
    return await CardNetwork.all(data);
  },
  async show({}, uuid) {
    return await CardNetwork.show(uuid);
  },
  async create({}, data) {
    return await CardNetwork.store(data);
  },
  async update({}, { uuid, data }) {
    return await CardNetwork.update({ uuid, data });
  },
  async search({}, term) {
    return await CardNetwork.search(term);
  },
  async delete({}, { uuid }) {
    await CardNetwork.delete(uuid);
  },
  async forSelect({}, ) {
    return await CardNetwork.forSelect();
  },
  async forSelectPaymentMethodConventional({}) {
    return await CardNetwork.forSelectPaymentMethodConventional();
  },  
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
