// Contests
export default [
  {
    path: '/contests',
    name: 'contests-list',
    component: () => import('@/views/contest/contest/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Concurso - Listar",
      btnCreate: false,
      btnCreateRouteName: 'contests-create',
      btnExport: false,
      pageTitle: 'Concursos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/create',
    name: 'contests-create',
    component: () => import('@/views/contest/contest/Create.vue'),
    meta: {
      resource: "Concursos",
      action: "Concurso - Cadastrar",
      btnBack: 'contests-list',
      pageTitle: 'Concursos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Cadastrar',
          to: { name: 'contests-create' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/:uuid/edit',
    name: 'contests-edit',
    component: () => import('@/views/contest/contest/Edit.vue'),
    meta: {
      resource: "Concursos",
      action: "Concurso - Editar",
      btnBack: 'contests-list',
      pageTitle: 'Editar',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/juries',
    name: 'jury-list',
    component: () => import('@/views/contest/jury/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Banca",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-jury",
      },
      pageTitle: 'Bancas',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Bancas',
        },
        {
          text: 'Listar',
          to: { name: 'jury-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/datasheets',
    name: 'datasheets-list',
    component: () => import('@/views/contest/datasheet/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Ficha Técnica",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-datasheet",
      },
      pageTitle: 'Fichas Técnicas',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Fichas Técnicas',
        },
        {
          text: 'Fichas Técnicas',
          to: { name: 'datasheets-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/notices',
    name: 'notices-list',
    component: () => import('@/views/contest/notice/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Edital",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-notice",
      },
      pageTitle: 'Editais',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
        },
        {
          text: 'Editais',
        },
        {
          text: 'Editais',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/notices/:uuid/edit',
    name: 'notices-edit',
    component: () => import('@/views/contest/notice/Edit.vue'),
    meta: {
      resource: "Concursos",
      action: "Edital",
      pageTitle: 'Editar',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
        },
        {
          text: 'Editais',
          to: { name: 'notices-list' },
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/organs',
    name: 'organs-list',
    component: () => import('@/views/contest/organ/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Orgão",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-organ",
      },
      pageTitle: 'Orgãos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Orgãos',
        },
        {
          text: 'Orgãos',
          to: { name: 'organ-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/offices',
    name: 'offices-list',
    component: () => import('@/views/contest/office/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Cargo",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-office",
      },
      pageTitle: 'Cargos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Cargos',
        },
        {
          text: 'Listar',
          to: { name: 'offices-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/proofs',
    name: 'proofs-list',
    component: () => import('@/views/contest/proof/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Prova",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-proof",
      },
      pageTitle: 'Provas',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
        },
        {
          text: 'Provas',
        },
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/proofs/edit',
    name: 'proofs-edit',
    component: () => import('@/views/contest/proof/Edit.vue'),
    meta: {
      resource: "Concursos",
      action: "Prova",
      btnBack: 'proofs-list',
      pageTitle: 'Provas',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
        },
        {
          text: 'Provas',
        },
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/timetocall',
    name: 'timetocall-list',
    component: () => import('@/views/contest/timetocall/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Que horas ligar",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-timetocall",
      },
      pageTitle: 'Que horas ligar?',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Listagem',
          to: { name: 'timetocall-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/schooling',
    name: 'schooling-list',
    component: () => import('@/views/contest/schooling/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Escolaridade",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-schooling",
      },
      pageTitle: 'Escolaridades',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Escolaridades',
          to: { name: 'schooling-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/states',
    name: 'states-list',
    component: () => import('@/views/contest/state/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Estado",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-state",
      },
      pageTitle: 'Estados',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Estados',
          to: { name: 'states-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/situations',
    name: 'situations-list',
    component: () => import('@/views/contest/situation/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Situação",
      btnCreate: true,
      btnCreateElementName: null,
      btnExport: false,
      pageTitle: 'Situações',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Situações',
          to: { name: 'situations-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/areas',
    name: 'areas-list',
    component: () => import('@/views/contest/area/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Área",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-area",
      },
      pageTitle: 'Áreas',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Áreas',
          to: { name: 'area-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/contract-modelds',
    name: 'contract-models-list',
    component: () => import('@/views/contest/contract-model/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Área",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-model-contract",
      },
      pageTitle: 'Modelos de contrato',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Modelos de contrato',
          to: { name: 'contract-models-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/shifts',
    name: 'shifts-list',
    component: () => import('@/views/contest/shift/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Turno",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-shift",
      },
      pageTitle: 'Turnos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Turnos',
          to: { name: 'shifts-list' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/contests/knows',
    name: 'knows-list',
    component: () => import('@/views/contest/know/List.vue'),
    meta: {
      resource: "Concursos",
      action: "Como conheceu",
      modal: {
        btnCreate: true,
        btnCreateModalId: "modal-create-know",
      },
      pageTitle: 'Como conheceu?',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Concursos',
          to: { name: 'contests-list' },
        },
        {
          text: 'Como conheceu?',
          to: { name: 'know-list' },
          active: true,
        },
      ],
    },
  },
];
