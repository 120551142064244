import Lesson from '@/_services/Http/lesson';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: false,
  lessons: [],
  lesson: {},
  lesson_uuid: '',
  deleted: false,
  finds: [],
  searchLessons: [],
});

const getters = {
  error: (state) => state.error,
  StateLesson: (state) => state.lesson,
};

const actions = {
  async all({}, filters) {
    return await Lesson.all(filters)
  },
  async create({
    commit,
  }, data) {
    await Lesson.store(data)
      .then((res) => {
        commit('SET_LESSON', res.data);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Lesson.show(uuid)
      .then((res) => {
        if (res) {
          commit('SET_LESSON', res);
        } else {
          commit('SET_ERROR', true);
        }

        commit('SET_LOADING', false);
      })
      .catch(() => {
        commit('SET_ERROR', true);
      });
  },
  async delete({}, data) {
    await Lesson.delete(data.uuid);
  },
  async deleteTag({}, {lesson_uuid, tag_uuid}) {
    await Lesson.deleteTag(lesson_uuid, tag_uuid);
  },
  async update({
    commit,
  }, uuid, data) {
    await Lesson.update(uuid, data)
      .then((res) => {
        commit('SET_LESSON', res.data.data);
      });
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCH', []);
    await Lesson.search(term)
      .then((res) => {
        commit('SET_SEARCH', res);
      });
  },
  async find({
    commit,
  }, filters) {
    commit('SET_FIND', []);
    await Lesson.find(filters)
      .then((res) => {
        commit('SET_FIND', res);
      });
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LESSONS(state, lessons) {
    state.lessons = lessons;
  },
  SET_LESSON(state, lesson) {
    state.lesson = lesson;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
  SET_UUID(state, uuid) {
    state.lesson_uuid = uuid;
  },
  SET_SEARCH(state, lessons) {
    state.searchLessons = lessons;
  },
  SET_FIND(state, find) {
    state.finds = find;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
