import ApiService from '@/_services/Http/axios';

const Author = {
  all: {},
  store: {},
  delete: {},
  search: {},
};

Author.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/authors?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Author.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/authors/store', data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Author.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/authors/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Author.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/authors/delete/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Author.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/authors/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Author.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/authors/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Author;
