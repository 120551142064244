import ApiService from "@/_services/Http/axios";

const User = {
  all: {},
  store: {},
  update: {},
  delete: {},
  search: {},
  show: {},
  showDiscounts: {},
  managersDiscounts: {},
  managersDiscountSend: {},
  fetchTeams: {},
  fetchUsersCRM: {},
};

User.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.fetchTeams = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/teams?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.fetchUsersCRM = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/crm`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/users/store", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/users/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/users/delete/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.showDiscounts = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/discounts`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/search?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.managersDiscounts = async function (classroom, modality) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/users/managers-discounts/${classroom}/classroom/${modality}/modality`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

User.managersDiscountSend = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/users/managers-discounts/send`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default User;
