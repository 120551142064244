import ApiService from "@/_services/Http/axios";

const LetterCredit = {
  all: {},
  store: {},
  update: {},
  delete: {},
  forSelect: {},
  edit: {},
  forSelectStatus: {},
  forSelectOfCustomer: {}
};

LetterCredit.all = async function (filters) {
  filters.page = filters.page ?? 1;
  filters.limit = filters.limit ?? 100;
  filters.order_id = filters.order_id ?? '';
  filters.id = filters.id ?? '';
  filters.customer = filters.customer ?? '';
  filters.rangeDate = filters.rangeDate ?? '';

  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/letter-credits/list?page=${filters.page}&limit=${filters.limit}&customer=${filters.customer}&orderId=${filters.order_id}&id=${filters.id}&rangeDate=${filters.rangeDate}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/v1/admin/orders/letter-credits/store", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.update = async function (uuid, data) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/orders/letter-credits/${uuid}/update`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/orders/letter-credits/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.edit = async function (uuid) {
  return new Promise((resolve, reject) => {
    console.log(uuid)
    ApiService.get(`/v1/admin/orders/letter-credits/${uuid}/edit`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.forSelect = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/letter-credits/forselect?term=${term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.forSelectOfCustomer = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/letter-credits/${uuid}/forselect`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

LetterCredit.forSelectStatus = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/orders/letter-credits/forselect-status`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default LetterCredit;
