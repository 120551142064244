import Area from '@/_services/Http/area';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  areas: [],
  area: {},
  area_uuid: '',
  deleted: false,
  searchAreas: {},
});

const getters = {
  error: (state) => state.error,
  StateArea: (state) => state.area,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Area.all(data)
      .then((res) => {
        commit('SET_AREAS', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Area.show(uuid)
      .then((res) => {
        commit('SET_AREA', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await Area.store(data)
      .then((res) => {
        commit('SET_AREA', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Area.update({uuid, data})
      .then((res) => {
        commit('SET_AREA', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async search({
    commit,
  }, term) {
    commit('SET_SEARCHAREAS', []);
    await Area.search(term)
      .then((res) => {
        commit('SET_SEARCHAREAS', res);
      });
  },
  async delete({}, uuid) {
    await Area.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_AREAS(state, areas) {
    state.areas = areas;
  },
  SET_SELECT(state, areas) {
    state.areasSelect = areas;
  },
  SET_AREA(state, area) {
    state.area = area;
  },
  SET_SEARCHAREAS(state, data) {
    state.searchAreas = data;
  },
  SET_UUID(state, area) {
    state.area_uuid = area.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
