import Post from '@/_services/Http/post';

const actions = {
  async all({}, data) {
    return await Post.all(data)
  },
  async show({}, uuid) {
    return await Post.show(uuid)
  },
  async areas({}) {
    return await Post.areas()
  },
  async area({}, uuid) {
    return await Post.area(uuid)
  },
  async areapost({}, uuid) {
    return await Post.areaPosts(uuid)
  },
  async home({}) {
    return await Post.home()
  },
  async create({}, data) {
    return await Post.store(data)
  },
  async postsHome({}, data) {
    return await Post.postsHome(data)
  },
  async search({}, term) {
    return await Post.search(term);
  },
  async update({}, uuid, data) {
    return await Post.update(uuid, data)
  },
  async areaUpdate({}, uuid, data) {
    return await Post.areaUpdate(uuid, data)
  },
  async delete({}, uuid) {
    await Post.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

export default {
  namespaced: true,
  actions,
};
