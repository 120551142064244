import CourseSale from '@/_services/Http/course-sale';

const state = () => ({
});

const getters = {
};

const actions = {
  async all({}, filters) {
    return await CourseSale.all(filters)
  },
  async show({}, uuid) {
    return await CourseSale.show(uuid)
  },
  async create({}, data) {
    return await CourseSale.store(data)
  },
  async update({}, {uuid, data}) {
    return await CourseSale.update({uuid, data})
  },
  async search({}, term) {
    return await CourseSale.search(term)
  },
  async delete({}, uuid) {
    await CourseSale.delete(uuid);
  },
  async forSelectTypes({}, data) {
    return await CourseSale.forSelectTypes(data)
  },
  async forSelect({}, data) {
    return await CourseSale.forSelect(data)
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
