import ApiService from '@/_services/Http/axios';

const Faq = {
  all: {},
  store: {},
  delete: {},
  show: {},
  list: [],
  search: {},
};

Faq.all = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/faqs`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Faq.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/faqs/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Faq.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/faqs/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Faq.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/faqs/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Faq.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/faqs/${uuid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Faq.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/faqs/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Faq;
