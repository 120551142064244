import TimeToCall from '@/_services/Http/timetocall';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  timetocalls: [],
  timetocall: {},
  timetocall_uuid: '',
  deleted: false,
  timetocallsSelect: [],
});

const getters = {
  error: (state) => state.error,
  StateTimeToCall: (state) => state.timetocall,
};

const actions = {
  async all({
    commit,
  }, data) {
    await TimeToCall.all(data)
      .then((res) => {
        commit('SET_TIMETOCALLS', res);
      });
  },
  async allToSelect({
    commit,
  }) {
    await TimeToCall.all()
      .then((res) => {
        commit('SET_TIMETOCALLS', res);
      });
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await TimeToCall.show(uuid)
      .then((res) => {
        commit('SET_TIMETOCALL', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await TimeToCall.store(data)
      .then((res) => {
        commit('SET_TIMETOCALL', res.data);
      })
      .catch(() => {
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await TimeToCall.update({uuid, data})
      .then((res) => {
        commit('SET_TIMETOCALL', res.data);
      })
      .catch(() => {
      });
  },
  async delete({}, uuid) {
    await TimeToCall.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_TIMETOCALLS(state, timetocalls) {
    state.timetocalls = timetocalls;
  },
  SET_SELECT(state, timetocalls) {
    state.timetocallsSelect = timetocalls;
  },
  SET_TIMETOCALL(state, timetocall) {
    state.timetocall = timetocall;
  },
  SET_UUID(state, timetocall) {
    state.timetocall_uuid = timetocall.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
