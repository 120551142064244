import ApiService from '@/_services/Http/axios';

const CardNetwork = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  forSelect: {},
  forSelectPaymentMethodConventional: {},
};

CardNetwork.all = async function ({ perPage: limit, currentPage: page }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/card-networks/list?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/card-networks/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/card-networks/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.put(`/v1/admin/card-networks/${uuid}/update`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/card-networks/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/card-networks/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.forSelect = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/card-networks/for-select`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

CardNetwork.forSelectPaymentMethodConventional = async function () {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/card-networks/for-select-payment-method-conventionals`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default CardNetwork;
