import Shift from '@/_services/Http/shift';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  shifts: [],
  shift: {},
  shift_uuid: '',
  deleted: false,
  shiftsSelect: [],
});

const getters = {
  error: (state) => state.error,
  StateShift: (state) => state.shift,
};

const actions = {
  async all({
    commit,
  }, data) {
    await Shift.all(data)
      .then((res) => {
        commit('SET_SHIFTS', res);
      });
  },
  async forSelect({}) {
    return await Shift.forSelect()
  },
  async show({
    commit,
  }, uuid) {
    commit('SET_LOADING', true);
    await Shift.show(uuid)
      .then((res) => {
        commit('SET_SHIFT', res.data);
        commit('SET_LOADING', false);
      });
  },
  async create({
    commit,
  }, data) {
    await Shift.store(data)
      .then((res) => {
        commit('SET_SHIFT', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async update({
    commit,
  }, {uuid, data}) {
    await Shift.update({uuid, data})
      .then((res) => {
        commit('SET_SHIFT', res.data);
      })
      .catch(() => {
        this.notifyDefault('error');
      });
  },
  async delete({}, uuid) {
    await Shift.delete(uuid);
  },
  async setStoreLoading({ commit }, payload) {
    await commit('SET_LOADING', payload);
  },
  async setStoreError({ commit }, errorCode) {
    await commit('SET_LOADING', {
      status: false,
      message: '',
    });
    await commit('SET_ERROR', errorCode);
  },
};

const mutations = {
  SET_LOADING(state, {
    status,
    message,
  }) {
    state.loading = status;
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SHIFTS(state, shifts) {
    state.shifts = shifts;
  },
  SET_SELECT(state, shifts) {
    state.shiftsSelect = shifts;
  },
  SET_SHIFT(state, shift) {
    state.shift = shift;
  },
  SET_UUID(state, shift) {
    state.shift_uuid = shift.uuid;
  },
  DELETED(state, status) {
    state.deleted = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
