import ApiService from '@/_services/Http/axios';

const Banner = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  update: {},
  categories: {},
  sort: {},
};

Banner.all = async function ({ perPage: limit, currentPage: page, categoryId }) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/banners/list?page=${page}&limit=${limit}&category=${categoryId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Banner.categories = async function () { 
  return new Promise((resolve, reject) => {
    ApiService.get('/v1/admin/banners/categories')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Banner.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/banners/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Banner.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/banners/${uuid}/show`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Banner.sort = async function (category, banners) {
  return new Promise((resolve, reject) => {
    ApiService.put(
      `/v1/admin/banners/sort/${category}`,
      { banners: banners},
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

Banner.store = async function (data) {  
  return new Promise((resolve, reject) => {
    ApiService.post(
      '/v1/admin/banners/store',
      data,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

Banner.update = async function (uuid, data) {  
  return new Promise((resolve, reject) => {
    ApiService.post(
      `/v1/admin/banners/${uuid}/update`,
      data,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

Banner.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/banners/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Banner.deleteFile = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/banners/${uuid}/file`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Banner;
