import ApiService from '@/_services/Http/axios';

const Teacher = {
  all: {},
  store: {},
  delete: {},
  show: {},
  search: {},
  update: {},
};

Teacher.all = async function ({ perPage: limit, currentPage: page, filters }) {  
  return new Promise((resolve, reject) => {
    const tag_subject = filters.tag_subject ?? '';
    const tag_contest = filters.tag_contest ?? '';
    const tag_timeline = filters.tag_timeline ?? '';
    const rangeDate = filters.rangeDate ?? '';
    const title = filters.title ?? '';

    ApiService.get(`/v1/admin/teachers/list?page=${page}&limit=${limit}&rangeDate=${rangeDate}&tag_subject=${tag_subject}&tag_contest=${tag_contest}&tag_timeline=${tag_timeline}&title=${title}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Teacher.search = async function (term) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/teachers/search?term=${term}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Teacher.getByTagsSubjects = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/teachers/get-by-tags-subjects?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Teacher.show = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.get(`/v1/admin/teachers/${uuid}/show`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Teacher.store = async function (data) {
  return new Promise((resolve, reject) => {
    ApiService.post('/v1/admin/teachers/store', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Teacher.update = async function ({uuid, data}) {
  return new Promise((resolve, reject) => {
    ApiService.post(`/v1/admin/teachers/${uuid}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

Teacher.delete = async function (uuid) {
  return new Promise((resolve, reject) => {
    ApiService.delete(`/v1/admin/teachers/${uuid}/delete`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default Teacher;
