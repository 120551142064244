export default {
  datePT: function (value, minutes = false) {
    if(!value) return
    const moment = require("moment");

    let date = moment(value).format("DD/MM/YYYY");

    if (minutes) {
      date = date + " às " + moment(value).format("HH:mm");
    }

    return date;
  },
  formatHourEndSecond: function (value) {
    const moment = require("moment");

    let date = moment(value, 'HH:mm:ss').format("HH:MM");

    return date;
  },
  convertToHourFormat: function (hours) {
    const totalMinutes = hours * 60;
    const formattedHours = Math.floor(totalMinutes / 60);
    const formattedMinutes = totalMinutes % 60;

    return `${formattedHours < 10 ? '0'+formattedHours : formattedHours}:${formattedMinutes.toString().padStart(2, '0')}`;
  },
  justTime: function (value) {
    const moment = require("moment");

    const date = moment(value, "HH:mm:ss").format("HH:mm");
    
    if (moment(date, 'HH:mm').isValid()) {
      return date;
    } else {
      return '-';
    }
  },
  formatTotalHour: function (value) {
    const moment = require("moment");

    const date = moment(value, "HH").format("HH:mm");
    console.log(date)
    if (moment(date, 'HH:mm').isValid()) {
      return date;
    } else {
      return '-';
    }
  },
  toCurrency: function (value, replace = false) {
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "BRL",
    });

    if (replace) {
      return formatter.format(value).replace('R$', '');
    } else {
      return formatter.format(value);
    }
  },

  bytesToMB: function (bytes, decimals, only) { 
    const K_UNIT = 1024;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

    if(bytes== 0) return "0 Byte";
  
    if(only==="MB") return (bytes / (K_UNIT*K_UNIT)).toFixed(decimals) + " MB" ;
  
    let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];
  
    return resp; 
  },
  sortDatesPT: function (datas) {
    const datasISO = datas.map((data) =>
      moment(data, "DD/MM/YYYY").toISOString()
    );

    function compararDatas(a, b) {
      const dataA = moment(a);
      const dataB = moment(b);
      if (dataA.isBefore(dataB)) {
        return -1;
      }
      if (dataA.isAfter(dataB)) {
        return 1;
      }
      return 0;
    }

    datasISO.sort(compararDatas);

    return datasISO.map((dataISO) => moment(dataISO).format("DD/MM/YYYY"));
  },
};
