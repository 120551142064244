import Gateway from '@/_services/Http/gateway';

const state = () => ({
  loading: true,
  message: 'Aguarde',
  error: '',
  gateway: {},
  gateway_uuid: '',
});

const getters = {
  error: (state) => state.error,
  StateGateway: (state) => state.gateway,
};

const actions = {
  async show({
    commit,
  }) {
    await Gateway.show()
      .then((res) => {
        commit('SET_GATEWAY', res.data);
      });
  },
  async update({
    commit,
  }, data) {
    await Gateway.update(data)
      .then((res) => {
        commit('SET_GATEWAY', res.data);
      });
  },
};

const mutations = {
  SET_GATEWAY(state, gateway) {
    state.gateway = gateway;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
